import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { IntlActions } from 'react-redux-multilingual'
import Pace from 'react-pace-progress'

// Import custom components
import store from '../../../store';
import NavBar from "./common/navbar";
import SideBar from "./common/sidebar";
import CartContainer from "./../../../containers/CartContainer";
import TopBar from "./common/topbar";
import LogoImage from "./common/logo";
import { connect } from "react-redux";
import { withTranslate } from 'react-redux-multilingual'
import { changeCurrency } from '../../../actions'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-responsive-modal';
import ImageUploading from "react-images-uploading";
import { toast } from 'react-toastify';
import { getAllProducts } from '../../../actions';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'

class HeaderTwo extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            mobile: false,
            open: false,
            productCode: '',
            nameEnglish: '',
            namePolish: '',
            nameChinese: '',
            price: '',
            family: '',
            lessCapacity: '',
            moreCapacity: '',
            lessUnitEnglish: '',
            moreUnitEnglish: '',
            lessUnitPolish: '',
            moreUnitPolish: '',
            lessUnitChinese: '',
            moreUnitChinese: '',
            descriptionEnglish: '',
            descriptionPolish: '',
            descriptionChinese: '',
            category: '',
            pictures: [],
            loading: false
        }
    }

    /*=====================
         Pre loader
         ==========================*/
    componentDidMount() {
        if (window.innerWidth < 576) {
            document.getElementById("sticky").classList.remove('fixed');
        } else
            document.getElementById("sticky").classList.add('fixed');

        setTimeout(function () {
            document.querySelector(".loader-wrapper").style = "display: none";
        }, 2000);

        window.addEventListener('resize', window.innerWidth < 576 ? this.setState({ mobile: true }) : this.setState({ mobile: false }));
    }

    componentWillMount() {
        window.addEventListener('scroll', this.handleScroll);
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };

    changeLanguage(lang) {
        store.dispatch(IntlActions.setLocale(lang))
    }

    openNav() {
        var openmyslide = document.getElementById("mySidenav");
        if (openmyslide) {
            openmyslide.classList.add('open-side')
        }
    }
    openSearch() {
        document.getElementById("search-overlay").style.display = "block";
    }

    closeSearch() {
        document.getElementById("search-overlay").style.display = "none";
    }

    addNewProduct() {
        if (
            this.state.productCode === '' ||
            this.state.nameEnglish === '' ||
            this.state.namePolish === '' ||
            this.state.nameChinese === '' ||
            this.state.price === '' ||
            this.state.family === '' ||
            this.state.lessCapacity === '' ||
            this.state.moreCapacity === '' ||
            this.state.lessUnitEnglish === '' ||
            this.state.moreUnitEnglish === '' ||
            this.state.lessUnitPolish === '' ||
            this.state.moreUnitPolish === '' ||
            this.state.lessUnitChinese === '' ||
            this.state.moreUnitChinese === '' ||
            this.state.descriptionEnglish === '' ||
            this.state.descriptionPolish === '' ||
            this.state.descriptionChinese === '' ||
            this.state.category === '' ||
            this.state.pictures.length === 0
        ) {
            toast.error(this.props.translate('emptyFieldRegister'));
        }
        else {
            (async () => {
                this.setState({ loading: true });
                const rawResponse = await fetch('https://api.fhsitko.pl/api/product', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Origin': '',
                        'Authorization': 'Bearer ' + this.props.token,
                        'Host': 'api.fhsitko.pl'
                    },
                    body: JSON.stringify({
                        "productCode": this.state.productCode,
                        "nameEnglish": this.state.nameEnglish,
                        "namePolish": this.state.namePolish,
                        "nameChinese": this.state.nameChinese,
                        "price": this.state.price,
                        "family": this.state.family,
                        "lessCapacity": this.state.lessCapacity,
                        "moreCapacity": this.state.moreCapacity,
                        "lessUnitEnglish": this.state.lessUnitEnglish,
                        "moreUnitEnglish": this.state.moreUnitEnglish,
                        "lessUnitPolish": this.state.lessUnitPolish,
                        "moreUnitPolish": this.state.moreUnitPolish,
                        "lessUnitChinese": this.state.lessUnitChinese,
                        "moreUnitChinese": this.state.moreUnitChinese,
                        "descriptionEnglish": this.state.descriptionEnglish,
                        "descriptionPolish": this.state.descriptionPolish,
                        "descriptionChinese": this.state.descriptionChinese,
                        "category": this.state.category
                    })
                });
                const content = await rawResponse.json();

                const formData = new FormData();
                var x = 0;
                formData.append('productID', content.id)
                for (x in this.state.pictures) {
                    formData.append('pictures[' + x + ']', this.state.pictures[x])
                }

                const rawResponse2 = await fetch('https://api.fhsitko.pl/api/image', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Origin': '',
                        'Authorization': 'Bearer ' + this.props.token,
                        'Host': 'api.fhsitko.pl'
                    },
                    body: formData
                });
                const content2 = await rawResponse2.json();
                if (content2.response === 'OK') {
                    this.props.store.dispatch(getAllProducts())
                    document.getElementById('toMainScreen').click();
                    this.setState({ open: false, loading: false, pictures: [] });
                    toast.success(this.props.translate('successAddProduct'));
                }
                else {
                    this.setState({ loading: false });
                    toast.error(this.props.translate('errorAddProduct'));
                }
            })();
        }
    }

    onInputValueChange(event, which) {
        if (which === 'productCode') this.setState({ productCode: event.target.value })
        else if (which === 'nameEnglish') this.setState({ nameEnglish: event.target.value })
        else if (which === 'namePolish') this.setState({ namePolish: event.target.value })
        else if (which === 'nameChinese') this.setState({ nameChinese: event.target.value })
        else if (which === 'price') this.setState({ price: event.target.value })
        else if (which === 'family') this.setState({ family: event.target.value })
        else if (which === 'lessCapacity') this.setState({ lessCapacity: event.target.value })
        else if (which === 'moreCapacity') this.setState({ moreCapacity: event.target.value })
        else if (which === 'lessUnitEnglish') this.setState({ lessUnitEnglish: event.target.value })
        else if (which === 'moreUnitEnglish') this.setState({ moreUnitEnglish: event.target.value })
        else if (which === 'lessUnitPolish') this.setState({ lessUnitPolish: event.target.value })
        else if (which === 'moreUnitPolish') this.setState({ moreUnitPolish: event.target.value })
        else if (which === 'lessUnitChinese') this.setState({ lessUnitChinese: event.target.value })
        else if (which === 'moreUnitChinese') this.setState({ moreUnitChinese: event.target.value })
        else if (which === 'descriptionEnglish') this.setState({ descriptionEnglish: event.target.value })
        else if (which === 'descriptionPolish') this.setState({ descriptionPolish: event.target.value })
        else if (which === 'descriptionChinese') this.setState({ descriptionChinese: event.target.value })
        else if (which === 'category') this.setState({ category: event.target.value })
    }

    load = () => {
        this.setState({ isLoading: true });
        fetch().then(() => {
            // deal with data fetched
            this.setState({ isLoading: false })
        })
    };

    onChange = (imageList) => {
        var newImageList = [];
        var x = 0;
        for (x in imageList) {
            newImageList.push(imageList[x].file)
        }
        this.setState({ pictures: newImageList })
    };

    render() {
        const { translate } = this.props;

        return (
            <div>
                <header id="sticky" className="sticky">
                    {this.state.isLoading ? <Pace color="#27ae60" /> : null}
                    <div className="mobile-fix-option"></div>
                    {/*Top Header Component*/}
                    <TopBar isLogged={this.props.isLogged} />
                    {this.state.open ? null : <ToastContainer closeButton={false} pauseOnHover={false} closeOnClick={false} autoClose={3000}/>}
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="main-menu border-section border-top-0" style={{ height: '65px' }}>
                                    <div className="brand-logo layout2-logo">
                                        <LogoImage logo={this.props.logoName} />
                                    </div>
                                    <div className="menu-right pull-right">
                                        <div>
                                            <div className="icon-nav">
                                                <ul>
                                                    <li className="onhover-div mobile-setting">
                                                        <div><img src={`${process.env.PUBLIC_URL}/assets/images/icon/lang.svg`} style={{ height: '30px' }} className="img-fluid" alt="" />
                                                            <i className="fa fa-language"></i></div>
                                                        <div className="show-div setting">
                                                            <h6>{translate('language')}</h6>
                                                            <ul>
                                                                <li><a href={null} id={'englishID'} onClick={() => this.changeLanguage('en')}>{translate('english')}</a> </li>
                                                                <li><a href={null} id={'polishID'} onClick={() => this.changeLanguage('pl')}>{translate('polish')}</a> </li>
                                                                <li><a href={null} id={'chineseID'} onClick={() => this.changeLanguage('zh')}>{translate('chinese')}</a> </li>
                                                            </ul>
                                                        </div>
                                                    </li>
                                                    {/*Header Cart Component */}
                                                    {this.props.isLogged ? <CartContainer /> : null}
                                                    {(this.props.isLogged && this.props.isAdmin) ?

                                                        <li className="onhover-div mobile-cart">
                                                            <Link to={`#`} onClick={this.onOpenModal.bind(this)}><img src={`${process.env.PUBLIC_URL}/assets/images/plus.svg`} style={{ height: '25px', cursor: 'pointer' }} className="img-fluid" alt="" />
                                                            </Link>
                                                            <ul className="show-div shopping-cart">
                                                                <li><h5>{translate('addProduct')}</h5></li>
                                                            </ul>
                                                        </li>
                                                        : null}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="main-nav-center">
                                    <NavBar />
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                {this.state.mobile ?
                    <div style={{ marginTop: '50px' }} /> : window.innerWidth >= 1200 ?
                        <div style={{ marginTop: '185px' }} />
                        :
                        <div style={{ marginTop: '85px' }} />}

                <Modal open={this.state.open} onClose={this.onCloseModal} center>
                    <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                        <div className="modal-content quick-view-modal">
                            <div className="modal-body">
                                <div className="row" style={{ marginTop: '30px' }}>
                                    {this.state.open ? <ToastContainer closeButton={false} pauseOnHover={false} closeOnClick={false} autoClose={3000}/> : null}
                                    <div class="col">
                                        <div className="form-group">
                                            <label htmlFor="productCode" style={{ marginTop: '10px' }}>{translate('productCode')}</label>
                                            <input type="text" className="form-control" id="productCode" value={this.state.productCode} onChange={(e) => this.onInputValueChange(e, 'productCode')}
                                                required="" />
                                            <label htmlFor="nameEnglish" style={{ marginTop: '10px' }}>{translate('nameEnglish')}</label>
                                            <input type="text" className="form-control" id="nameEnglish" value={this.state.nameEnglish} onChange={(e) => this.onInputValueChange(e, 'nameEnglish')}
                                                required="" />
                                            <label htmlFor="namePolish" style={{ marginTop: '10px' }}>{translate('namePolish')}</label>
                                            <input type="text" className="form-control" id="namePolish" value={this.state.namePolish} onChange={(e) => this.onInputValueChange(e, 'namePolish')}
                                                required="" />
                                            <label htmlFor="nameChinese" style={{ marginTop: '10px' }}>{translate('nameChinese')}</label>
                                            <input type="text" className="form-control" id="nameChinese" value={this.state.nameChinese} onChange={(e) => this.onInputValueChange(e, 'nameChinese')}
                                                required="" />
                                            <label htmlFor="price" style={{ marginTop: '10px' }}>{translate('price')}</label>
                                            <input type="text" className="form-control" id="price" value={this.state.price} onChange={(e) => this.onInputValueChange(e, 'price')}
                                                required="" />
                                            <label htmlFor="family" style={{ marginTop: '10px' }}>{translate('family')}</label>
                                            <input type="text" className="form-control" id="family" value={this.state.family} onChange={(e) => this.onInputValueChange(e, 'family')}
                                                required="" />
                                            <label htmlFor="lessCapacity" style={{ marginTop: '10px' }}>{translate('lessCapacity')}</label>
                                            <input type="text" className="form-control" id="lessCapacity" value={this.state.lessCapacity} onChange={(e) => this.onInputValueChange(e, 'lessCapacity')}
                                                required="" />
                                            <label htmlFor="moreCapacity" style={{ marginTop: '10px' }}>{translate('moreCapacity')}</label>
                                            <input type="text" className="form-control" id="moreCapacity" value={this.state.moreCapacity} onChange={(e) => this.onInputValueChange(e, 'moreCapacity')}
                                                required="" />
                                            <label htmlFor="lessUnitEnglish" style={{ marginTop: '10px' }}>{translate('lessUnitEnglish')}</label>
                                            <input type="text" className="form-control" id="lessUnitEnglish" value={this.state.lessUnitEnglish} onChange={(e) => this.onInputValueChange(e, 'lessUnitEnglish')}
                                                required="" />
                                            <label htmlFor="moreUnitEnglish" style={{ marginTop: '10px' }}>{translate('moreUnitEnglish')}</label>
                                            <input type="text" className="form-control" id="moreUnitEnglish" value={this.state.moreUnitEnglish} onChange={(e) => this.onInputValueChange(e, 'moreUnitEnglish')}
                                                required="" />
                                            <label htmlFor="lessUnitPolish" style={{ marginTop: '10px' }}>{translate('lessUnitPolish')}</label>
                                            <input type="text" className="form-control" id="lessUnitPolish" value={this.state.lessUnitPolish} onChange={(e) => this.onInputValueChange(e, 'lessUnitPolish')}
                                                required="" />
                                            <label htmlFor="moreUnitPolish" style={{ marginTop: '10px' }}>{translate('moreUnitPolish')}</label>
                                            <input type="text" className="form-control" id="moreUnitPolish" value={this.state.moreUnitPolish} onChange={(e) => this.onInputValueChange(e, 'moreUnitPolish')}
                                                required="" />
                                            <label htmlFor="lessUnitChinese" style={{ marginTop: '10px' }}>{translate('lessUnitChinese')}</label>
                                            <input type="text" className="form-control" id="lessUnitChinese" value={this.state.lessUnitChinese} onChange={(e) => this.onInputValueChange(e, 'lessUnitChinese')}
                                                required="" />
                                            <label htmlFor="moreUnitChinese" style={{ marginTop: '10px' }}>{translate('moreUnitChinese')}</label>
                                            <input type="text" className="form-control" id="moreUnitChinese" value={this.state.moreUnitChinese} onChange={(e) => this.onInputValueChange(e, 'moreUnitChinese')}
                                                required="" />
                                            <label htmlFor="descriptionEnglish" style={{ marginTop: '10px' }}>{translate('descriptionEnglish')}</label>
                                            <input type="text" className="form-control" id="descriptionEnglish" value={this.state.descriptionEnglish} onChange={(e) => this.onInputValueChange(e, 'descriptionEnglish')}
                                                required="" />
                                            <label htmlFor="descriptionPolish" style={{ marginTop: '10px' }}>{translate('descriptionPolish')}</label>
                                            <input type="text" className="form-control" id="descriptionPolish" value={this.state.descriptionPolish} onChange={(e) => this.onInputValueChange(e, 'descriptionPolish')}
                                                required="" />
                                            <label htmlFor="descriptionChinese" style={{ marginTop: '10px' }}>{translate('descriptionChinese')}</label>
                                            <input type="text" className="form-control" id="descriptionChinese" value={this.state.descriptionChinese} onChange={(e) => this.onInputValueChange(e, 'descriptionChinese')}
                                                required="" />
                                            <label htmlFor="category" style={{ marginTop: '10px' }}>{translate('category')}</label>
                                            <select className="form-control" id="category" onChange={(e) => this.onInputValueChange(e, 'category')}>
                                                <option value="" selected></option>For="descriptionChi
                                                <optgroup label={translate('artificialFlowers')} style={{ fontWeight: '600', color: 'rgb(255, 76, 59)' }}>
                                                    <option value="artificialFlowers2" style={{ fontWeight: '400', color: '#000000' }}>{translate('artificialFlowers2')}</option>
                                                    <option value="singleFlowers" style={{ fontWeight: '400', color: '#000000' }}>{translate('singleFlowers')}</option>
                                                    <option value="bouquets" style={{ fontWeight: '400', color: '#000000' }}>{translate('bouquets')}</option>
                                                    <option value="leaves" style={{ fontWeight: '400', color: '#000000' }}>{translate('leaves')}</option>
                                                    <option value="othersArtificialFlowers" style={{ fontWeight: '400', color: '#000000' }}>{translate('others')}</option>
                                                </optgroup>
                                                <optgroup label={translate('christmas')} style={{ fontWeight: '600', color: 'rgb(255, 76, 59)' }}>
                                                    <option value="glassBaubles" style={{ fontWeight: '400', color: '#000000' }}>{translate('glassBaubles')}</option>
                                                    <option value="plasticBaubles" style={{ fontWeight: '400', color: '#000000' }}>{translate('plasticBaubles')}</option>
                                                    <option value="decoratedBaubles" style={{ fontWeight: '400', color: '#000000' }}>{translate('decoratedBaubles')}</option>
                                                    <option value="christmasTreeSpikes" style={{ fontWeight: '400', color: '#000000' }}>{translate('christmasTreeSpikes')}</option>
                                                    <option value="othersChristmas" style={{ fontWeight: '400', color: '#000000' }}>{translate('others')}</option>
                                                </optgroup>
                                                <optgroup label={translate('easter')} style={{ fontWeight: '600', color: 'rgb(255, 76, 59)' }}>
                                                    <option value="chickens" style={{ fontWeight: '400', color: '#000000' }}>{translate('chickens')}</option>
                                                    <option value="eggs" style={{ fontWeight: '400', color: '#000000' }}>{translate('eggs')}</option>
                                                    <option value="birdsAndButterflies" style={{ fontWeight: '400', color: '#000000' }}>{translate('birdsAndButterflies')}</option>
                                                    <option value="othersEaster" style={{ fontWeight: '400', color: '#000000' }}>{translate('others')}</option>
                                                </optgroup>
                                                <option value="artificialFoundations" style={{ fontWeight: '600', color: 'rgb(255, 76, 59)' }}>{translate('artificialFoundations')}</option>
                                                <option value="differentAssortment" style={{ fontWeight: '600', color: 'rgb(255, 76, 59)' }}>{translate('differentAssortment')}</option>
                                            </select>
                                        </div>
                                        <div><label style={{ marginTop: '5px' }}>{translate('pictures')}</label></div>
                                        <div><label style={{ marginTop: '5px' }}>{translate('maxPictureSize')}</label></div>
                                        <div><label style={{ marginTop: '5px' }}>{translate('recommendedPictureDimensions')}</label></div>
                                        <div><label style={{ marginTop: '5px' }}>{translate('supportedPictureExtensions')}</label></div>
                                        <ImageUploading
                                            onChange={this.onChange}
                                            maxNumber={5}
                                            multiple={true}
                                            maxFileSize={5 * 1024 * 1024}
                                            acceptType={["jpeg", "jpg", "png"]}
                                            resolutionType={"less"}
                                            resolutionWidth={736}
                                            resolutionHeight={1000}
                                        >
                                            {({ imageList, onImageUpload, onImageRemoveAll }) => (
                                                // write your building UI
                                                <div>
                                                    <button className="btn btn-solid" onClick={onImageUpload} style={{ marginBottom: '10px' }}>{translate('uploadImages')}</button>
                                                    <button className="btn btn-solid" onClick={onImageRemoveAll} style={{ marginBottom: '10px', backgroundImage: 'linear-gradient(30deg, #ff4c3b 60%, transparent 50%)', marginLeft: '10px' }}>{translate('removeAllImages')}</button>

                                                    {imageList.map((image) => (
                                                        <div key={image.key}>
                                                            <img src={image.dataURL} />
                                                            <button className="btn btn-solid" onClick={image.onUpdate} style={{ marginBottom: '10px' }}>{translate('update')}</button>
                                                            <button className="btn btn-solid" onClick={image.onRemove} style={{ marginBottom: '10px', marginLeft: '10px' }}>{translate('remove')}</button>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </ImageUploading>
                                    </div>
                                </div>
                                <div className="product-buttons" style={{ display: 'flex' }}>
                                    <button className="btn btn-solid" onClick={this.addNewProduct.bind(this)} >{translate('addProduct')}</button>
                                    <Loader
                                        type="Puff"
                                        color="#ff4c3b"
                                        height={35}
                                        width={35}
                                        style={{
                                            marginTop: '10px',
                                            marginLeft: '10px'
                                        }}
                                        visible={this.state.loading}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Link to={`${process.env.PUBLIC_URL}/`} style={{ visibility: 'hidden' }} id="toMainScreen"></Link>
            </div>
        )
    }
}

export default withTranslate(connect(null,
    { changeCurrency }
)(HeaderTwo));