import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ScrollContext } from 'react-router-scroll-4';
import { IntlReducer as Intl, IntlProvider } from 'react-redux-multilingual'
import './index.scss';

// Import custom components
import store from './store';
import translations from './constants/translations'
import { getAllProducts } from './actions'


// Layouts
import Vegetables from './components/layouts/vegetables/main';


//Collection Pages
import CollectionNoSidebar from "./components/collection/collection-no-sidebar";

// Product Pages
import NoSideBar from "./components/products/no-sidebar";

// Features
import Layout from './components/app'
import Cart from './components/cart'
import CheckOut from './components/checkout'

// Extra Pages
import aboutUs from './components/pages/about-us'
import cookies from './components/pages/cookies'
import policyPrivacy from './components/pages/policy-privacy'
import regulations from './components/pages/regulations'
import PageNotFound from './components/pages/404'
import ResetSuccess from './components/pages/resetsuccess'
import Login from './components/pages/login'
import Register from './components/pages/register'
import ForgetPassword from './components/pages/forget-password'
import Contact from './components/pages/contact'
import Dashboard from './components/pages/dashboard'
import Faq from './components/pages/faq'

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

class Root extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLogged: false,
            token: '',
            userData: [{
                email: "",
                name: "",
                surname: "",
                nip: "",
                businessName: "",
                address: "",
                phone: "",
                city: "",
                postalCode: "",
                country: "",
                defaultLanguage: "",
                role: ''
            }]
        };
    }

    loggingHandle(credentials, currentLanguage) {
        (async () => {
            const rawResponse = await fetch('https://api.fhsitko.pl/api/login', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Origin': '',
                    'Host': 'api.fhsitko.pl'
                },
                body: JSON.stringify({
                    "email": credentials[0],
                    "password": credentials[1]
                })
            });
            const content = await rawResponse.json();
            if (content.error !== 'Unauthorised') {
                var newUserData = [{
                    email: content.email,
                    name: content.name,
                    surname: content.surname,
                    nip: content.nip,
                    businessName: content.company_name,
                    address: content.address,
                    phone: content.phone,
                    city: content.city,
                    postalCode: content.postal_code,
                    country: content.country,
                    defaultLanguage: content.language,
                    role: content.type
                }]
                this.setState({
                    token: content.token,
                    isLogged: true,
                    userData: newUserData
                })
                if (content.language === 'EN') {
                    document.getElementById('englishID').click();
                    toast.success('Signed in successfully!');
                }
                else if (content.language === 'PL') {
                    document.getElementById('polishID').click();
                    toast.success('Zalogowano pomyślnie!');
                }
                else if (content.language === 'ZH') {
                    document.getElementById('chineseID').click();
                    toast.success('登录成功');
                }
            }
            else {
                if (currentLanguage === 'en') toast.error('Incorrect login/password or user is not in the database')
                else if (currentLanguage === 'pl') toast.error('Błędny login/hasło lub użytkownika nie ma w bazie')
                else if (currentLanguage === 'zh') toast.error('错误的登录名/密码或用户不在数据库中')
            }
        })();
    }

    userDataChangeHandle(newUserData) {
        this.setState({
            userData: newUserData
        })
    }



    render() {
        store.dispatch(getAllProducts());

        return (
            <Provider store={store}>
                <IntlProvider translations={translations} locale='pl'>
                    <BrowserRouter basename={'/'} >
                        <ScrollContext>
                            <Switch>
                                <Route exact path={`${process.env.PUBLIC_URL}/`} component={() => <Vegetables store={store} token={this.state.token} isLogged={this.state.isLogged} isAdmin={this.state.userData[0].role === 'admin' ? true : false} />} />
                                <Layout token={this.state.token} store={store} isLogged={this.state.isLogged} isAdmin={this.state.userData[0].role === 'admin' ? true : false}>

                                    {/*Routes For Features (Product Collection) */}
                                    <Route path={`${process.env.PUBLIC_URL}/shop`} component={() => <CollectionNoSidebar isLogged={this.state.isLogged} />} />

                                    {/*Routes For Single Product*/}
                                    <Route path={`${process.env.PUBLIC_URL}/product/:id`} component={(matchProps) => <NoSideBar {...matchProps} store={store} token={this.state.token} isAdmin={this.state.userData[0].role === 'admin' ? true : false} isLogged={this.state.isLogged} />} />

                                    {/*Routes For custom Features*/}
                                    <Route path={`${process.env.PUBLIC_URL}/cart`} component={() => <Cart token={this.state.token} isLogged={this.state.isLogged} userData={this.state.userData} />} />
                                    <Route path={`${process.env.PUBLIC_URL}/checkout`} component={() => <CheckOut token={this.state.token} isLogged={this.state.isLogged} userData={this.state.userData} />} />

                                    {/*Routes For Extra Pages*/}
                                    <Route path={`${process.env.PUBLIC_URL}/about-us`} component={aboutUs} />
                                    <Route path={`${process.env.PUBLIC_URL}/cookies`} component={cookies} />
                                    <Route path={`${process.env.PUBLIC_URL}/policy-privacy`} component={policyPrivacy} />
                                    <Route path={`${process.env.PUBLIC_URL}/regulations`} component={regulations} />
                                    <Route path={`${process.env.PUBLIC_URL}/pages/404`} component={PageNotFound} />
                                    <Route path={`${process.env.PUBLIC_URL}/pages/resetsuccess`} component={ResetSuccess} />
                                    <Route path={`${process.env.PUBLIC_URL}/login`} component={() => <Login loggingHandle={this.loggingHandle.bind(this)} isLogged={this.state.isLogged} />} />
                                    <Route path={`${process.env.PUBLIC_URL}/register`} component={() => <Register loggingHandle={this.loggingHandle.bind(this)} />} />
                                    <Route path={`${process.env.PUBLIC_URL}/forget-password`} component={ForgetPassword} />
                                    <Route path={`${process.env.PUBLIC_URL}/contact`} component={Contact} />
                                    <Route path={`${process.env.PUBLIC_URL}/dashboard`} component={() => <Dashboard token={this.state.token} isLogged={this.state.isLogged} userData={this.state.userData} userDataChangeHandle={this.userDataChangeHandle.bind(this)} />} />
                                    <Route path={`${process.env.PUBLIC_URL}/faq`} component={Faq} />

                                    <Route path='*' exact={true} component={PageNotFound} />
                                </Layout>
                            </Switch>
                        </ScrollContext>
                    </BrowserRouter>
                </IntlProvider>
            </Provider>
        );
    }
}

ReactDOM.render(<Root />, document.getElementById('root'));


