import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { SlideUpDown } from "../../../services/script"
import LogoImage from "../headers/common/logo"
import { withTranslate } from 'react-redux-multilingual'

class FooterOne extends Component {

    componentDidMount() {
        var contentwidth = window.innerWidth;
        if ((contentwidth) < 750) {
            SlideUpDown('footer-title');
        } else {
            var elems = document.querySelectorAll(".footer-title");
            [].forEach.call(elems, function (elemt) {
                let el = elemt.nextElementSibling;
                el.style = "display: block";
            });
        }
    }


    render() {
        const { translate } = this.props;

        return (
            <footer className="footer-light">
                <section className="section-b-space light-layout">
                    <div className="container">
                        <div className="row footer-theme partition-f">
                            <div className="col">
                                <div className="sub-title">
                                    <div className="footer-title">
                                        <h4>{translate('storeInformation')}</h4>
                                    </div>
                                    <div className="footer-contant">
                                        <ul className="contact-list">
                                            <li><i className="fa fa-map-marker"></i>Centrum Giełdowe Balicka, 30-149 Kraków, ul. Balicka 56/20B
                                            </li>
                                            <li><i className="fa fa-phone"></i><a
                                                href="callto:+48603104321" target="_blank">+48 603 104 321</a></li>
                                            <li><i className="fa fa-envelope-o"></i><a
                                                href="mailto:greg-sit@o2.pl" target="_blank" style={{textTransform: 'none'}}>greg-sit@o2.pl</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col offset-xl-1">
                                <div className="sub-title">
                                    <div className="footer-title">
                                        <h4>{translate('information')}</h4>
                                    </div>
                                    <div className="footer-contant">
                                        <ul>
                                            <li><Link to={`${process.env.PUBLIC_URL}/regulations`} >{translate('basicInformation')}</Link></li>
                                            <li><Link to={`${process.env.PUBLIC_URL}/policy-privacy`} >{translate('policyPrivacy')}</Link></li>
                                            <li><Link to={`${process.env.PUBLIC_URL}/cookies`} >{translate('cookiesPolicy')}</Link></li>
                                            {/* <li><Link to={`${process.env.PUBLIC_URL}/sale`} >{translate('sale')}</Link></li>
                                            <li><Link to={`${process.env.PUBLIC_URL}/delivery`} >{translate('delivery')}</Link></li>
                                            <li><Link to={`${process.env.PUBLIC_URL}/prices`} >{translate('prices')}</Link></li> */}
                                            <li><Link to={`${process.env.PUBLIC_URL}/faq`} >{translate('faq')}</Link></li>
                                            <li><Link to={`${process.env.PUBLIC_URL}/contact`} >{translate('contact')}</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </footer>
        )
    }
}

export default withTranslate(FooterOne);
