/**
 * Mocking client-server processing
 */

const TIMEOUT = 0

export default {
  getProducts: (cb, timeout) => setTimeout(() => {
    fetch('https://api.fhsitko.pl/api/product')
      .then(response => response.json())
      .then(data => cb(data));
  }, timeout || TIMEOUT),
  buyProducts: (payload, cb, timeout) => setTimeout(() => cb(), timeout || TIMEOUT)
}