import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslate } from 'react-redux-multilingual'

class TopBar extends Component {

    handleLogout() {
        this.props.loggingHandle();
    }

    render() {
        const { translate } = this.props;
        return (
            <div className="top-header" style={{ display: 'block' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="header-contact">
                                <ul>
                                    <li>{translate('topbar_title', { theme_name: ' Sitko' })}</li>
                                    <li><i className="fa fa-phone" aria-hidden="true"></i>{translate('call_us')}:  <a href="callto:+48603104321" target="_blank">+48 603 104 321</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 text-right">
                            <ul className="header-dropdown">
                                <li className="mobile-wishlist compare-mobile"></li>
                                <li className="onhover-dropdown mobile-account">
                                    <i className="fa fa-user" aria-hidden="true"></i> {translate('my_account')}
                                    {this.props.isLogged ?
                                        <ul className="onhover-show-div">
                                            <li>
                                                <Link to={`${process.env.PUBLIC_URL}/dashboard`} data-lng="en">{translate('dashboard')}</Link>
                                            </li>
                                            <li>
                                                <Link to={`${process.env.PUBLIC_URL}/login`} data-lng="en" onClick={() => this.handleLogout()}>{translate('logout')}</Link>
                                            </li>
                                        </ul>
                                        :
                                        <ul className="onhover-show-div">
                                            <li>
                                                <Link to={`${process.env.PUBLIC_URL}/login`} data-lng="en">{translate('login')}</Link>
                                            </li>
                                            <li>
                                                <Link to={`${process.env.PUBLIC_URL}/register`} data-lng="en">{translate('register')}</Link>
                                            </li>
                                        </ul>
                                    }

                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default withTranslate(TopBar);