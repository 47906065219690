import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';


import { getTotal, getCartProducts } from '../../../reducers'
import { addToCart, addToWishlist, addToCompare } from '../../../actions'
import { getVisibleproducts } from '../../../services';
import ProductListItem from "./product-list-item";
import { withTranslate } from 'react-redux-multilingual'

class ProductListing extends Component {

    constructor(props) {
        super(props)

        this.state = { limit: 24, hasMoreItems: true };
    }

    componentWillMount() {
        this.fetchMoreItems();
    }

    fetchMoreItems = () => {
        if (this.state.limit >= this.props.products.length) {
            this.setState({ hasMoreItems: false });
            return;
        }
        // a fake async api call
        setTimeout(() => {
            this.setState({
                limit: this.state.limit + 8
            });
        }, 3000);


    }

    render() {
        const { products, addToCart, symbol, addToWishlist, addToCompare, translate } = this.props;

        console.log(this.props.colSize)
        return (
            <div>
                <div className="product-wrapper-grid">
                    <div className="container-fluid">
                        {products.length > 0 ?
                            <InfiniteScroll
                                dataLength={this.state.limit} //This is important field to render the next data
                                next={this.fetchMoreItems}
                                hasMore={this.state.hasMoreItems}
                                loader={<div className="loading-cls"></div>}
                                endMessage={
                                    <p className="seen-cls seen-it-cls">
                                        <b>{translate('noProduct')}</b>
                                    </p>
                                }
                            >
                                <div className="row">
                                    {products.slice(0, this.state.limit).map((product, index) =>
                                        <div className={`${this.props.colSize === 3 ? 'col-xl-3 col-md-6 col-grid-box' : 'col-lg-' + this.props.colSize}`} key={index}>
                                            <ProductListItem isLogged={this.props.isLogged} product={product} symbol={symbol}
                                                onAddToCompareClicked={() => addToCompare(product)}
                                                onAddToWishlistClicked={() => addToWishlist(product)}
                                                onAddToCartClicked={addToCart} key={index} />
                                        </div>)
                                    }
                                </div>
                            </InfiniteScroll>
                            :
                            <div className="row">
                                <div className="col-sm-12 text-center section-b-space mt-5 no-found" >
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/empty-search.jpg`} className="img-fluid mb-4" />
                                    <h3>{translate('noResults')}</h3>
                                    <p>{translate('tryAgain')}</p>
                                    <Link to={`${process.env.PUBLIC_URL}/`} className="btn btn-solid">{translate('continueShopping')}</Link>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    products: getVisibleproducts(state.data, state.filters),
    symbol: state.data.symbol,
})

export default withTranslate(connect(
    mapStateToProps, { addToCart, addToWishlist, addToCompare }
)(ProductListing))