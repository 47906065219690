import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import Slider from 'react-slick';
import Modal from 'react-responsive-modal';
import { withTranslate } from 'react-redux-multilingual'
import { ToastContainer } from 'react-toastify';
import ImageUploading from "react-images-uploading";
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { getAllProducts } from '../../../../actions';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'


class DetailsWithPrice extends Component {

    constructor(props) {
        super(props)
        this.state = {
            openDelete: false,
            openEdit: false,
            openCopy: false,
            quantity: 0,
            more: false,
            less: true,
            nav3: null,
            productCodeEdit: this.props.item.productCode,
            nameEnglishEdit: this.props.item.nameEnglish,
            namePolishEdit: this.props.item.namePolish,
            nameChineseEdit: this.props.item.nameChinese,
            priceEdit: this.props.item.price,
            familyEdit: this.props.item.family,
            lessCapacityEdit: this.props.item.lessCapacity,
            moreCapacityEdit: this.props.item.moreCapacity,
            lessUnitEnglishEdit: this.props.item.lessUnitEnglish,
            moreUnitEnglishEdit: this.props.item.moreUnitEnglish,
            lessUnitPolishEdit: this.props.item.lessUnitPolish,
            moreUnitPolishEdit: this.props.item.moreUnitPolish,
            lessUnitChineseEdit: this.props.item.lessUnitChinese,
            moreUnitChineseEdit: this.props.item.moreUnitChinese,
            descriptionEnglishEdit: this.props.item.descriptionEnglish,
            descriptionPolishEdit: this.props.item.descriptionPolish,
            descriptionChineseEdit: this.props.item.descriptionChinese,
            categoryEdit: this.props.item.category,
            picturesEdit: [],
            loading: false
        }
    }

    onOpenModalDelete = () => {
        this.setState({ openDelete: true });
    };

    onCloseModalDelete = () => {
        this.setState({ openDelete: false });
    };

    onDeleteClick = () => {
        this.setState({ loading: true });
        var url = 'https://api.fhsitko.pl/api/product/' + this.props.item.id;
        (async () => {
            const rawResponse = await fetch(url, {
                method: 'DELETE',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Origin': '',
                    'Authorization': 'Bearer ' + this.props.token,
                    'Host': 'api.fhsitko.pl',
                    'Location': 'http://localhost/Books/index.php'
                }
            })
                .then(() => {
                    this.props.store.dispatch(getAllProducts())
                    document.getElementById('toMainScreen').click();
                    this.setState({ loading: false, openDelete: false });
                    toast.success(this.props.translate('deleteSuccess'));
                })
        })();
    };

    onOpenModalEdit = () => {
        this.setState({ openEdit: true });
    };

    onCloseModalEdit = () => {
        this.setState({ openEdit: false });
    };

    onOpenModalCopy = () => {
        this.setState({ openCopy: true });
    };

    onCloseModalCopy = () => {
        this.setState({ openCopy: false });
    };

    onEditClick = () => { //eloelo
        if (
            this.state.productCodeEdit === '' ||
            this.state.nameEnglishEdit === '' ||
            this.state.namePolishEdit === '' ||
            this.state.nameChineseEdit === '' ||
            this.state.priceEdit === '' ||
            this.state.familyEdit === '' ||
            this.state.lessCapacityEdit === '' ||
            this.state.moreCapacityEdit === '' ||
            this.state.lessUnitEnglishEdit === '' ||
            this.state.moreUnitEnglishEdit === '' ||
            this.state.lessUnitPolishEdit === '' ||
            this.state.moreUnitPolishEdit === '' ||
            this.state.lessUnitChineseEdit === '' ||
            this.state.moreUnitChineseEdit === '' ||
            this.state.descriptionEnglishEdit === '' ||
            this.state.descriptionPolishEdit === '' ||
            this.state.descriptionChineseEdit === '' ||
            this.state.categoryEdit === ''
        ) {
            toast.error(this.props.translate('emptyFieldRegister'));
        }
        else if (
            this.state.productCodeEdit === this.props.item.productCode &&
            this.state.nameEnglishEdit === this.props.item.nameEnglish &&
            this.state.namePolishEdit === this.props.item.namePolish &&
            this.state.nameChineseEdit === this.props.item.nameChinese &&
            this.state.priceEdit === this.props.item.price &&
            this.state.familyEdit === this.props.item.family &&
            this.state.lessCapacityEdit === this.props.item.lessCapacity &&
            this.state.moreCapacityEdit === this.props.item.moreCapacity &&
            this.state.lessUnitEnglishEdit === this.props.item.lessUnitEnglish &&
            this.state.moreUnitEnglishEdit === this.props.item.moreUnitEnglish &&
            this.state.lessUnitPolishEdit === this.props.item.lessUnitPolish &&
            this.state.moreUnitPolishEdit === this.props.item.moreUnitPolish &&
            this.state.lessUnitChineseEdit === this.props.item.lessUnitChinese &&
            this.state.moreUnitChineseEdit === this.props.item.moreUnitChinese &&
            this.state.descriptionEnglishEdit === this.props.item.descriptionEnglish &&
            this.state.descriptionPolishEdit === this.props.item.descriptionPolish &&
            this.state.descriptionChineseEdit === this.props.item.descriptionChinese &&
            this.state.categoryEdit === this.props.item.category
        ) {
            if (this.state.picturesEdit.length > 0) {
                this.setState({ loading: true });
                const formData = new FormData();
                var x = 0;
                formData.append('productID', this.props.item.id)
                for (x in this.state.picturesEdit) {
                    formData.append('pictures[' + x + ']', this.state.picturesEdit[x])
                }

                const rawResponse2 = fetch('https://api.fhsitko.pl/api/image', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Origin': '',
                        'Authorization': 'Bearer ' + this.props.token,
                        'Host': 'api.fhsitko.pl'
                    },
                    body: formData
                })
                    .then(() => {
                        this.setState({
                            picturesEdit: []
                        })
                        this.props.store.dispatch(getAllProducts())
                        this.setState({ loading: false });
                        this.setState({ openEdit: false });
                    })
                    .then(() => {
                        toast.success(this.props.translate('successEditProduct'));
                    })
            }
            else {
                toast.error(this.props.translate('noChanges'));
            }
        }
        else {
            this.setState({ loading: true });
            var url = 'https://api.fhsitko.pl/api/product/' + this.props.item.id;
            (async () => {
                const rawResponse = await fetch(url, {
                    method: 'PUT',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Origin': '',
                        'Authorization': 'Bearer ' + this.props.token,
                        'Host': 'api.fhsitko.pl',
                        'Location': 'http://localhost/Books/index.php'
                    },
                    body: JSON.stringify({
                        "productCode": this.state.productCodeEdit,
                        "nameEnglish": this.state.nameEnglishEdit,
                        "namePolish": this.state.namePolishEdit,
                        "nameChinese": this.state.nameChineseEdit,
                        "price": this.state.priceEdit,
                        "family": this.state.familyEdit,
                        "lessCapacity": this.state.lessCapacityEdit,
                        "moreCapacity": this.state.moreCapacityEdit,
                        "lessUnitEnglish": this.state.lessUnitEnglishEdit,
                        "moreUnitEnglish": this.state.moreUnitEnglishEdit,
                        "lessUnitPolish": this.state.lessUnitPolishEdit,
                        "moreUnitPolish": this.state.moreUnitPolishEdit,
                        "lessUnitChinese": this.state.lessUnitChineseEdit,
                        "moreUnitChinese": this.state.moreUnitChineseEdit,
                        "descriptionEnglish": this.state.descriptionEnglishEdit,
                        "descriptionPolish": this.state.descriptionPolishEdit,
                        "descriptionChinese": this.state.descriptionChineseEdit,
                        "category": this.state.categoryEdit
                    })
                })
                    .then(() => {
                        if (this.state.picturesEdit.length > 0) {
                            const formData = new FormData();
                            var x = 0;
                            formData.append('productID', this.props.item.id)
                            for (x in this.state.picturesEdit) {
                                formData.append('pictures[' + x + ']', this.state.picturesEdit[x])
                            }

                            const rawResponse2 = fetch('https://api.fhsitko.pl/api/image', {
                                method: 'POST',
                                headers: {
                                    'Accept': 'application/json',
                                    'Origin': '',
                                    'Authorization': 'Bearer ' + this.props.token,
                                    'Host': 'api.fhsitko.pl'
                                },
                                body: formData
                            })
                                .then(() => {
                                    this.setState({
                                        picturesEdit: []
                                    })
                                    this.props.store.dispatch(getAllProducts())
                                    this.setState({ openEdit: false, loading: false });
                                })
                                .then(() => {
                                    toast.success(this.props.translate('successEditProduct'));
                                })
                        }
                        else {
                            this.props.store.dispatch(getAllProducts())
                            this.setState({ openEdit: false, loading: false });
                            toast.success(this.props.translate('successEditProduct'));
                        }
                    })
            })();
        }
    };

    componentDidMount() {
        this.setState({
            nav3: this.slider3
        });
    }

    minusQty = () => {
        if (this.state.quantity > 0) {
            if (this.state.less) {
                this.setState({ quantity: this.state.quantity - this.props.item.lessCapacity })
                if (this.state.quantity - this.props.item.lessCapacity < 0) {
                    this.setState({ quantity: 0 })
                }
            }
            else if (this.state.more) {
                this.setState({ quantity: this.state.quantity - this.props.item.moreCapacity })
                if (this.state.quantity - this.props.item.moreCapacity < 0) {
                    this.setState({ quantity: 0 })
                }
            }
        }
    }

    plusQty = () => {
        if (this.state.less) {
            this.setState({ quantity: this.state.quantity + this.props.item.lessCapacity })
        }
        else if (this.state.more) {
            this.setState({ quantity: this.state.quantity + this.props.item.moreCapacity })
        }
    }
    changeQty = (e) => {
        this.setState({ quantity: parseInt(e.target.value) })
    }

    radioButtonClicked(unit) {
        if (unit === 'more') {
            this.setState({
                more: true,
                less: false
            })
        }
        if (unit === 'less') {
            this.setState({
                more: false,
                less: true
            })
        }
    }

    onInputValueChange(event, which) {
        if (which === 'productCode') this.setState({ productCodeEdit: event.target.value })
        else if (which === 'nameEnglish') this.setState({ nameEnglishEdit: event.target.value })
        else if (which === 'namePolish') this.setState({ namePolishEdit: event.target.value })
        else if (which === 'nameChinese') this.setState({ nameChineseEdit: event.target.value })
        else if (which === 'price') this.setState({ priceEdit: event.target.value })
        else if (which === 'family') this.setState({ familyEdit: event.target.value })
        else if (which === 'lessCapacity') this.setState({ lessCapacityEdit: event.target.value })
        else if (which === 'moreCapacity') this.setState({ moreCapacityEdit: event.target.value })
        else if (which === 'lessUnitEnglish') this.setState({ lessUnitEnglishEdit: event.target.value })
        else if (which === 'moreUnitEnglish') this.setState({ moreUnitEnglishEdit: event.target.value })
        else if (which === 'lessUnitPolish') this.setState({ lessUnitPolishEdit: event.target.value })
        else if (which === 'moreUnitPolish') this.setState({ moreUnitPolishEdit: event.target.value })
        else if (which === 'lessUnitChinese') this.setState({ lessUnitChineseEdit: event.target.value })
        else if (which === 'moreUnitChinese') this.setState({ moreUnitChineseEdit: event.target.value })
        else if (which === 'descriptionEnglish') this.setState({ descriptionEnglishEdit: event.target.value })
        else if (which === 'descriptionPolish') this.setState({ descriptionPolishEdit: event.target.value })
        else if (which === 'descriptionChinese') this.setState({ descriptionChineseEdit: event.target.value })
        else if (which === 'category') this.setState({ categoryEdit: event.target.value })
    }

    onChange = (imageList) => {
        var newImageList = [];
        var x = 0;
        for (x in imageList) {
            newImageList.push(imageList[x].file)
        }
        this.setState({ picturesEdit: newImageList })
    };

    addNewProduct() { //eloelo
        if (
            this.state.productCodeEdit === '' ||
            this.state.nameEnglishEdit === '' ||
            this.state.namePolishEdit === '' ||
            this.state.nameChineseEdit === '' ||
            this.state.priceEdit === '' ||
            this.state.familyEdit === '' ||
            this.state.lessCapacityEdit === '' ||
            this.state.moreCapacityEdit === '' ||
            this.state.lessUnitEnglishEdit === '' ||
            this.state.moreUnitEnglishEdit === '' ||
            this.state.lessUnitPolishEdit === '' ||
            this.state.moreUnitPolishEdit === '' ||
            this.state.lessUnitChineseEdit === '' ||
            this.state.moreUnitChineseEdit === '' ||
            this.state.descriptionEnglishEdit === '' ||
            this.state.descriptionPolishEdit === '' ||
            this.state.descriptionChineseEdit === '' ||
            this.state.categoryEdit === '' ||
            this.state.picturesEdit.length === 0
        ) {
            toast.error(this.props.translate('emptyFieldRegister'));
        }
        else {
            (async () => {
                this.setState({ loading: true });
                const rawResponse = await fetch('https://api.fhsitko.pl/api/product', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Origin': '',
                        'Authorization': 'Bearer ' + this.props.token,
                        'Host': 'api.fhsitko.pl'
                    },
                    body: JSON.stringify({
                        "productCode": this.state.productCodeEdit,
                        "nameEnglish": this.state.nameEnglishEdit,
                        "namePolish": this.state.namePolishEdit,
                        "nameChinese": this.state.nameChineseEdit,
                        "price": this.state.priceEdit,
                        "family": this.state.familyEdit,
                        "lessCapacity": this.state.lessCapacityEdit,
                        "moreCapacity": this.state.moreCapacityEdit,
                        "lessUnitEnglish": this.state.lessUnitEnglishEdit,
                        "moreUnitEnglish": this.state.moreUnitEnglishEdit,
                        "lessUnitPolish": this.state.lessUnitPolishEdit,
                        "moreUnitPolish": this.state.moreUnitPolishEdit,
                        "lessUnitChinese": this.state.lessUnitChineseEdit,
                        "moreUnitChinese": this.state.moreUnitChineseEdit,
                        "descriptionEnglish": this.state.descriptionEnglishEdit,
                        "descriptionPolish": this.state.descriptionPolishEdit,
                        "descriptionChinese": this.state.descriptionChineseEdit,
                        "category": this.state.categoryEdit
                    })
                });
                const content = await rawResponse.json();

                const formData = new FormData();
                var x = 0;
                formData.append('productID', content.id)
                for (x in this.state.picturesEdit) {
                    formData.append('pictures[' + x + ']', this.state.picturesEdit[x])
                }

                const rawResponse2 = await fetch('https://api.fhsitko.pl/api/image', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Origin': '',
                        'Authorization': 'Bearer ' + this.props.token,
                        'Host': 'api.fhsitko.pl'
                    },
                    body: formData
                });
                const content2 = await rawResponse2.json();
                if (content2.response === 'OK') {
                    this.props.store.dispatch(getAllProducts())
                    document.getElementById('toMainScreen').click();
                    this.setState({ open: false, loading: false, picturesEdit: [] });
                    toast.success(this.props.translate('successAddProduct'));
                }
                else {
                    this.setState({ loading: false });
                    toast.error(this.props.translate('errorAddProduct'));
                }
            })();
        }
    }

    render() {
        const { symbol, item, addToCartClicked, BuynowClicked, addToWishlistClicked, translate } = this.props

        var colorsnav = {
            slidesToShow: 6,
            swipeToSlide: true,
            arrows: false,
            dots: false,
            focusOnSelect: true
        };

        return (
            <div className="col-lg-6 rtl-text">
                {!this.state.openEdit ? <ToastContainer closeButton={false} pauseOnHover={false} closeOnClick={false} autoClose={3000} /> : null}
                <div className="product-right">
                    {this.props.isAdmin ?
                        <div>
                            <img onClick={this.onOpenModalDelete.bind(this)} title={translate('deleteProduct')} style={{ cursor: 'pointer', height: '25px', marginBottom: '15px' }} src={'/assets/images/bin.svg'}></img>
                            <img onClick={this.onOpenModalEdit.bind(this)} title={translate('editProduct')} style={{ cursor: 'pointer', height: '25px', paddingLeft: '10px', marginBottom: '15px' }} src={'/assets/images/edit.svg'}></img>
                            <img onClick={this.onOpenModalCopy.bind(this)} title={translate('copyProduct')} style={{ cursor: 'pointer', height: '25px', paddingLeft: '10px', marginBottom: '15px' }} src={'/assets/images/copy-solid.svg'}></img>
                        </div>
                        : null
                    }
                    <h2>
                        {
                            translate('langCode') === 'en' ?
                                item.nameEnglish :
                                translate('langCode') === 'pl' ?
                                    item.namePolish :
                                    translate('langCode') === 'zh' ?
                                        item.nameChinese : null
                        }
                    </h2>
                    {this.props.isLogged ? <h3>{symbol}{item.price}</h3> : null}
                    <h4>{translate('code')}: {item.productCode} </h4>
                    <div className="product-description border-product">
                        <div className="radio" style={{ display: 'grid', marginTop: '10px', width: 'fit-content' }}>
                            <label>
                                <input type="radio" value={this.state.more} checked={this.state.more} onChange={() => this.radioButtonClicked('more')} style={{ marginRight: '5px' }} />
                                {translate('langCode') === 'en' ?
                                    item.moreUnitEnglish :
                                    translate('langCode') === 'pl' ?
                                        item.moreUnitPolish :
                                        translate('langCode') === 'zh' ?
                                            item.moreUnitChinese : null
                                } ({item.moreCapacity}{translate('pcs')})
                            </label>
                            <label>
                                <input type="radio" value={this.state.less} checked={this.state.less} onChange={() => this.radioButtonClicked('less')} style={{ marginRight: '5px' }} />
                                {translate('langCode') === 'en' ?
                                    item.lessUnitEnglish :
                                    translate('langCode') === 'pl' ?
                                        item.lessUnitPolish :
                                        translate('langCode') === 'zh' ?
                                            item.lessUnitChinese : null
                                } ({item.lessCapacity}{translate('pcs')})
                            </label>
                        </div>
                        {this.props.isLogged ?
                            <div>
                                <h6 className="product-title" style={{ textTransform: 'none' }}>{translate('quantity')}</h6>
                                <div className="qty-box">
                                    <div className="input-group">
                                        <span className="input-group-prepend">
                                            <button type="button" className="btn quantity-left-minus" onClick={this.minusQty} data-type="minus" data-field="">
                                                <i className="fa fa-angle-left"></i>
                                            </button>
                                        </span>
                                        <input id="quantityID" type="text" name="quantity" value={this.state.quantity} onChange={this.changeQty} className="form-control input-number" disabled={true} />
                                        <span className="input-group-prepend">
                                            <button type="button" className="btn quantity-right-plus" onClick={this.plusQty} data-type="plus" data-field="">
                                                <i className="fa fa-angle-right"></i>
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            :
                            null}
                    </div>
                    <div className="product-buttons" >
                        {this.props.isLogged ?
                            <button
                                className={this.state.quantity !== 0 ? "btn btn-solid" : null}
                                style={this.state.quantity !== 0 ? null : {
                                    padding: '7px 25px',
                                    cursor: 'auto',
                                    color: '#ffffff !important',
                                    letterSpacing: '0.05em',
                                    border: '2px solid #ff4c3b',
                                    backgroundImage: '-webkit-linear-gradient(30deg, #ff4c3b 50%, transparent 50%)',
                                    backgroundImage: '-webkit-linear-gradient(60deg, #ff4c3b 50%, transparent 50%)',
                                    backgroundImage: 'linear-gradient(30deg, #ff4c3b 50%, transparent 50%)',
                                    backgroundSize: '540px',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: '0',
                                    webkitTransition: 'background 300ms ease-in-out',
                                    transition: 'background 300ms ease-in-out'
                                }}
                                disabled={this.state.quantity !== 0 ? false : true} onClick={() => addToCartClicked(item, this.state.quantity, translate('langCode'))}>{translate('addToCart')}</button>
                            :
                            <Link to={`${process.env.PUBLIC_URL}/login`}
                                className={"btn btn-solid"} style={{
                                    backgroundImage: '-webkit-linear-gradient(30deg, #ff4c3b 100%, transparent 50%)',
                                    backgroundImage: '-webkit-linear-gradient(60deg, #ff4c3b 100%, transparent 50%)',
                                    backgroundImage: 'linear-gradient(30deg, #ff4c3b 100%, transparent 50%)',
                                }}>{translate('loginToBuy')}</Link>
                        }

                    </div>
                    <div className="border-product">
                        <h6 className="product-title">{translate('productDetails')}</h6>
                        <p>
                            {translate('langCode') === 'en' ?
                                item.descriptionEnglish :
                                translate('langCode') === 'pl' ?
                                    item.descriptionPolish :
                                    translate('langCode') === 'zh' ?
                                        item.descriptionChinese : null
                            }
                        </p>
                    </div>
                </div>
                <Modal open={this.state.openDelete} onClose={this.onCloseModalDelete} center>
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    {translate('sureDelete')} {
                                        translate('langCode') === 'en' ?
                                            item.nameEnglish :
                                            translate('langCode') === 'pl' ?
                                                item.namePolish :
                                                translate('langCode') === 'zh' ?
                                                    item.nameChinese : null
                                    } ({item.productCode})?
                                </h5>
                            </div>
                            <div className="modal-body" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <button onClick={this.onCloseModalDelete} className={"btn btn-solid"}>{translate('back')}</button>
                                <button onClick={this.onDeleteClick} className={"btn btn-solid"}>{translate('delete')}</button>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal open={this.state.openEdit} onClose={this.onCloseModalEdit} center>
                    <div className="modal-dialog modal-dialog-centered" role="document" style={{ maxWidth: 'none' }}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">{translate('editProduct')}</h5>
                            </div>
                            <div className="modal-body">
                                <div className="row" style={{ marginTop: '30px' }}>
                                    {this.state.openEdit ? <ToastContainer closeButton={false} pauseOnHover={false} closeOnClick={false} autoClose={3000} /> : null}
                                    <div class="col">
                                        <div className="form-group">
                                            <label htmlFor="productCode" style={{ marginTop: '10px' }}>{translate('productCode')}</label>
                                            <input type="text" className="form-control" id="productCode" value={this.state.productCodeEdit} onChange={(e) => this.onInputValueChange(e, 'productCode')}
                                                required="" />
                                            <label htmlFor="nameEnglish" style={{ marginTop: '10px' }}>{translate('nameEnglish')}</label>
                                            <input type="text" className="form-control" id="nameEnglish" value={this.state.nameEnglishEdit} onChange={(e) => this.onInputValueChange(e, 'nameEnglish')}
                                                required="" />
                                            <label htmlFor="namePolish" style={{ marginTop: '10px' }}>{translate('namePolish')}</label>
                                            <input type="text" className="form-control" id="namePolish" value={this.state.namePolishEdit} onChange={(e) => this.onInputValueChange(e, 'namePolish')}
                                                required="" />
                                            <label htmlFor="nameChinese" style={{ marginTop: '10px' }}>{translate('nameChinese')}</label>
                                            <input type="text" className="form-control" id="nameChinese" value={this.state.nameChineseEdit} onChange={(e) => this.onInputValueChange(e, 'nameChinese')}
                                                required="" />
                                            <label htmlFor="price" style={{ marginTop: '10px' }}>{translate('price')}</label>
                                            <input type="text" className="form-control" id="price" value={this.state.priceEdit} onChange={(e) => this.onInputValueChange(e, 'price')}
                                                required="" />
                                            <label htmlFor="family" style={{ marginTop: '10px' }}>{translate('family')}</label>
                                            <input type="text" className="form-control" id="family" value={this.state.familyEdit} onChange={(e) => this.onInputValueChange(e, 'family')}
                                                required="" />
                                            <label htmlFor="lessCapacity" style={{ marginTop: '10px' }}>{translate('lessCapacity')}</label>
                                            <input type="text" className="form-control" id="lessCapacity" value={this.state.lessCapacityEdit} onChange={(e) => this.onInputValueChange(e, 'lessCapacity')}
                                                required="" />
                                            <label htmlFor="moreCapacity" style={{ marginTop: '10px' }}>{translate('moreCapacity')}</label>
                                            <input type="text" className="form-control" id="moreCapacity" value={this.state.moreCapacityEdit} onChange={(e) => this.onInputValueChange(e, 'moreCapacity')}
                                                required="" />
                                            <label htmlFor="lessUnitEnglish" style={{ marginTop: '10px' }}>{translate('lessUnitEnglish')}</label>
                                            <input type="text" className="form-control" id="lessUnitEnglish" value={this.state.lessUnitEnglishEdit} onChange={(e) => this.onInputValueChange(e, 'lessUnitEnglish')}
                                                required="" />
                                            <label htmlFor="moreUnitEnglish" style={{ marginTop: '10px' }}>{translate('moreUnitEnglish')}</label>
                                            <input type="text" className="form-control" id="moreUnitEnglish" value={this.state.moreUnitEnglishEdit} onChange={(e) => this.onInputValueChange(e, 'moreUnitEnglish')}
                                                required="" />
                                            <label htmlFor="lessUnitPolish" style={{ marginTop: '10px' }}>{translate('lessUnitPolish')}</label>
                                            <input type="text" className="form-control" id="lessUnitPolish" value={this.state.lessUnitPolishEdit} onChange={(e) => this.onInputValueChange(e, 'lessUnitPolish')}
                                                required="" />
                                            <label htmlFor="moreUnitPolish" style={{ marginTop: '10px' }}>{translate('moreUnitPolish')}</label>
                                            <input type="text" className="form-control" id="moreUnitPolish" value={this.state.moreUnitPolishEdit} onChange={(e) => this.onInputValueChange(e, 'moreUnitPolish')}
                                                required="" />
                                            <label htmlFor="lessUnitChinese" style={{ marginTop: '10px' }}>{translate('lessUnitChinese')}</label>
                                            <input type="text" className="form-control" id="lessUnitChinese" value={this.state.lessUnitChineseEdit} onChange={(e) => this.onInputValueChange(e, 'lessUnitChinese')}
                                                required="" />
                                            <label htmlFor="moreUnitChinese" style={{ marginTop: '10px' }}>{translate('moreUnitChinese')}</label>
                                            <input type="text" className="form-control" id="moreUnitChinese" value={this.state.moreUnitChineseEdit} onChange={(e) => this.onInputValueChange(e, 'moreUnitChinese')}
                                                required="" />
                                            <label htmlFor="descriptionEnglish" style={{ marginTop: '10px' }}>{translate('descriptionEnglish')}</label>
                                            <input type="text" className="form-control" id="descriptionEnglish" value={this.state.descriptionEnglishEdit} onChange={(e) => this.onInputValueChange(e, 'descriptionEnglish')}
                                                required="" />
                                            <label htmlFor="descriptionPolish" style={{ marginTop: '10px' }}>{translate('descriptionPolish')}</label>
                                            <input type="text" className="form-control" id="descriptionPolish" value={this.state.descriptionPolishEdit} onChange={(e) => this.onInputValueChange(e, 'descriptionPolish')}
                                                required="" />
                                            <label htmlFor="descriptionChinese" style={{ marginTop: '10px' }}>{translate('descriptionChinese')}</label>
                                            <input type="text" className="form-control" id="descriptionChinese" value={this.state.descriptionChineseEdit} onChange={(e) => this.onInputValueChange(e, 'descriptionChinese')}
                                                required="" />
                                            <label htmlFor="category" style={{ marginTop: '10px' }}>{translate('category')}</label>
                                            <select className="form-control" id="category" onChange={(e) => this.onInputValueChange(e, 'category')}>
                                                <optgroup label={translate('artificialFlowers')} style={{ fontWeight: '600', color: 'rgb(255, 76, 59)' }}>
                                                    <option value="artificialFlowers2" selected={this.props.item.category === 'artificialFlowers2'} style={{ fontWeight: '400', color: '#000000' }}>{translate('artificialFlowers2')}</option>
                                                    <option value="singleFlowers" selected={this.props.item.category === 'singleFlowers'} style={{ fontWeight: '400', color: '#000000' }}>{translate('singleFlowers')}</option>
                                                    <option value="bouquets" selected={this.props.item.category === 'bouquets'} style={{ fontWeight: '400', color: '#000000' }}>{translate('bouquets')}</option>
                                                    <option value="leaves" selected={this.props.item.category === 'leaves'} style={{ fontWeight: '400', color: '#000000' }}>{translate('leaves')}</option>
                                                    <option value="othersArtificialFlowers" selected={this.props.item.category === 'othersArtificialFlowers'} style={{ fontWeight: '400', color: '#000000' }}>{translate('others')}</option>
                                                </optgroup>
                                                <optgroup label={translate('christmas')} style={{ fontWeight: '600', color: 'rgb(255, 76, 59)' }}>
                                                    <option value="glassBaubles" selected={this.props.item.category === 'glassBaubles'} style={{ fontWeight: '400', color: '#000000' }}>{translate('glassBaubles')}</option>
                                                    <option value="plasticBaubles" selected={this.props.item.category === 'plasticBaubles'} style={{ fontWeight: '400', color: '#000000' }}>{translate('plasticBaubles')}</option>
                                                    <option value="decoratedBaubles" selected={this.props.item.category === 'decoratedBaubles'} style={{ fontWeight: '400', color: '#000000' }}>{translate('decoratedBaubles')}</option>
                                                    <option value="christmasTreeSpikes" selected={this.props.item.category === 'christmasTreeSpikes'} style={{ fontWeight: '400', color: '#000000' }}>{translate('christmasTreeSpikes')}</option>
                                                    <option value="othersChristmas" selected={this.props.item.category === 'othersChristmas'} style={{ fontWeight: '400', color: '#000000' }}>{translate('others')}</option>
                                                </optgroup>
                                                <optgroup label={translate('easter')} style={{ fontWeight: '600', color: 'rgb(255, 76, 59)' }}>
                                                    <option value="chickens" selected={this.props.item.category === 'chickens'} style={{ fontWeight: '400', color: '#000000' }}>{translate('chickens')}</option>
                                                    <option value="eggs" selected={this.props.item.category === 'eggs'} style={{ fontWeight: '400', color: '#000000' }}>{translate('eggs')}</option>
                                                    <option value="birdsAndButterflies" selected={this.props.item.category === 'birdsAndButterflies'} style={{ fontWeight: '400', color: '#000000' }}>{translate('birdsAndButterflies')}</option>
                                                    <option value="othersEaster" selected={this.props.item.category === 'othersEaster'} style={{ fontWeight: '400', color: '#000000' }}>{translate('others')}</option>
                                                </optgroup>
                                                <option value="artificialFoundations" selected={this.props.item.category === 'artificialFoundations'} style={{ fontWeight: '600', color: 'rgb(255, 76, 59)' }}>{translate('artificialFoundations')}</option>
                                                <option value="differentAssortment" selected={this.props.item.category === 'differentAssortment'} style={{ fontWeight: '600', color: 'rgb(255, 76, 59)' }}>{translate('differentAssortment')}</option>
                                            </select>
                                        </div>
                                        <div><label style={{ marginTop: '5px' }}>{translate('pictures')}</label></div>
                                        <div><label style={{ marginTop: '5px', textTransform: 'uppercase', color: '#e53935' }}>{translate('beCareful')}</label></div>
                                        <div><label style={{ marginTop: '5px' }}>{translate('maxPictureSize')}</label></div>
                                        <div><label style={{ marginTop: '5px' }}>{translate('recommendedPictureDimensions')}</label></div>
                                        <div><label style={{ marginTop: '5px' }}>{translate('supportedPictureExtensions')}</label></div>
                                        <ImageUploading
                                            onChange={this.onChange}
                                            maxNumber={5}
                                            multiple={true}
                                            maxFileSize={5 * 1024 * 1024}
                                            acceptType={["jpeg", "jpg", "png"]}
                                            resolutionType={"less"}
                                            resolutionWidth={736}
                                            resolutionHeight={1000}
                                        >
                                            {({ imageList, onImageUpload, onImageRemoveAll }) => (
                                                // write your building UI
                                                <div>
                                                    <button className="btn btn-solid" onClick={onImageUpload} style={{ marginBottom: '10px' }}>{translate('uploadImages')}</button>
                                                    <button className="btn btn-solid" onClick={onImageRemoveAll} style={{ marginBottom: '10px', backgroundImage: 'linear-gradient(30deg, #ff4c3b 60%, transparent 50%)', marginLeft: '10px' }}>{translate('removeAllImages')}</button>

                                                    {imageList.map((image) => (
                                                        <div key={image.key}>
                                                            <img src={image.dataURL} />
                                                            <button className="btn btn-solid" onClick={image.onUpdate} style={{ marginBottom: '10px' }}>{translate('update')}</button>
                                                            <button className="btn btn-solid" onClick={image.onRemove} style={{ marginBottom: '10px', marginLeft: '10px' }}>{translate('remove')}</button>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </ImageUploading>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-body" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <button onClick={this.onCloseModalEdit} className={"btn btn-solid"}>{translate('back')}</button>
                                <Loader
                                    type="Puff"
                                    color="#ff4c3b"
                                    height={35}
                                    width={35}
                                    style={{
                                        marginTop: '10px',
                                        marginLeft: '10px'
                                    }}
                                    visible={this.state.loading}
                                />
                                <button onClick={this.onEditClick} className={"btn btn-solid"}>{translate('update')}</button>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal open={this.state.openCopy} onClose={this.onCloseModalCopy} center>
                    <div className="modal-dialog modal-dialog-centered" role="document" style={{ maxWidth: 'none' }}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">{translate('copyProduct')}</h5>
                            </div>
                            <div className="modal-body">
                                <div className="row" style={{ marginTop: '30px' }}>
                                    {this.state.openCopy ? <ToastContainer closeButton={false} pauseOnHover={false} closeOnClick={false} autoClose={3000} /> : null}
                                    <div class="col">
                                        <div className="form-group">
                                            <label htmlFor="productCode" style={{ marginTop: '10px' }}>{translate('productCode')}</label>
                                            <input type="text" className="form-control" id="productCode" value={this.state.productCodeEdit} onChange={(e) => this.onInputValueChange(e, 'productCode')}
                                                required="" />
                                            <label htmlFor="nameEnglish" style={{ marginTop: '10px' }}>{translate('nameEnglish')}</label>
                                            <input type="text" className="form-control" id="nameEnglish" value={this.state.nameEnglishEdit} onChange={(e) => this.onInputValueChange(e, 'nameEnglish')}
                                                required="" />
                                            <label htmlFor="namePolish" style={{ marginTop: '10px' }}>{translate('namePolish')}</label>
                                            <input type="text" className="form-control" id="namePolish" value={this.state.namePolishEdit} onChange={(e) => this.onInputValueChange(e, 'namePolish')}
                                                required="" />
                                            <label htmlFor="nameChinese" style={{ marginTop: '10px' }}>{translate('nameChinese')}</label>
                                            <input type="text" className="form-control" id="nameChinese" value={this.state.nameChineseEdit} onChange={(e) => this.onInputValueChange(e, 'nameChinese')}
                                                required="" />
                                            <label htmlFor="price" style={{ marginTop: '10px' }}>{translate('price')}</label>
                                            <input type="text" className="form-control" id="price" value={this.state.priceEdit} onChange={(e) => this.onInputValueChange(e, 'price')}
                                                required="" />
                                            <label htmlFor="family" style={{ marginTop: '10px' }}>{translate('family')}</label>
                                            <input type="text" className="form-control" id="family" value={this.state.familyEdit} onChange={(e) => this.onInputValueChange(e, 'family')}
                                                required="" />
                                            <label htmlFor="lessCapacity" style={{ marginTop: '10px' }}>{translate('lessCapacity')}</label>
                                            <input type="text" className="form-control" id="lessCapacity" value={this.state.lessCapacityEdit} onChange={(e) => this.onInputValueChange(e, 'lessCapacity')}
                                                required="" />
                                            <label htmlFor="moreCapacity" style={{ marginTop: '10px' }}>{translate('moreCapacity')}</label>
                                            <input type="text" className="form-control" id="moreCapacity" value={this.state.moreCapacityEdit} onChange={(e) => this.onInputValueChange(e, 'moreCapacity')}
                                                required="" />
                                            <label htmlFor="lessUnitEnglish" style={{ marginTop: '10px' }}>{translate('lessUnitEnglish')}</label>
                                            <input type="text" className="form-control" id="lessUnitEnglish" value={this.state.lessUnitEnglishEdit} onChange={(e) => this.onInputValueChange(e, 'lessUnitEnglish')}
                                                required="" />
                                            <label htmlFor="moreUnitEnglish" style={{ marginTop: '10px' }}>{translate('moreUnitEnglish')}</label>
                                            <input type="text" className="form-control" id="moreUnitEnglish" value={this.state.moreUnitEnglishEdit} onChange={(e) => this.onInputValueChange(e, 'moreUnitEnglish')}
                                                required="" />
                                            <label htmlFor="lessUnitPolish" style={{ marginTop: '10px' }}>{translate('lessUnitPolish')}</label>
                                            <input type="text" className="form-control" id="lessUnitPolish" value={this.state.lessUnitPolishEdit} onChange={(e) => this.onInputValueChange(e, 'lessUnitPolish')}
                                                required="" />
                                            <label htmlFor="moreUnitPolish" style={{ marginTop: '10px' }}>{translate('moreUnitPolish')}</label>
                                            <input type="text" className="form-control" id="moreUnitPolish" value={this.state.moreUnitPolishEdit} onChange={(e) => this.onInputValueChange(e, 'moreUnitPolish')}
                                                required="" />
                                            <label htmlFor="lessUnitChinese" style={{ marginTop: '10px' }}>{translate('lessUnitChinese')}</label>
                                            <input type="text" className="form-control" id="lessUnitChinese" value={this.state.lessUnitChineseEdit} onChange={(e) => this.onInputValueChange(e, 'lessUnitChinese')}
                                                required="" />
                                            <label htmlFor="moreUnitChinese" style={{ marginTop: '10px' }}>{translate('moreUnitChinese')}</label>
                                            <input type="text" className="form-control" id="moreUnitChinese" value={this.state.moreUnitChineseEdit} onChange={(e) => this.onInputValueChange(e, 'moreUnitChinese')}
                                                required="" />
                                            <label htmlFor="descriptionEnglish" style={{ marginTop: '10px' }}>{translate('descriptionEnglish')}</label>
                                            <input type="text" className="form-control" id="descriptionEnglish" value={this.state.descriptionEnglishEdit} onChange={(e) => this.onInputValueChange(e, 'descriptionEnglish')}
                                                required="" />
                                            <label htmlFor="descriptionPolish" style={{ marginTop: '10px' }}>{translate('descriptionPolish')}</label>
                                            <input type="text" className="form-control" id="descriptionPolish" value={this.state.descriptionPolishEdit} onChange={(e) => this.onInputValueChange(e, 'descriptionPolish')}
                                                required="" />
                                            <label htmlFor="descriptionChinese" style={{ marginTop: '10px' }}>{translate('descriptionChinese')}</label>
                                            <input type="text" className="form-control" id="descriptionChinese" value={this.state.descriptionChineseEdit} onChange={(e) => this.onInputValueChange(e, 'descriptionChinese')}
                                                required="" />
                                            <label htmlFor="category" style={{ marginTop: '10px' }}>{translate('category')}</label>
                                            <select className="form-control" id="category" onChange={(e) => this.onInputValueChange(e, 'category')}>
                                                <optgroup label={translate('artificialFlowers')} style={{ fontWeight: '600', color: 'rgb(255, 76, 59)' }}>
                                                    <option value="artificialFlowers2" selected={this.props.item.category === 'artificialFlowers2'} style={{ fontWeight: '400', color: '#000000' }}>{translate('artificialFlowers2')}</option>
                                                    <option value="singleFlowers" selected={this.props.item.category === 'singleFlowers'} style={{ fontWeight: '400', color: '#000000' }}>{translate('singleFlowers')}</option>
                                                    <option value="bouquets" selected={this.props.item.category === 'bouquets'} style={{ fontWeight: '400', color: '#000000' }}>{translate('bouquets')}</option>
                                                    <option value="leaves" selected={this.props.item.category === 'leaves'} style={{ fontWeight: '400', color: '#000000' }}>{translate('leaves')}</option>
                                                    <option value="othersArtificialFlowers" selected={this.props.item.category === 'othersArtificialFlowers'} style={{ fontWeight: '400', color: '#000000' }}>{translate('others')}</option>
                                                </optgroup>
                                                <optgroup label={translate('christmas')} style={{ fontWeight: '600', color: 'rgb(255, 76, 59)' }}>
                                                    <option value="glassBaubles" selected={this.props.item.category === 'glassBaubles'} style={{ fontWeight: '400', color: '#000000' }}>{translate('glassBaubles')}</option>
                                                    <option value="plasticBaubles" selected={this.props.item.category === 'plasticBaubles'} style={{ fontWeight: '400', color: '#000000' }}>{translate('plasticBaubles')}</option>
                                                    <option value="decoratedBaubles" selected={this.props.item.category === 'decoratedBaubles'} style={{ fontWeight: '400', color: '#000000' }}>{translate('decoratedBaubles')}</option>
                                                    <option value="christmasTreeSpikes" selected={this.props.item.category === 'christmasTreeSpikes'} style={{ fontWeight: '400', color: '#000000' }}>{translate('christmasTreeSpikes')}</option>
                                                    <option value="othersChristmas" selected={this.props.item.category === 'othersChristmas'} style={{ fontWeight: '400', color: '#000000' }}>{translate('others')}</option>
                                                </optgroup>
                                                <optgroup label={translate('easter')} style={{ fontWeight: '600', color: 'rgb(255, 76, 59)' }}>
                                                    <option value="chickens" selected={this.props.item.category === 'chickens'} style={{ fontWeight: '400', color: '#000000' }}>{translate('chickens')}</option>
                                                    <option value="eggs" selected={this.props.item.category === 'eggs'} style={{ fontWeight: '400', color: '#000000' }}>{translate('eggs')}</option>
                                                    <option value="birdsAndButterflies" selected={this.props.item.category === 'birdsAndButterflies'} style={{ fontWeight: '400', color: '#000000' }}>{translate('birdsAndButterflies')}</option>
                                                    <option value="othersEaster" selected={this.props.item.category === 'othersEaster'} style={{ fontWeight: '400', color: '#000000' }}>{translate('others')}</option>
                                                </optgroup>
                                                <option value="artificialFoundations" selected={this.props.item.category === 'artificialFoundations'} style={{ fontWeight: '600', color: 'rgb(255, 76, 59)' }}>{translate('artificialFoundations')}</option>
                                                <option value="differentAssortment" selected={this.props.item.category === 'differentAssortment'} style={{ fontWeight: '600', color: 'rgb(255, 76, 59)' }}>{translate('differentAssortment')}</option>
                                            </select>
                                        </div>
                                        <div><label style={{ marginTop: '5px' }}>{translate('pictures')}</label></div>
                                        <div><label style={{ marginTop: '5px', textTransform: 'uppercase', color: '#e53935' }}>{translate('copyProductCareful')}</label></div>
                                        <div><label style={{ marginTop: '5px' }}>{translate('maxPictureSize')}</label></div>
                                        <div><label style={{ marginTop: '5px' }}>{translate('recommendedPictureDimensions')}</label></div>
                                        <div><label style={{ marginTop: '5px' }}>{translate('supportedPictureExtensions')}</label></div>
                                        <ImageUploading
                                            onChange={this.onChange}
                                            maxNumber={5}
                                            multiple={true}
                                            maxFileSize={5 * 1024 * 1024}
                                            acceptType={["jpeg", "jpg", "png"]}
                                            resolutionType={"less"}
                                            resolutionWidth={736}
                                            resolutionHeight={1000}
                                        >
                                            {({ imageList, onImageUpload, onImageRemoveAll }) => (
                                                // write your building UI
                                                <div>
                                                    <button className="btn btn-solid" onClick={onImageUpload} style={{ marginBottom: '10px' }}>{translate('uploadImages')}</button>
                                                    <button className="btn btn-solid" onClick={onImageRemoveAll} style={{ marginBottom: '10px', backgroundImage: 'linear-gradient(30deg, #ff4c3b 60%, transparent 50%)', marginLeft: '10px' }}>{translate('removeAllImages')}</button>

                                                    {imageList.map((image) => (
                                                        <div key={image.key}>
                                                            <img src={image.dataURL} />
                                                            <button className="btn btn-solid" onClick={image.onUpdate} style={{ marginBottom: '10px' }}>{translate('update')}</button>
                                                            <button className="btn btn-solid" onClick={image.onRemove} style={{ marginBottom: '10px', marginLeft: '10px' }}>{translate('remove')}</button>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </ImageUploading>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-body" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <button onClick={this.onCloseModalCopy} className={"btn btn-solid"}>{translate('back')}</button>
                                <Loader
                                    type="Puff"
                                    color="#ff4c3b"
                                    height={35}
                                    width={35}
                                    style={{
                                        marginTop: '10px',
                                        marginLeft: '10px'
                                    }}
                                    visible={this.state.loading}
                                />
                                <button onClick={this.addNewProduct.bind(this)} className={"btn btn-solid"}>{translate('addProduct')}</button>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Link to={`${process.env.PUBLIC_URL}/`} style={{ visibility: 'hidden' }} id="toMainScreen"></Link>
            </div>
        )
    }
}


export default withTranslate(DetailsWithPrice);