import React, { Component } from 'react';
import Slider from 'react-slick';
import { withTranslate } from 'react-redux-multilingual'
import { Link } from 'react-router-dom';

import Breadcrumb from "../common/breadcrumb";

class ResetSuccess extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isOK: false,
            isINVALID: false,
        };
    }

    getParameterByName(name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    componentDidMount() {
        var token = this.getParameterByName('token');
        (async () => {
            const rawResponse = await fetch('https://api.fhsitko.pl/api/password/clear', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Origin': '',
                    'Host': 'api.fhsitko.pl'
                },
                body: JSON.stringify({
                    "token": token
                })
            });
            const content = await rawResponse.json();
            if (content.status === 'OK') this.setState({ isOK: true, isINVALID: false });
            else if (content.status === 'INVALID') this.setState({ isOK: false, isINVALID: true });
        })();
    }

    render() {
        const { translate } = this.props;

        return (
            <div>
                {
                    this.state.isOK ?
                        <div>
                            <Breadcrumb title={translate('passwordReset')} />

                            <section className="p-0">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="error-section">
                                                <h2 style={{ color: '#43a047' }}>{translate('resetSuccess')}</h2>
                                                <Link to={`${process.env.PUBLIC_URL}/login`} className="btn btn-solid">{translate('login')}</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                        :
                        this.state.isINVALID ?
                            <div>
                                <Breadcrumb title={translate('passwordReset')} />

                                <section className="p-0">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="error-section">
                                                    <h2 style={{ color: '#e53935' }}>{translate('passwordResetProblem')}</h2>
                                                    <Link to={`${process.env.PUBLIC_URL}/login`} className="btn btn-solid">{translate('passwordReset')}</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                            :
                            null
                }
            </div>
        )
    }
}

export default withTranslate(ResetSuccess)