import React, { Component } from 'react';
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import PaypalExpressBtn from 'react-paypal-express-checkout';
import SimpleReactValidator from 'simple-react-validator';

import Breadcrumb from "../common/breadcrumb";
import { removeFromWishlist } from '../../actions'
import { getCartTotal } from "../../services";
import { withTranslate } from 'react-redux-multilingual'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';

class checkOut extends Component {

    constructor(props) {
        super(props)

        this.state = {
            payment: 'stripe',
            first_name: this.props.userData[0].name,
            last_name: this.props.userData[0].surname,
            phone: this.props.userData[0].phone,
            email: this.props.userData[0].email,
            country: this.props.userData[0].country,
            address: this.props.userData[0].address,
            city: this.props.userData[0].city,
            spincode: this.props.userData[0].postalCode,
            businessName: this.props.userData[0].businessName,
            nip: this.props.userData[0].nip,
            create_account: '',
            unitArray: [],
            loading: false
        }
        this.validator = new SimpleReactValidator();
    }

    componentDidMount() {
        var newUnitArray = [];
        var x = 0;
        for (x in this.props.cartItems) {
            newUnitArray.push('');
        }
        this.setState({ unitArray: newUnitArray })
    }

    setStateFromInput = (event) => {
        var obj = {};
        obj[event.target.name] = event.target.value;
        this.setState(obj);

    }

    setStateFromCheckbox = (event) => {
        var obj = {};
        obj[event.target.name] = event.target.checked;
        this.setState(obj);

        if (!this.validator.fieldValid(event.target.name)) {
            this.validator.showMessages();
        }
    }

    checkhandle(value) {
        this.setState({
            payment: value
        })
    }

    StripeClick = () => {
        var x = 0;
        var error = false;
        for (x in this.state.unitArray) {
            this.state.unitArray[x] === '' ? error = true : error = false
        }
        if (!error) {
            this.setState({ loading: true });
            var totalPrice = this.props.total > 12500 ? Math.round((this.props.total * 0.85 + Number.EPSILON) * 100) / 100 :
                this.props.total > 8000 ? Math.round((this.props.total * 0.9 + Number.EPSILON) * 100) / 100 :
                    this.props.total > 6000 ? Math.round((this.props.total * 0.93 + Number.EPSILON) * 100) / 100 :
                        this.props.total > 3500 ? Math.round((this.props.total * 0.95 + Number.EPSILON) * 100) / 100 :
                            this.props.total > 2000 ? Math.round((this.props.total * 0.97 + Number.EPSILON) * 100) / 100 :
                                Math.round((this.props.total + Number.EPSILON) * 100) / 100
            var productsVar = [];
            var x = 0;
            for (x in this.props.cartItems) {
                productsVar.push({
                    "id": this.props.cartItems[x].id, "quantity": this.props.cartItems[x].qty, "packageType": this.state.unitArray[x] === 'more' ? this.props.cartItems[x].moreUnitPolish : this.props.cartItems[x].lessUnitPolish,
                    "packageTypeUser":
                        this.state.unitArray[x] === 'more' ?
                            this.props.translate('langCode') === 'en' ? this.props.cartItems[x].moreUnitEnglish :
                                this.props.translate('langCode') === 'pl' ? this.props.cartItems[x].moreUnitPolish :
                                    this.props.translate('langCode') === 'zh' ? this.props.cartItems[x].moreUnitChinese :
                                        null :
                            this.props.translate('langCode') === 'en' ? this.props.cartItems[x].lessUnitEnglish :
                                this.props.translate('langCode') === 'pl' ? this.props.cartItems[x].lessUnitPolish :
                                    this.props.translate('langCode') === 'zh' ? this.props.cartItems[x].lessUnitChinese
                                        : null
                })
            }
            if (this.validator.allValid()) {
                (async () => {
                    const rawResponse = await fetch('https://api.fhsitko.pl/api/reservation', {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Origin': '',
                            'Authorization': 'Bearer ' + this.props.token,
                            'Host': 'api.fhsitko.pl'
                        },
                        body: JSON.stringify({
                            "email": this.props.userData[0].email,
                            "name": this.props.userData[0].name,
                            "surname": this.props.userData[0].surname,
                            "companyName": this.props.userData[0].businessName,
                            "address": this.props.userData[0].address,
                            "phone": this.props.userData[0].phone,
                            "city": this.props.userData[0].city,
                            "postalCode": this.props.userData[0].postalCode,
                            "nip": this.props.userData[0].nip,
                            "country": this.props.userData[0].country,
                            "total": totalPrice,
                            "products": productsVar
                        })
                    })
                    const content = await rawResponse.json();
                    if (content.status === 'OK') {
                        this.setState({ loading: false });
                        var y = 0;
                        for (y in this.props.cartItems) {
                            document.getElementById('removeAllItems').click();
                        }
                        document.getElementById('toMainScreen').click();
                        toast.success(this.props.translate('orderSuccess'));
                    }
                    else {
                        this.setState({ loading: false });
                        toast.error(this.props.translate('orderFailed'));
                    }
                })();
            }
        }
        else {
            toast.error(this.props.translate('prefferedUnitError'))
        }
    }

    radioButtonClicked(unit, index) {
        var newUnitArray = this.state.unitArray;
        newUnitArray[index] = unit;
        this.setState({ unitArray: newUnitArray });
    }

    render() {
        const { cartItems, symbol, total, translate } = this.props;

        // Paypal Integration
        const onSuccess = (payment) => {
            console.log("The payment was succeeded!", payment);
            this.props.history.push({
                pathname: '/order-success',
                state: { payment: payment, items: cartItems, orderTotal: total, symbol: symbol }
            })

        }

        const onCancel = (data) => {
            console.log('The payment was cancelled!', data);
        }

        const onError = (err) => {
            console.log("Error!", err);
        }

        const client = {
            sandbox: 'AZ4S98zFa01vym7NVeo_qthZyOnBhtNvQDsjhaZSMH-2_Y9IAJFbSD3HPueErYqN8Sa8WYRbjP7wWtd_',
            production: 'AZ4S98zFa01vym7NVeo_qthZyOnBhtNvQDsjhaZSMH-2_Y9IAJFbSD3HPueErYqN8Sa8WYRbjP7wWtd_',
        }


        return (
            <div>

                {/*SEO Support*/}
                <Helmet>
                    <title>F.H Sitko</title>
                    <meta name="description" content="F.H. Sitko" />
                </Helmet>
                <ToastContainer closeButton={false} pauseOnHover={false} closeOnClick={false} autoClose={3000}/>
                {/*SEO Support End */}

                <Breadcrumb title={translate('checkout')} />
                {this.props.isLogged ?
                    <section className="section-b-space">
                        <div className="container padding-cls">
                            <div className="checkout-page">
                                <div className="checkout-form">
                                    <form>
                                        <div className="checkout row">
                                            <div className="col-lg-6 col-sm-12 col-xs-12">
                                                <div className="checkout-title">
                                                    <h3>{translate('customerData')}</h3><br></br>
                                                    <span>{translate('changeCustomerData')}</span>
                                                </div>
                                                <div className="row check-out">
                                                    <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                                        <div className="field-label">{translate('name')}</div>
                                                        <input disabled={true} type="text" name="first_name" value={this.state.first_name} onChange={this.setStateFromInput} />
                                                    </div>
                                                    <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                                        <div className="field-label">{translate('surname')}</div>
                                                        <input disabled={true} type="text" name="last_name" value={this.state.last_name} onChange={this.setStateFromInput} />
                                                    </div>
                                                    <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                                        <div className="field-label">{translate('phoneNumber')}</div>
                                                        <input disabled={true} type="text" name="phone" value={this.state.phone} onChange={this.setStateFromInput} />
                                                    </div>
                                                    <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                                        <div className="field-label">{translate('email')}</div>
                                                        <input disabled={true} type="text" name="email" value={this.state.email} onChange={this.setStateFromInput} />
                                                    </div>
                                                    <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                                        <div className="field-label">NIP</div>
                                                        <input disabled={true} type="text" name="nip" value={this.state.nip} onChange={this.setStateFromInput} />
                                                    </div>
                                                    <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                                        <div className="field-label">{translate('companyName')}</div>
                                                        <input disabled={true} type="text" name="businessName" value={this.state.businessName} onChange={this.setStateFromInput} />
                                                    </div>
                                                    <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                                        <div className="field-label">{translate('country')}</div>
                                                        <input disabled={true} type="text" name="country" value={this.state.country} onChange={this.setStateFromInput} />
                                                    </div>
                                                    <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                                        <div className="field-label">{translate('postalCode')}</div>
                                                        <input disabled={true} type="text" name="spincode" value={this.state.spincode} onChange={this.setStateFromInput} />
                                                    </div>
                                                    <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                                        <div className="field-label">{translate('city')}</div>
                                                        <input disabled={true} type="text" name="city" value={this.state.city} onChange={this.setStateFromInput} />
                                                    </div>
                                                    <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                                        <div className="field-label">{translate('address')}</div>
                                                        <input disabled={true} type="text" name="address" value={this.state.address} onChange={this.setStateFromInput} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-sm-12 col-xs-12">
                                                <div className="checkout-details">
                                                    <div className="order-box">
                                                        <div className="title-box">
                                                            <div>{translate('products')} </div>
                                                        </div>
                                                        <ul className="qty">
                                                            {cartItems.map((item, index) => {
                                                                return <li style={{ borderBottomStyle: 'solid', borderBottomWidth: '1px', paddingBottom: '20px', marginBottom: '0px' }} key={index}>{
                                                                    translate('langCode') === 'en' ?
                                                                        item.nameEnglish :
                                                                        translate('langCode') === 'pl' ?
                                                                            item.namePolish :
                                                                            translate('langCode') === 'zh' ?
                                                                                item.nameChinese : null
                                                                } ({item.productCode}) × {item.qty}{translate('pcs')} <br></br><br></br>
                                                                    {translate('prefferedUnit')}
                                                                    <div className="radio" style={{ display: 'grid', marginTop: '10px', width: 'fit-content' }}>
                                                                        <label>
                                                                            <input type="radio" checked={this.state.unitArray[index] === 'more'} onChange={() => this.radioButtonClicked('more', index)} style={{ marginRight: '5px' }} />
                                                                            {translate('langCode') === 'en' ?
                                                                                item.moreUnitEnglish :
                                                                                translate('langCode') === 'pl' ?
                                                                                    item.moreUnitPolish :
                                                                                    translate('langCode') === 'zh' ?
                                                                                        item.moreUnitChinese : null
                                                                            }
                                                                        </label>
                                                                        <label>
                                                                            <input type="radio" checked={this.state.unitArray[index] === 'less'} onChange={() => this.radioButtonClicked('less', index)} style={{ marginRight: '5px' }} />
                                                                            {translate('langCode') === 'en' ?
                                                                                item.lessUnitEnglish :
                                                                                translate('langCode') === 'pl' ?
                                                                                    item.lessUnitPolish :
                                                                                    translate('langCode') === 'zh' ?
                                                                                        item.lessUnitChinese : null
                                                                            }
                                                                        </label>
                                                                    </div>
                                                                        <span>{symbol} {Math.round(((item.qty * item.price) + Number.EPSILON) * 100) / 100}</span></li>
                                                            })
                                                            }
                                                        </ul>
                                                        <ul className="total">
                                                            <li>{translate('total')}
                                                                <span className="count">{symbol}
                                                                    {
                                                                        this.props.total > 12500 ? Math.round((this.props.total * 0.85 + Number.EPSILON) * 100) / 100 + translate('shippingCosts') :
                                                                            this.props.total > 8000 ? Math.round((this.props.total * 0.9 + Number.EPSILON) * 100) / 100 + translate('shippingCosts') :
                                                                                this.props.total > 6000 ? Math.round((this.props.total * 0.93 + Number.EPSILON) * 100) / 100 + translate('shippingCosts') :
                                                                                    this.props.total > 3500 ? Math.round((this.props.total * 0.95 + Number.EPSILON) * 100) / 100 + translate('shippingCosts') :
                                                                                        this.props.total > 2000 ? Math.round((this.props.total * 0.97 + Number.EPSILON) * 100) / 100 + translate('shippingCosts') :
                                                                                            Math.round((this.props.total + Number.EPSILON) * 100) / 100 + translate('shippingCosts')
                                                                    }
                                                                </span>
                                                            </li>
                                                            <li>{this.props.total > 2000 ? <u> {translate('discountInfoCheckout')} </u> : null}</li>
                                                        </ul>
                                                    </div>

                                                    <div className="payment-box">
                                                        {(this.props.total >= 500) ?
                                                            <div className="text-right">
                                                                <Loader
                                                                    type="Puff"
                                                                    color="#ff4c3b"
                                                                    height={35}
                                                                    width={35}
                                                                    style={{
                                                                        marginTop: '10px',
                                                                        marginLeft: '10px'
                                                                    }}
                                                                    visible={this.state.loading}
                                                                />
                                                                <button type="button" className="btn-solid btn" onClick={() => this.StripeClick()} >{translate('placeOrder')}</button>
                                                            </div>
                                                            :
                                                            <div className="text-right">
                                                                <span style={{ color: '#e53935' }}>{translate('minimumOrder')}</span>
                                                            </div>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </section>
                    :
                    <section className="cart-section section-b-space">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div >
                                        <div className="col-sm-12 empty-cart-cls text-center">
                                            <img src={`${process.env.PUBLIC_URL}/assets/images/stop.png`} className="img-fluid mb-4" alt="" />
                                            <h3>
                                                <strong>{translate('pleaseLogin')}</strong>
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                }
                <Link to={`${process.env.PUBLIC_URL}/`} style={{ visibility: 'hidden' }} id="toMainScreen"></Link>
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    cartItems: state.cartList.cart,
    symbol: state.data.symbol,
    total: getCartTotal(state.cartList.cart)
})

export default withTranslate(connect(
    mapStateToProps,
    { removeFromWishlist }
)(checkOut))