import React, { Component } from 'react';
import Slider from 'react-slick';

import Breadcrumb from "../common/breadcrumb";
import { Slider2, Team4 } from "../../services/script"
import { withTranslate } from 'react-redux-multilingual'

class aboutUs extends Component {

    constructor(props) {
        super(props)

    }

    render() {
        const { translate } = this.props;

        return (
            <div>
                <Breadcrumb title={translate('about_us')} />
                {/*about section*/}
                <section className="about-page  section-b-space" style={{paddingTop: '20px'}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="banner-section">
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/about/about%20us.jpg`} className="img-fluid" alt="" />
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <h4>{translate('aboutHeader')}</h4>
                                <p>{translate('aboutContent1')}</p><br></br>
                                <p>{translate('aboutContent2')}</p>
                                <p>{translate('aboutContent3')}</p>
                                <p>{translate('aboutContent4')}</p>
                                <p>{translate('aboutContent5')}</p>
                                <p>{translate('aboutContent6')}</p>
                                <p>{translate('aboutContent7')}</p><br></br>
                                <p>{translate('aboutContent8')}</p>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}

export default withTranslate(aboutUs);