import React, { Component } from 'react';
import Breadcrumb from "../common/breadcrumb";
import { withTranslate } from 'react-redux-multilingual'

class Contact extends Component {

    constructor(props) {
        super(props)
    }

    render() {
        const { translate } = this.props;


        return (
            <div>
                <Breadcrumb title={translate('contact')} />


                {/*Forget Password section*/}
                <section className=" contact-page section-b-space" style={{paddingTop: '20px'}}>
                    <div className="container">
                        <div className="row section-b-space">
                            <div className="col-lg-7 map">
                                <iframe
                                    src="https://maps.google.com/maps?q=Centrum%20Gie%C5%82dowe%20Balicka%20%20&t=&z=13&ie=UTF8&iwloc=&output=embed"
                                    allowFullScreen></iframe>

                            </div>
                            <div className="col-lg-5">
                                <p>{translate('contactContent1')}</p>
                                <div className="contact-right">
                                    <ul>
                                        <li>
                                            <div className="contact-icon">
                                                <img src={`${process.env.PUBLIC_URL}/assets/images/icon/phone.png`} alt="Generic placeholder image" />
                                                <h6>{translate('call_us')}</h6>
                                            </div>
                                            <div className="media-body">
                                                <p><a href="callto:+48603104321" target="_blank">+48 603 104 321</a></p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="contact-icon">
                                                <i className="fa fa-map-marker" aria-hidden="true"></i>
                                                <h6>{translate('address')}</h6>
                                            </div>
                                            <div className="media-body">
                                                <p>Centrum Giełdowe Balicka</p>
                                                <p>30-149 Kraków, ul. Balicka 56/20B</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="contact-icon">
                                                <img src={`${process.env.PUBLIC_URL}/assets/images/icon/email.png`} alt="Generic placeholder image" />
                                                <h6>{translate('address')}</h6>
                                            </div>
                                            <div className="media-body">
                                                <p><a href="mailto:greg-sit@o2.pl" target="_blank">greg-sit@o2.pl</a></p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <p style={{paddingLeft: '15px', paddingRight: '15px'}}><br></br>
                                <br></br>
                                {translate('contactContent2')}<br></br>
                                <b>Śląska Giełda Kwiatowa,</b><br></br>
                                <b>43-100 Tychy, ul. Sadowa 3/H10-H12.</b></p>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}

export default withTranslate(Contact);