import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { withTranslate } from 'react-redux-multilingual'

const CartHeader = ({ item, total, symbol, removeFromCart, translate }) => (
    <li >
        <div className="media">
            <Link to={`${process.env.PUBLIC_URL}/product/${item.id}`}><img alt=""
                className="mr-3" 
                src={item.pictures[0] !== undefined ? `${item.pictures[0].filename}` : '/assets/images/defaultFlower.png'} /></Link>
            <div className="media-body">
                <Link to={`${process.env.PUBLIC_URL}/product/${item.id}`}><h4>
                    {
                        translate('langCode') === 'en' ?
                            item.nameEnglish :
                            translate('langCode') === 'pl' ?
                                item.namePolish :
                                translate('langCode') === 'zh' ?
                                    item.nameChinese : null
                    }
                </h4></Link>
                <h4><span>{item.qty} x {symbol} {(item.price)}</span></h4>
            </div>
        </div>
        {/*<span>{cart}</span>*/}
        <div className="close-circle">
            <a href={null} id={'removeAllItems'} onClick={removeFromCart}><i className="fa fa-times" aria-hidden="true"></i></a>
        </div>
    </li>
)



export default withTranslate(CartHeader);
