import React, { Component } from 'react';

import Breadcrumb from "../common/breadcrumb";
import { withTranslate } from 'react-redux-multilingual'
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Modal from 'react-responsive-modal';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'

class Login extends Component {

    constructor(props) {
        super(props)
        this.state = {
            email: '',
            emailRecover: '',
            password: '',
            open: false,
            loading: false
        };
    }

    validateEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    sendForgotPassword() {
        if (!this.validateEmail(this.state.emailRecover)) {
            this.setState({ open: false });
            toast.error(this.props.translate('wrongEmail'));
        }
        else {
            this.setState({ loading: true });
            (async () => {
                const rawResponse = await fetch('https://api.fhsitko.pl/api/password/create', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Origin': '',
                        'Host': 'api.fhsitko.pl'
                    },
                    body: JSON.stringify({
                        "email": this.state.emailRecover
                    })
                });
                const content = await rawResponse.json();
                this.setState({ loading: false });
                this.setState({ open: false });
                toast.success(this.props.translate('sendConfirm'));
            })();
        }
    }

    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };

    onEmailChange(event) {
        this.setState({ email: event.target.value })
    }

    onEmailRecoverChange(event) {
        this.setState({ emailRecover: event.target.value })
    }

    onPassChange(event) {
        this.setState({ password: event.target.value })
    }

    handleLogin() {
        this.props.loggingHandle([this.state.email, this.state.password], this.props.translate('langCode'));
    }

    render() {
        const { translate } = this.props;


        return (
            <div>
                <Breadcrumb title={translate('logging')} />

                {
                    !this.props.isLogged ?
                        <section className="login-page section-b-space" style={{paddingTop: '20px'}}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <h3>{translate('logging')}</h3>
                                        <div className="theme-card">
                                            <div className="form-group">
                                                <label htmlFor="email">{translate('email')}</label>
                                                <input type="text" className="form-control" id="email" placeholder={translate('emailEnter')} value={this.state.email} onChange={(e) => this.onEmailChange(e)}
                                                    required="" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="password">{translate('password')}</label>
                                                <input type="password" className="form-control" id="password"
                                                    placeholder={translate('passwordEnter')} required="" value={this.state.password} onChange={(e) => this.onPassChange(e)} />
                                            </div>
                                            <Link to={`${process.env.PUBLIC_URL}/`} className="btn btn-solid" onClick={() => this.handleLogin()}>{translate('login')}</Link>
                                            <div style={{ textAlign: 'left', marginTop: '10px' }}>
                                                <a onClick={this.onOpenModal.bind(this)} style={{ cursor: 'pointer', marginRight: '10px', color: '#ff4c3b' }}>{translate('forgetPassword')}</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 right-login">
                                        <h3>{translate('newCustomer')}</h3>
                                        <div className="theme-card authentication-right">
                                            <h6 className="title-font">{translate('createAccount')}</h6>
                                            <p>{translate('registerTip')}</p>
                                            <Link to={`${process.env.PUBLIC_URL}/register`} className="btn btn-solid">{translate('createAccount')}</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        :
                        <section className="cart-section section-b-space">
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div >
                                            <div className="col-sm-12 empty-cart-cls text-center">
                                                <img src={`${process.env.PUBLIC_URL}/assets/images/stop.png`} className="img-fluid mb-4" alt="" />
                                                <h3>
                                                    <strong>{translate('alreadyLogin')}</strong>
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                }

                <Modal open={this.state.open} onClose={this.onCloseModal} center>
                    <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                        <div className="modal-content quick-view-modal">
                            <div className="modal-body">
                                <div className="row" style={{ marginTop: '30px' }}>
                                    <div class="col">
                                        <div className="form-group">
                                            <label htmlFor="emailRecover">{translate('provideEmail')}</label>
                                            <input type="text" className="form-control" id="emailRecover" placeholder={translate('emailEnter')} value={this.state.emailRecover} onChange={(e) => this.onEmailRecoverChange(e)}
                                                required="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="product-buttons">
                                    <Loader
                                        type="Puff"
                                        color="#ff4c3b"
                                        height={35}
                                        width={35}
                                        style={{
                                            marginTop: '10px',
                                            marginLeft: '10px'
                                        }}
                                        visible={this.state.loading}
                                    />
                                    <button className="btn btn-solid" onClick={this.sendForgotPassword.bind(this)} >{translate('send')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>

            </div>
        )
    }
}

export default withTranslate(Login)