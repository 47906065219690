import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Breadcrumb from "../common/breadcrumb";
import { withTranslate } from 'react-redux-multilingual'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import store from '../../store';
import { IntlActions } from 'react-redux-multilingual'

class Dashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            editModeAccount: false,
            editModeAddress: false,
            editModePass: false
        };
    }

    changeEditModeAccount() {
        this.setState({ editModeAccount: !this.state.editModeAccount });
    }

    changeEditModeAddress() {
        this.setState({ editModeAddress: !this.state.editModeAddress });
    }

    changeEditModePass() {
        this.setState({ editModePass: !this.state.editModePass });
    }

    isValidNip(nip) {
        if (typeof nip !== 'string')
            return false;

        nip = nip.replace(/[\ \-]/gi, '');

        let weight = [6, 5, 7, 2, 3, 4, 5, 6, 7];
        let sum = 0;
        let controlNumber = parseInt(nip.substring(9, 10));
        let weightCount = weight.length;
        for (let i = 0; i < weightCount; i++) {
            sum += (parseInt(nip.substr(i, 1)) * weight[i]);
        }

        return sum % 11 === controlNumber;
    }

    validateEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    saveAccountSettings() {
        if (document.getElementById('email').value === this.props.userData[0].email && document.getElementById('defaultLanguage').value === this.props.userData[0].defaultLanguage) {
            toast.error(this.props.translate('noChanges'))
        }
        else if (!this.validateEmail(document.getElementById('email').value)) {
            toast.error(this.props.translate('wrongEmail'));
        }
        else {
            (async () => {
                const rawResponse = await fetch('https://api.fhsitko.pl/api/basedata/edit', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Origin': '',
                        'Authorization': 'Bearer ' + this.props.token,
                        'Host': 'api.fhsitko.pl'
                    },
                    body: JSON.stringify({
                        "email": document.getElementById('email').value,
                        "defaultLanguage": document.getElementById('defaultLanguage').value
                    })
                });
                const content = await rawResponse.json();
                if (content.error === true) toast.error(this.props.translate('emailAlreadyTaken'));
                else {
                    var newUserData = this.props.userData;
                    newUserData[0].email = document.getElementById('email').value;
                    newUserData[0].defaultLanguage = document.getElementById('defaultLanguage').value;
                    this.props.userDataChangeHandle(newUserData, this.props.translate('successSave'));
                    this.setState({ editModeAccount: !this.state.editModeAccount });
                    store.dispatch(IntlActions.setLocale(newUserData[0].defaultLanguage.toLowerCase()))
                    if (newUserData[0].defaultLanguage.toLowerCase() === 'en') toast.success('Changes have been made successfully.')
                    else if (newUserData[0].defaultLanguage.toLowerCase() === 'pl') toast.success('Pomyślnie dokonano zmian.')
                    else if (newUserData[0].defaultLanguage.toLowerCase() === 'zh') toast.success('更改已成功完成。')
                }
            })();
        }
    }

    saveAddressSettings() {
        if (
            document.getElementById('name').value === this.props.userData[0].name &&
            document.getElementById('surname').value === this.props.userData[0].surname &&
            (document.getElementById('NIP').value === this.props.userData[0].nip || (document.getElementById('NIP').value === '' && this.props.userData[0].nip === null)) &&
            document.getElementById('companyName').value === this.props.userData[0].businessName &&
            document.getElementById('address').value === this.props.userData[0].address &&
            document.getElementById('phoneNumber').value === this.props.userData[0].phone &&
            document.getElementById('city').value === this.props.userData[0].city &&
            document.getElementById('postalCode').value === this.props.userData[0].postalCode &&
            (document.getElementById('country').value === this.props.userData[0].country || (document.getElementById('country').value === '' && this.props.userData[0].country === null))
        ) {
            toast.error(this.props.translate('noChanges'))
        }
        else if (document.getElementById('name').value.replace(/\s/g, '') === '') {
            toast.error(this.props.translate('voidName'));
        }
        else if (document.getElementById('surname').value.replace(/\s/g, '') === '') {
            toast.error(this.props.translate('voidSurname'));
        }
        else if (document.getElementById('companyName').value.replace(/\s/g, '') === '') {
            toast.error(this.props.translate('voidCompanyName'));
        }
        else if (document.getElementById('address').value.replace(/\s/g, '') === '') {
            toast.error(this.props.translate('voidAddress'));
        }
        else if (document.getElementById('phoneNumber').value.replace(/\s/g, '') === '') {
            toast.error(this.props.translate('voidPhone'));
        }
        else if (document.getElementById('city').value.replace(/\s/g, '') === '') {
            toast.error(this.props.translate('voidCity'));
        }
        else if (document.getElementById('postalCode').value.replace(/\s/g, '') === '') {
            toast.error(this.props.translate('voidPostalCode'));
        }
        else if (!this.isValidNip(document.getElementById('NIP').value) && document.getElementById('NIP').value !== '') {
            toast.error(this.props.translate('wrongNip'));
        }
        else {
            (async () => {
                const rawResponse = await fetch('https://api.fhsitko.pl/api/details/edit', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Origin': '',
                        'Authorization': 'Bearer ' + this.props.token,
                        'Host': 'api.fhsitko.pl'
                    },
                    body: JSON.stringify({
                        name: document.getElementById('name').value,
                        surname: document.getElementById('surname').value,
                        nip: document.getElementById('NIP').value,
                        company_name: document.getElementById('companyName').value,
                        address: document.getElementById('address').value,
                        phone: document.getElementById('phoneNumber').value,
                        city: document.getElementById('city').value,
                        postal_code: document.getElementById('postalCode').value,
                        country: document.getElementById('country').value
                    })
                });
                const content = await rawResponse.json();
            })();

            var newUserData = this.props.userData;
            newUserData[0].name = document.getElementById('name').value;
            newUserData[0].surname = document.getElementById('surname').value;
            newUserData[0].businessName = document.getElementById('companyName').value;
            newUserData[0].nip = document.getElementById('NIP').value;
            newUserData[0].phone = document.getElementById('phoneNumber').value;
            newUserData[0].address = document.getElementById('address').value;
            newUserData[0].city = document.getElementById('city').value;
            newUserData[0].postalCode = document.getElementById('postalCode').value;
            newUserData[0].country = document.getElementById('country').value;
            this.props.userDataChangeHandle(newUserData);
            this.setState({ editModeAddress: !this.state.editModeAddress });
            toast.success(this.props.translate('changesSuccess'))
        }
    }

    saveNewPass() {
        if (document.getElementById('newPassword').value.replace(/\s/g, '') === '') {
            toast.error(this.props.translate('emptyNewPassword'));
        }
        else if (document.getElementById('oldPassword').value.replace(/\s/g, '') === '') {
            toast.error(this.props.translate('emptyOldPassword'));
        }
        else if (document.getElementById('newPassword').value !== document.getElementById('repeatNewPassword').value) {
            toast.error(this.props.translate('passNotMatch'));
        }
        else {
            (async () => {
                const rawResponse = await fetch('https://api.fhsitko.pl/api/password/edit', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Origin': '',
                        'Authorization': 'Bearer ' + this.props.token,
                        'Host': 'api.fhsitko.pl'
                    },
                    body: JSON.stringify({
                        oldPassword: document.getElementById('oldPassword').value,
                        newPassword: document.getElementById('newPassword').value
                    })
                });
                const content = await rawResponse.json();
                if (content.error === "Password do not match") {
                    toast.error(this.props.translate('wrongOldPass'))
                }
                else {
                    this.setState({ editModePass: !this.state.editModePass });
                    toast.success(this.props.translate('changesSuccess'))
                }
            })();

        }
    }

    render() {
        const { translate } = this.props;


        return (
            <div>
                <Breadcrumb title={translate('dashboard')} />

                {/*Dashboard section*/}
                {
                    this.props.isLogged ?
                        <section className="section-b-space" style={{paddingTop: '20px'}}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-3">
                                        <div className="account-sidebar">
                                            <a className="popup-btn">
                                                {translate('my_account')}
                                            </a>
                                        </div>
                                        <div className="dashboard-left">
                                            <div className="collection-mobile-back">
                                                <span className="filter-back">
                                                    <i className="fa fa-angle-left" aria-hidden="true"></i> back
                                    </span>
                                            </div>
                                            <div className="block-content">
                                                <ul>
                                                    <li className="active"><a style={{ cursor: 'pointer' }}>{translate('accountInfo')}</a></li>
                                                    <li>
                                                        <Link to={`${process.env.PUBLIC_URL}/login`} data-lng="en" onClick={() => this.handleLogout()}>
                                                            {translate('logout')}
                                                        </Link>
                                                    </li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-9">
                                        <div className="dashboard-right">
                                            <div className="dashboard">
                                                <div className="box-account box-info">
                                                    <div className="box-head">
                                                        <h2>{translate('dashboard')}</h2>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <div className="box">
                                                                <div className="box-title">
                                                                    <h3>{translate('loginInfo')}</h3>
                                                                    {this.state.editModeAccount ?
                                                                        <div style={{ textAlign: 'right' }}>
                                                                            <a onClick={this.changeEditModeAccount.bind(this)} style={{ cursor: 'pointer', marginRight: '10px' }}>{translate('cancel')}</a>
                                                                            <a onClick={this.saveAccountSettings.bind(this)} style={{ cursor: 'pointer' }}>{translate('save')}</a>
                                                                        </div>
                                                                        :
                                                                        <a onClick={this.changeEditModeAccount.bind(this)} style={{ cursor: 'pointer' }}>{translate('edit')}</a>
                                                                    }
                                                                </div>
                                                                <div className="box-content">
                                                                    {this.state.editModeAccount ?
                                                                        <div style={{ display: 'grid' }}>
                                                                            <div style={{ marginTop: '5px' }}>
                                                                                <label for="email">{translate('email')}:</label>
                                                                                <input type="text" id="email" name="email" defaultValue={this.props.userData[0].email} style={{ marginLeft: '10px' }}></input>
                                                                            </div>
                                                                            <div>
                                                                                <label htmlFor="defaultLanguage">{translate('defaultLanguage')}</label>
                                                                                <select id="defaultLanguage" name="defaultLanguage" style={{ marginLeft: '10px' }}>
                                                                                    <option selected={this.props.userData[0].defaultLanguage === 'PL' ? 'selected' : null} value="PL" >PL</option>
                                                                                    <option selected={this.props.userData[0].defaultLanguage === 'EN' ? 'selected' : null} value="EN">EN</option>
                                                                                    <option selected={this.props.userData[0].defaultLanguage === 'ZH' ? 'selected' : null} value="ZH">ZH</option>
                                                                                </select>
                                                                            </div>

                                                                            <h6>{translate('password')}: <b>********</b></h6>
                                                                        </div>
                                                                        :
                                                                        <div>
                                                                            <h6>{translate('email')}: <b>{this.props.userData[0].email}</b></h6>
                                                                            <h6>{translate('defaultLanguage')}:
                                                                        {this.props.userData[0].defaultLanguage === 'EN' ?
                                                                                    <b> EN</b> :
                                                                                    this.props.userData[0].defaultLanguage === 'PL' ?
                                                                                        <b> PL</b> : this.props.userData[0].defaultLanguage === 'ZH' ?
                                                                                            <b> ZH</b> : null
                                                                                }
                                                                            </h6>
                                                                            {this.state.editModePass ?
                                                                                <div style={{ display: 'grid' }}>
                                                                                    <div style={{ marginTop: '5px' }}>
                                                                                        <label for="oldPassword">{translate('oldPassword')}:</label>
                                                                                        <input type="password" id="oldPassword" name="oldPassword" style={{ marginLeft: '10px' }}></input>
                                                                                    </div>
                                                                                    <div>
                                                                                        <label for="newPassword">{translate('newPassword')}:</label>
                                                                                        <input type="password" id="newPassword" name="newPassword" style={{ marginLeft: '10px' }}></input>
                                                                                    </div>
                                                                                    <div>
                                                                                        <label for="repeatNewPassword">{translate('repeatNewPassword')}:</label>
                                                                                        <input type="password" id="repeatNewPassword" name="repeatNewPassword" style={{ marginLeft: '10px' }}></input>
                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                <h6>{translate('password')}: <b>********</b></h6>
                                                                            }

                                                                            {this.state.editModePass ?
                                                                                <div>
                                                                                    <a onClick={this.changeEditModePass.bind(this)} style={{ cursor: 'pointer', marginRight: '10px' }}>{translate('cancel')}</a>
                                                                                    <a onClick={this.saveNewPass.bind(this)} style={{ cursor: 'pointer' }}>{translate('save')}</a>
                                                                                </div>
                                                                                :
                                                                                <h6><a onClick={this.changeEditModePass.bind(this)} style={{ cursor: 'pointer' }}>{translate('changePassword')}</a></h6>
                                                                            }

                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="box">
                                                                <div className="box-title">
                                                                    <h3>{translate('contactInformation')}</h3>
                                                                    {this.state.editModeAddress ?
                                                                        <div style={{ textAlign: 'right' }}>
                                                                            <a onClick={this.changeEditModeAddress.bind(this)} style={{ cursor: 'pointer', marginRight: '10px' }}>{translate('cancel')}</a>
                                                                            <a onClick={this.saveAddressSettings.bind(this)} style={{ cursor: 'pointer' }}>{translate('save')}</a>
                                                                        </div>
                                                                        :
                                                                        <a onClick={this.changeEditModeAddress.bind(this)} style={{ cursor: 'pointer' }}>{translate('edit')}</a>
                                                                    }
                                                                </div>
                                                                <div className="box-content">
                                                                    {this.state.editModeAddress ?
                                                                        <div style={{ display: 'grid' }}>
                                                                            <div style={{ marginTop: '5px' }}>
                                                                                <label for="name">{translate('name')}:</label>
                                                                                <input type="text" id="name" name="name" defaultValue={this.props.userData[0].name} style={{ marginLeft: '10px' }}></input>
                                                                            </div>
                                                                            <div>
                                                                                <label for="surname">{translate('surname')}:</label>
                                                                                <input type="text" id="surname" name="surname" defaultValue={this.props.userData[0].surname} style={{ marginLeft: '10px' }}></input>
                                                                            </div>
                                                                            <div>
                                                                                <label for="companyName">{translate('companyName')}:</label>
                                                                                <input type="text" id="companyName" name="companyName" defaultValue={this.props.userData[0].businessName} style={{ marginLeft: '10px' }}></input>
                                                                            </div>
                                                                            <div>
                                                                                <label for="NIP">NIP:</label>
                                                                                <input type="text" id="NIP" name="NIP" defaultValue={this.props.userData[0].nip} style={{ marginLeft: '10px' }}></input>
                                                                            </div>
                                                                            <div>
                                                                                <label for="phoneNumber">{translate('phoneNumber')}:</label>
                                                                                <input type="text" id="phoneNumber" name="phoneNumber" defaultValue={this.props.userData[0].phone} style={{ marginLeft: '10px' }}></input>
                                                                            </div>
                                                                            <div>
                                                                                <label for="address">{translate('address')}:</label>
                                                                                <input type="text" id="address" name="address" defaultValue={this.props.userData[0].address} style={{ marginLeft: '10px' }}></input>
                                                                            </div>
                                                                            <div>
                                                                                <label for="city">{translate('city')}:</label>
                                                                                <input type="text" id="city" name="city" defaultValue={this.props.userData[0].city} style={{ marginLeft: '10px' }}></input>
                                                                            </div>
                                                                            <div>
                                                                                <label for="postalCode">{translate('postalCode')}:</label>
                                                                                <input type="text" id="postalCode" name="postalCode" defaultValue={this.props.userData[0].postalCode} style={{ marginLeft: '10px' }}></input>
                                                                            </div>
                                                                            <div>
                                                                                <label for="country">{translate('country')}:</label>
                                                                                <input type="text" id="country" name="country" defaultValue={this.props.userData[0].country} style={{ marginLeft: '10px' }}></input>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <div>
                                                                            <h6>{translate('name')}: <b>{this.props.userData[0].name}</b></h6>
                                                                            <h6>{translate('surname')}: <b>{this.props.userData[0].surname}</b></h6>
                                                                            <h6>{translate('companyName')}: <b>{this.props.userData[0].businessName}</b></h6>
                                                                            <h6>NIP: <b>{this.props.userData[0].nip}</b></h6>
                                                                            <h6>{translate('phoneNumber')}: <b>{this.props.userData[0].phone}</b></h6>
                                                                            <h6>{translate('address')}: <b>{this.props.userData[0].address}</b></h6>
                                                                            <h6>{translate('city')}: <b>{this.props.userData[0].city}</b></h6>
                                                                            <h6>{translate('postalCode')}: <b>{this.props.userData[0].postalCode}</b></h6>
                                                                            <h6>{translate('country')}: <b>{this.props.userData[0].country}</b></h6>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        :
                        <section className="cart-section section-b-space">
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div >
                                            <div className="col-sm-12 empty-cart-cls text-center">
                                                <img src={`${process.env.PUBLIC_URL}/assets/images/stop.png`} className="img-fluid mb-4" alt="" />
                                                <h3>
                                                    <strong>{translate('pleaseLogin')}</strong>
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                }


            </div>
        )
    }
}

export default withTranslate(Dashboard)