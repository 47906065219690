import React, { Component } from 'react';
import { connect } from 'react-redux'
import { filterSort } from '../../../actions'
import { getVisibleproducts } from '../../../services';
import { withTranslate } from 'react-redux-multilingual'

class FilterBar extends Component {

    //List Layout View
    listLayout() {
        document.querySelector(".collection-grid-view").style = "opacity:0";
        document.querySelector(".product-wrapper-grid").style = "opacity:0.2";
        document.querySelector(".product-wrapper-grid").classList.add("list-view");
        var elems = document.querySelector(".infinite-scroll-component .row").childNodes;
        [].forEach.call(elems, function (el) {
            el.className = '';
            el.classList.add('col-lg-12');
        });
        setTimeout(function () {
            document.querySelector(".product-wrapper-grid").style = "opacity: 1";
        }, 500);
    }

    //Grid Layout View
    gridLayout() {
        document.querySelector(".collection-grid-view").style = "opacity:1";
        document.querySelector(".product-wrapper-grid").classList.remove("list-view");
        var elems = document.querySelector(".infinite-scroll-component .row").childNodes;
        [].forEach.call(elems, function (el) {
            el.className = '';
            el.classList.add('col-lg-3');
        });
    }

    // Layout Column View
    LayoutView = (colSize) => {
        if (!document.querySelector(".product-wrapper-grid").classList.contains("list-view")) {
            var elems = document.querySelector(".infinite-scroll-component .row").childNodes;
            [].forEach.call(elems, function (el) {
                el.className = '';
                el.classList.add('col-lg-' + colSize);
            });
        }

        this.props.onLayoutViewClicked(colSize);
    }

    render() {
        const { translate } = this.props

        return (
            <div>
                <div className="product-filter-content">
                    <div className="search-count" style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="form-group" style={{ display: 'flex' }}>
                                        <input type="text" id="searchValue" className="form-control" placeholder={translate('placeholderSearch')} onKeyPress={this.props.handleKeyPress} />
                                        <button
                                            onClick={document.getElementById("searchValue") === null ? '' : () => this.props.handleClick(document.getElementById("searchValue").value)}
                                            className="btn btn-primary" style={{ backgroundColor: 'rgb(255, 76, 59)', borderColor: 'rgb(255, 76, 59)' }}><i className="fa fa-search"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="collection-view" style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                        <ul>
                            <li><i
                                className="fa fa-th grid-layout-view" onClick={this.gridLayout}></i>
                            </li>
                            <li><i
                                className="fa fa-list-ul list-layout-view" onClick={this.listLayout}></i>
                            </li>
                        </ul>
                    </div>
                    <div className="collection-grid-view" style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                        <ul>
                            <li>
                                <img
                                    src={`${process.env.PUBLIC_URL}/assets/images/icon/2.png`}
                                    alt=""
                                    className="product-2-layout-view" onClick={() => this.LayoutView(6)} />
                            </li>
                            <li>
                                <img
                                    src={`${process.env.PUBLIC_URL}/assets/images/icon/3.png`}
                                    alt=""
                                    className="product-3-layout-view" onClick={() => this.LayoutView(4)} />
                            </li>
                            <li>
                                <img
                                    src={`${process.env.PUBLIC_URL}/assets/images/icon/4.png`}
                                    alt=""
                                    className="product-4-layout-view" onClick={() => this.LayoutView(3)} />
                            </li>
                            <li>
                                <img
                                    src={`${process.env.PUBLIC_URL}/assets/images/icon/6.png`}
                                    alt=""
                                    className="product-6-layout-view" onClick={() => this.LayoutView(2)} />
                            </li>
                        </ul>
                    </div>
                    <div className="product-page-filter" style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                        <select onChange={(e) => this.props.filterSort(e.target.value)} style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                            <option value="">{translate('sortingItems')}</option>
                            <option value="HighToLow">{translate('highToLow')}</option>
                            <option value="LowToHigh">{translate('lowToHigh')}</option>
                            <option value="Newest">{translate('newest')}</option>
                            <option value="AscOrder">{translate('ascOrder')}</option>
                            <option value="DescOrder">{translate('descOrder')}</option>
                        </select>
                    </div>
                </div>
                <div className="product-filter-content" style={{ borderTopColor: '#dddddd', borderTopStyle: 'solid', borderTopWidth: '1px' }}>
                    <div className="product-page-filter" style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                        <select onChange={(e) => this.props.filterCategory(e.target.value)} style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                            <option value="">{translate('all')}</option>
                            <option value="artificialFlowers" style={{ fontWeight: '600', color: 'rgb(255, 76, 59)' }}>{translate('artificialFlowers')}</option>
                            <option value="artificialFlowers2">{translate('artificialFlowers2')}</option>
                            <option value="singleFlowers">{translate('singleFlowers')}</option>
                            <option value="bouquets">{translate('bouquets')}</option>
                            <option value="leaves">{translate('leaves')}</option>
                            <option value="othersArtificialFlowers">{translate('others')}</option>
                            <option value="christmas" style={{ fontWeight: '600', color: 'rgb(255, 76, 59)' }}>{translate('christmas')}</option>
                            <option value="glassBaubles">{translate('glassBaubles')}</option>
                            <option value="plasticBaubles">{translate('plasticBaubles')}</option>
                            <option value="decoratedBaubles">{translate('decoratedBaubles')}</option>
                            <option value="christmasTreeSpikes">{translate('christmasTreeSpikes')}</option>
                            <option value="othersChristmas">{translate('others')}</option>
                            <option value="easter" style={{ fontWeight: '600', color: 'rgb(255, 76, 59)' }}>{translate('easter')}</option>
                            <option value="chickens">{translate('chickens')}</option>
                            <option value="eggs">{translate('eggs')}</option>
                            <option value="birdsAndButterflies">{translate('birdsAndButterflies')}</option>
                            <option value="othersEaster">{translate('others')}</option>
                            <option value="artificialFoundations" style={{ fontWeight: '600', color: 'rgb(255, 76, 59)' }}>{translate('artificialFoundations')}</option>
                            <option value="differentAssortment" style={{ fontWeight: '600', color: 'rgb(255, 76, 59)' }}>{translate('differentAssortment')}</option>
                        </select>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    products: getVisibleproducts(state.data, state.filters),
    filters: state.filters
})

export default withTranslate(connect(mapStateToProps, { filterSort })(FilterBar));