import React, { Component } from 'react';
import { withTranslate } from 'react-redux-multilingual'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { Link } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";

import Breadcrumb from "../common/breadcrumb";

class Register extends Component {

    constructor(props) {
        super(props);
        this.state = {
            reCaptchaClicked: false
        };
    }

    isValidNip(nip) {
        if (typeof nip !== 'string')
            return false;

        nip = nip.replace(/[\ \-]/gi, '');

        let weight = [6, 5, 7, 2, 3, 4, 5, 6, 7];
        let sum = 0;
        let controlNumber = parseInt(nip.substring(9, 10));
        let weightCount = weight.length;
        for (let i = 0; i < weightCount; i++) {
            sum += (parseInt(nip.substr(i, 1)) * weight[i]);
        }

        return sum % 11 === controlNumber;
    }

    validateEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    registerButtonClicked() {
        if (
            document.getElementById('name').value === '' ||
            document.getElementById('companyName').value === '' ||
            document.getElementById('surname').value === '' ||
            document.getElementById('address').value === '' ||
            document.getElementById('phone').value === '' ||
            document.getElementById('city').value === '' ||
            document.getElementById('email').value === '' ||
            document.getElementById('postalCode').value === '' ||
            document.getElementById('emailRepeat').value === '' ||
            document.getElementById('password').value === '' ||
            document.getElementById('passwordRepeat').value === ''
        ) {
            toast.error(this.props.translate('emptyFieldRegister'));
        }
        else if (!this.validateEmail(document.getElementById('email').value)) {
            toast.error(this.props.translate('wrongEmail'));
        }
        else if (document.getElementById('password').value !== document.getElementById('passwordRepeat').value) {
            toast.error(this.props.translate('passNotMatch'));
        }
        else if (document.getElementById('email').value !== document.getElementById('emailRepeat').value) {
            toast.error(this.props.translate('emailNotMatch'));
        }
        else if (!this.isValidNip(document.getElementById('nip').value) && document.getElementById('nip').value !== '') {
            toast.error(this.props.translate('wrongNip'));
        }
        else {
            (async () => {
                const rawResponse = await fetch('https://api.fhsitko.pl/api/register', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Origin': '',
                        'Host': 'api.fhsitko.pl'
                    },
                    body: JSON.stringify({
                        "email": document.getElementById('email').value,
                        "password": document.getElementById('password').value,
                        "name": document.getElementById('name').value,
                        "surname": document.getElementById('surname').value,
                        "company_name": document.getElementById('companyName').value,
                        "address": document.getElementById('address').value,
                        "phone": document.getElementById('phone').value,
                        "nip": document.getElementById('nip').value,
                        "city": document.getElementById('city').value,
                        "postal_code": document.getElementById('postalCode').value,
                        "country": document.getElementById('country').value,
                        "language": document.getElementById('defaultLanguage').value
                    })
                });
                const content = await rawResponse.json();
                if (content.token !== undefined) {
                    console.log(content);
                    this.props.loggingHandle([document.getElementById('email').value, document.getElementById('password').value], this.props.translate('langCode'));
                    document.getElementById('backLink').click();
                }
                else if (content.error.email[0] === "The email has already been taken.") toast.error(this.props.translate('emailAlreadyTaken'));
            })();
        }
    }

    render() {
        const { translate } = this.props;



        return (
            <div>
                <Breadcrumb title={translate('registering')} />

                {/*Regsiter section*/}
                <section className="register-page section-b-space" style={{paddingTop: '20px'}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <h3>{translate('createAccount')}</h3>
                                <div className="theme-card">
                                    <form className="theme-form">
                                        <div className="form-row">
                                            <div className="col-md-6">
                                                <label htmlFor="nip">NIP</label>
                                                <input type="text" className="form-control" id="nip"
                                                    placeholder={translate('enterNip')} required="" />
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="name">{translate('name')} *</label>
                                                <input type="text" className="form-control" id="name"
                                                    placeholder={translate('enterName')} required="" />
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="col-md-6">
                                                <label htmlFor="companyName">{translate('companyName')} *</label>
                                                <input type="text" className="form-control" id="companyName"
                                                    placeholder={translate('enterCompanyName')} required="" />
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="surname">{translate('surname')} *</label>
                                                <input type="text" className="form-control" id="surname"
                                                    placeholder={translate('enterSurname')} required="" />
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="col-md-6">
                                                <label htmlFor="address">{translate('address')} *</label>
                                                <input type="text" className="form-control" id="address"
                                                    placeholder={translate('enterAddress')} required="" />
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="phone">{translate('phoneNumber')} *</label>
                                                <input type="text" className="form-control" id="phone"
                                                    placeholder={translate('enterPhone')} required="" />
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="col-md-6">
                                                <label htmlFor="city">{translate('city')} *</label>
                                                <input type="text" className="form-control" id="city"
                                                    placeholder={translate('enterCity')} required="" />
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="email">{translate('email')} *</label>
                                                <input type="text" className="form-control" id="email"
                                                    placeholder={translate('enterEmail')} required="" />
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="col-md-6">
                                                <label htmlFor="postalCode">{translate('postalCode')} *</label>
                                                <input type="text" className="form-control" id="postalCode"
                                                    placeholder={translate('enterPostalCode')} required="" />
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="emailRepeat">{translate('emailRepeat')} *</label>
                                                <input type="text" className="form-control" id="emailRepeat"
                                                    placeholder={translate('emailRepeat')} required="" />
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="col-md-6">
                                                <label htmlFor="country">{translate('country')}</label>
                                                <input type="text" className="form-control" id="country"
                                                    placeholder={translate('enterCountry')} required="" />
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="password">{translate('password')} *</label>
                                                <input type="password" className="form-control" id="password"
                                                    placeholder={translate('enterPassword')} required="" />
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="col-md-6">
                                                <label htmlFor="defaultLanguage">{translate('defaultLanguage')} *</label>
                                                <select id="defaultLanguage" name="defaultLanguage" className="form-control" style={window.innerWidth > 767 ? { height: '54px' } : { height: '44px', marginBottom: '20px' }}>
                                                    <option value="pl" >PL</option>
                                                    <option value="en">EN</option>
                                                    <option value="zh">ZH</option>
                                                </select>
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="passwordRepeat">{translate('passwordRepeat')} *</label>
                                                <input type="password" className="form-control" id="passwordRepeat"
                                                    placeholder={translate('passwordRepeat')} required="" />
                                            </div>
                                        </div>
                                        <ReCAPTCHA
                                            sitekey="6LekkqsZAAAAAMCXHAuNRvDZUqmHBI2dKOQ5QoQA"
                                            style={{ marginBottom: '10px' }}
                                            onChange={() => this.setState({ reCaptchaClicked: true }) }
                                        />

                                        {this.state.reCaptchaClicked ?
                                            <a
                                                className={"btn btn-solid"}
                                                onClick={this.registerButtonClicked.bind(this)}>{translate('createAccount')}</a>
                                            :
                                            <button
                                                className={null}
                                                style={{
                                                    padding: '7px 25px',
                                                    cursor: 'auto',
                                                    color: '#ffffff',
                                                    letterSpacing: '0.05em',
                                                    border: '2px solid #000000',
                                                    backgroundImage: '-webkit-linear-gradient(30deg, #000000 100%, transparent 50%)',
                                                    backgroundImage: '-webkit-linear-gradient(60deg, #000000 100%, transparent 50%)',
                                                    backgroundImage: 'linear-gradient(30deg, #000000 100%, transparent 50%)',
                                                    backgroundSize: '540px',
                                                    backgroundRepeat: 'no-repeat',
                                                    backgroundPosition: '0',
                                                    webkitTransition: 'background 300ms ease-in-out',
                                                    transition: 'background 300ms ease-in-out'
                                                }}
                                                disabled={true}>{translate('createAccount')}</button>
                                        }
                                        <Link to={`${process.env.PUBLIC_URL}/`} id={'backLink'}
                                            style={{ visibility: 'hidden' }}
                                        >a</Link>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}

export default withTranslate(Register)