import React, { Component } from 'react';
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { withTranslate } from 'react-redux-multilingual'
import { ToastContainer } from 'react-toastify';


import Breadcrumb from "../common/breadcrumb";
import { getCartTotal } from "../../services";
import { removeFromCart, incrementQty, decrementQty } from '../../actions'

class cartComponent extends Component {

    constructor(props) {
        super(props)
    }

    render() {

        const { cartItems, symbol, total, translate } = this.props;
        return (
            <div>
                {/*SEO Support*/}
                <Helmet>
                    <title>F.H. Sitko</title>
                    <meta name="description" content="F.H. Sitko" />
                </Helmet>
                <ToastContainer closeButton={false} pauseOnHover={false} closeOnClick={false} autoClose={3000}/>
                {/*SEO Support End */}

                <Breadcrumb title={translate('cart')} />

                {this.props.isLogged ?
                    cartItems.length > 0 ?
                        <section className="cart-section section-b-space">
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <table className="table cart-table table-responsive-xs">
                                            <thead>
                                                <tr className="table-head">
                                                    <th scope="col">{translate('image')}</th>
                                                    <th scope="col">{translate('productName')}</th>
                                                    <th scope="col">{translate('unitPrice')}</th>
                                                    <th scope="col">{translate('code')}</th>
                                                    <th scope="col">{translate('quantityCart')}</th>
                                                    <th scope="col">{translate('delete')}</th>
                                                    <th scope="col"></th>
                                                </tr>
                                            </thead>
                                            {cartItems.map((item, index) => {
                                                return (
                                                    <tbody key={index}>
                                                        <tr>
                                                            <td>
                                                                <Link to={`${process.env.PUBLIC_URL}/product/${item.id}`}>
                                                                    <img src={item.pictures[0] !== undefined ? item.pictures[0].filename : '/assets/images/defaultFlower.png'} alt="" />
                                                                </Link>
                                                            </td>
                                                            <td><Link to={`${process.env.PUBLIC_URL}/product/${item.id}`}>
                                                                {
                                                                    translate('langCode') === 'en' ?
                                                                        item.nameEnglish :
                                                                        translate('langCode') === 'pl' ?
                                                                            item.namePolish :
                                                                            translate('langCode') === 'zh' ?
                                                                                item.nameChinese : null
                                                                }
                                                            </Link>
                                                                <div className="mobile-cart-content row">
                                                                    <div className="col-xs-3">
                                                                        <div className="qty-box">
                                                                            <div className="input-group">
                                                                                <input type="text" name="quantity"
                                                                                    className="form-control input-number" defaultValue={item.qty} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-xs-3">
                                                                        <h2 className="td-color">{symbol}{item.price}</h2>
                                                                    </div>
                                                                    <div className="col-xs-3">
                                                                        <h2 className="td-color">
                                                                            <a href="#" className="icon" onClick={() => this.props.removeFromCart(item, translate('langCode'))}>
                                                                                <i className="icon-close"></i>
                                                                            </a>
                                                                        </h2>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td><h2>{symbol}{item.price}</h2></td>
                                                            <td><h2>{item.productCode}</h2></td>
                                                            <td>
                                                                <div className="qty-box">
                                                                    <div className="input-group">
                                                                        <span className="input-group-prepend">
                                                                            <button type="button" className="btn quantity-left-minus" onClick={() => this.props.decrementQty(item.id)} data-type="minus" data-field="">
                                                                                <i className="fa fa-angle-left"></i>
                                                                            </button>
                                                                        </span>
                                                                        <input type="text" name="quantity" value={item.qty} readOnly={true} className="form-control input-number" />

                                                                        <span className="input-group-prepend">
                                                                            <button className="btn quantity-right-plus" onClick={() => this.props.incrementQty(item, 1)} data-type="plus" disabled={false}>
                                                                                <i className="fa fa-angle-right"></i>
                                                                            </button>
                                                                        </span>
                                                                    </div>
                                                                </div>{false ? 'out of Stock' : ''}
                                                            </td>
                                                            <td>
                                                                <a className="icon" style={{ cursor: 'pointer' }} onClick={() => this.props.removeFromCart(item, translate('langCode'))}>
                                                                    <i className="fa fa-times"></i>
                                                                </a>
                                                            </td>
                                                            <td><h2 className="td-color">{symbol}{Math.round(((item.qty * item.price) + Number.EPSILON) * 100) / 100}</h2></td>
                                                        </tr>
                                                    </tbody>)
                                            })}
                                        </table>
                                        <table className="table cart-table table-responsive-md">
                                            <tfoot>
                                                <tr>
                                                    <td>{translate('total')}:</td>
                                                    <td><h2>{symbol} {Math.round((total + Number.EPSILON) * 100) / 100} </h2></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ color: '#e53935', textTransform: 'none' }}>{translate('discountInfo')}</td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                                <div className="row cart-buttons">
                                    <div className="col-6">
                                        <Link to={`${process.env.PUBLIC_URL}/shop`} className="btn btn-solid">{translate('continueShopping')}</Link>
                                    </div>
                                    <div className="col-6">
                                        <Link to={`${process.env.PUBLIC_URL}/checkout`} className="btn btn-solid">{translate('checkout')}</Link>
                                    </div>
                                </div>
                            </div>
                        </section>
                        :
                        <section className="cart-section section-b-space">
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div >
                                            <div className="col-sm-12 empty-cart-cls text-center">
                                                <img src={`${process.env.PUBLIC_URL}/assets/images/icon-empty-cart.png`} className="img-fluid mb-4" alt="" />
                                                <h3>
                                                    <strong>{translate('emptyCart')}</strong>
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    :
                    <section className="cart-section section-b-space">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div >
                                        <div className="col-sm-12 empty-cart-cls text-center">
                                            <img src={`${process.env.PUBLIC_URL}/assets/images/stop.png`} className="img-fluid mb-4" alt="" />
                                            <h3>
                                                <strong>{translate('pleaseLogin')}</strong>
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                }
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    cartItems: state.cartList.cart,
    symbol: state.data.symbol,
    total: getCartTotal(state.cartList.cart)
})

export default withTranslate(connect(
    mapStateToProps,
    { removeFromCart, incrementQty, decrementQty }
)(cartComponent))