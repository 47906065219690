import React, { Component } from 'react';
import Slider from 'react-slick';

import Breadcrumb from "../common/breadcrumb";
import { Slider2, Team4 } from "../../services/script"
import { withTranslate } from 'react-redux-multilingual'

class regulations extends Component {

    constructor(props) {
        super(props)

    }

    render() {
        const { translate } = this.props;

        return (
            <div>
                <Breadcrumb title={translate('basicInformation')} />
                {/*about section*/}
                <section className="about-page  section-b-space" style={{paddingTop: '20px'}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="banner-section">
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/about/about%20us.jpg`} className="img-fluid" alt="" />
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <h4>{translate('basicInformation')}</h4>
                                <p>{translate('basicInformation1')}</p><br></br>
                                <b>
                                    {translate('basicInformation2')}
                                </b>
                                <p>
                                    {translate('basicInformation3')}<br></br>
                                    {translate('basicInformation4')}<br></br>
                                    {translate('basicInformation5')}<br></br>
                                    {translate('basicInformation6')}<br></br><br></br>
                                </p>
                                <b>{translate('basicInformation7')}</b><br></br>
                                <p>{translate('basicInformation8')}<br></br>
                                    {translate('basicInformation9')}<br></br>
                                    {translate('basicInformation10')}<br></br>
                                    {translate('basicInformation11')}<br></br>
                                    {translate('basicInformation12')}<br></br>
                                    {translate('basicInformation13')}<br></br>
                                    {translate('basicInformation14')}<br></br>
                                    {translate('basicInformation15')}<br></br>
                                    {translate('basicInformation16')}<br></br>
                                    {translate('basicInformation17')}<br></br>
                                    {translate('basicInformation18')}<br></br><br></br>
                                </p>
                                <b>{translate('basicInformation19')}</b><br></br>
                                <p>
                                    {translate('basicInformation20')}<br></br>
                                    {translate('basicInformation21')}<br></br>
                                    {translate('basicInformation22')}<br></br>
                                    {translate('basicInformation23')}<br></br>
                                    {translate('basicInformation24')}<br></br>
                                    {translate('basicInformation25')}<br></br><br></br>
                                </p>
                                <b>{translate('basicInformation26')}</b><br></br>
                                <p>
                                    {translate('basicInformation27')}<br></br>
                                    {translate('basicInformation28')}<br></br>
                                    {translate('basicInformation29')}<br></br><br></br>
                                </p>
                                <b>{translate('basicInformation30')}</b><br></br>
                                <p>
                                    {translate('basicInformation31')}<br></br>
                                    {translate('basicInformation32')}<br></br>
                                    {translate('basicInformation33')}<br></br>
                                    {translate('basicInformation34')}<br></br><br></br>
                                </p>
                                <b>{translate('basicInformation35')}</b><br></br>
                                <p>
                                    {translate('basicInformation36')}<br></br>
                                    {translate('basicInformation37')}<br></br>
                                    {translate('basicInformation38')}<br></br>
                                    {translate('basicInformation39')}<br></br>
                                    {translate('basicInformation40')}<br></br><br></br>
                                </p>
                                <b>{translate('basicInformation41')}</b><br></br>
                                <p>
                                    {translate('basicInformation42')}<br></br>
                                    {translate('basicInformation43')}<br></br>
                                    {translate('basicInformation44')}<br></br>
                                    {translate('basicInformation45')}<br></br>
                                    {translate('basicInformation46')}<br></br>
                                    {translate('basicInformation47')}<br></br>
                                    {translate('basicInformation48')}<br></br>
                                    {translate('basicInformation49')}<br></br>
                                    {translate('basicInformation50')}<br></br>
                                    {translate('basicInformation51')}<br></br>
                                    {translate('basicInformation52')}<br></br>
                                    {translate('basicInformation53')}<br></br>
                                    {translate('basicInformation54')}<br></br>
                                    {translate('basicInformation55')}<br></br>
                                    {translate('basicInformation56')}<br></br>
                                    {translate('basicInformation57')}<br></br>
                                    {translate('basicInformation58')}<br></br>
                                    {translate('basicInformation59')}<br></br>
                                    {translate('basicInformation60')}<br></br>
                                    {translate('basicInformation61')}<br></br>
                                    {translate('basicInformation62')}<br></br>
                                    {translate('basicInformation63')}<br></br>
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}

export default withTranslate(regulations);