import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { withTranslate } from 'react-redux-multilingual'

class Breadcrumb extends Component {
    render() {
        const { title, parent, translate } = this.props;
        return (
            <div className="breadcrumb-section" style={{padding: '0px'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="page-title">
                                <nav aria-label="breadcrumb" className="theme-breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to={`${process.env.PUBLIC_URL}`}>{translate('home')}</Link></li>
                                        {parent ?
                                            <li className="breadcrumb-item" aria-current="page">{parent}</li> : ''}
                                        <li className="breadcrumb-item active" aria-current="page">{title}</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                        <div className="col-md-6">

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslate(Breadcrumb);