import React, { Component } from 'react';
import Slider from 'react-slick';
import { withTranslate } from 'react-redux-multilingual'
import { Link } from 'react-router-dom';

import Breadcrumb from "../common/breadcrumb";

class PageNotFound extends Component {

    constructor(props) {
        super(props)

    }

    render() {
        const { translate } = this.props;

        return (
            <div>
                {document.location.href !== 'https://fhsitko.pl' &&
                    document.location.href !== 'https://fhsitko.pl/shop' &&
                    document.location.href !== 'https://fhsitko.pl/about-us' &&
                    document.location.href !== 'https://fhsitko.pl/contact' &&
                    !document.location.href.includes('/product/') &&
                    document.location.href !== 'https://fhsitko.pl/cart' &&
                    document.location.href !== 'https://fhsitko.pl/checkout' &&
                    document.location.href !== 'https://fhsitko.pl/login' &&
                    document.location.href !== 'https://fhsitko.pl/register' &&
                    document.location.href !== 'https://fhsitko.pl/regulations' &&
                    document.location.href !== 'https://fhsitko.pl/policy-privacy' &&
                    document.location.href !== 'https://fhsitko.pl/prices' &&
                    document.location.href !== 'https://fhsitko.pl/cookies' &&
                    document.location.href !== 'https://fhsitko.pl/dashboard' &&
                    !document.location.href.includes('/pages/resetsuccess') &&
                    document.location.href !== 'https://fhsitko.pl/faq'
                    ?
                    <div>
                        <Breadcrumb title={'404'} />

                        <section className="p-0">
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="error-section">
                                            <h1>404</h1>
                                            <h2>{translate('pageNotFound')}</h2>
                                            <Link to={`${process.env.PUBLIC_URL}`} className="btn btn-solid" style={{ backgroundImage: 'linear-gradient(30deg, #ff4c3b 100%, transparent 50%)' }}>{translate('backToHome')}</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    :
                    null
                }
            </div>
        )
    }
}

export default withTranslate(PageNotFound)