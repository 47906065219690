import React, { Component } from 'react';
import Slider from 'react-slick';

import Breadcrumb from "../common/breadcrumb";
import { Slider2, Team4 } from "../../services/script"
import { withTranslate } from 'react-redux-multilingual'
import { Link } from 'react-router-dom';

class cookies extends Component {

    constructor(props) {
        super(props)

    }

    render() {
        const { translate } = this.props;

        return (
            <div>
                <Breadcrumb title={translate('cookiesPolicy')} />
                {/*about section*/}
                <section className="about-page  section-b-space" style={{paddingTop: '20px'}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <h4>{translate('cookiesPolicy')}</h4>
                                <b>{translate('cookieContent1')}</b><br></br>
                                <p>{translate('cookieContent2')}</p><br></br>
                                <p>{translate('cookieContent3')}</p><br></br>
                                <p>{translate('cookieContent4')}</p><br></br><br></br>
                                <b>{translate('cookieContent5')}</b><br></br>
                                <p>{translate('cookieContent6')}</p><br></br><br></br>
                                <b>{translate('cookieContent7')}</b><br></br>
                                <p>{translate('cookieContent8')}</p><br></br>
                                <u>{translate('cookieContent9')}</u><br></br>
                                <p>{translate('cookieContent10')}</p><br></br>
                                <u>{translate('cookieContent11')}</u><br></br>
                                <p>{translate('cookieContent12')}</p><br></br>
                                <u>{translate('cookieContent13')}</u><br></br>
                                <p>{translate('cookieContent14')}</p><br></br>
                                <u>{translate('cookieContent15')}</u><br></br>
                                <p>{translate('cookieContent16')}</p><br></br>
                                <u>{translate('cookieContent17')}</u><br></br>
                                <p>{translate('cookieContent18')}</p><br></br>
                                <u>{translate('cookieContent19')}</u><br></br>
                                <p>{translate('cookieContent20')}</p><br></br>
                                <u>{translate('cookieContent21')}</u><br></br>
                                <p>{translate('cookieContent22')}</p><br></br><br></br>
                                <b>{translate('cookieContent23')}</b><br></br>
                                <p>{translate('cookieContent24')}</p><br></br>
                                <p>{translate('cookieContent25')}</p><br></br>
                                <a href={'https://support.apple.com/kb/ph19214?locale=en_US'} target="_blank">Apple Safari</a><br></br><br></br>
                                <a href={'https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=en'} target="_blank">Google Chrome</a><br></br><br></br>
                                <a href={'https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy'} target="_blank">Microsoft Edge</a><br></br><br></br>
                                <a href={'https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies'} target="_blank">Microsoft Internet Explorer</a><br></br><br></br>
                                <a href={'https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences'} target="_blank">Mozilla Firefox</a><br></br><br></br>
                                <a href={'http://www.opera.com/help/tutorials/security/cookies/'} target="_blank">Opera</a><br></br><br></br>
                                <a href={'https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DAndroid&hl=en&oco=1'} target="_blank">Android (Chrome)</a><br></br><br></br>
                                <a href={'https://help.blackberry.com/en/blackberry-classic/10.3.1/help/mwa1334238823957.html'} target="_blank">Blackberry</a><br></br><br></br>
                                <a href={'https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DiOS&hl=en&oco=1'} target="_blank">Iphone or Ipad (Chrome)</a><br></br><br></br>
                                <a href={'https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DAndroid&hl=en&oco=1'} target="_blank">Iphone or Ipad (Safari)</a><br></br><br></br>
                                <p>{translate('cookieContent26')}<a href={'http://optout.networkadvertising.org/#!/'} target="_blank">{translate('cookieContent27')}</a></p><br></br><br></br>
                                <b>{translate('cookieContent28')}</b><br></br>
                                <p>{translate('cookieContent29')}</p><br></br><br></br>
                                <b>{translate('cookieContent30')}</b><br></br>
                                <p>{translate('cookieContent31')}<Link to={`${process.env.PUBLIC_URL}/policy-privacy`}>{translate('cookieContent32')}</Link>
                                    {translate('cookieContent33')}
                                </p><br></br><br></br>
                                <b>{translate('cookieContent34')}</b><br></br>
                                <p>{translate('cookieContent35')}</p><br></br>
                                <p>F.H. Sitko<br></br>
                                Centrum Giełdowe Balicka, ul. Balicka 56/20B<br></br>
                                30-149 Kraków<br></br>
                                +48 603 104 321<br></br>
                                greg-sit@o2.pl<br></br>
                                </p>
                                <br></br>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}

export default withTranslate(cookies);