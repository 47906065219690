var module = {
    pl: {
        locale: 'pl',
        messages: {
            langCode: 'pl',
            language: 'Język',
            polish: 'polski',
            english: 'angielski',
            chinese: 'chiński',
            copyright: "Witryna stworzona przez ",
            topbar_title: 'Witamy w naszym sklepie',
            call_us: 'Zadzwoń',
            home: 'Strona główna',
            login: 'Zaloguj',
            register: 'Zarejestruj',
            about_us: 'O nas',
            compare: 'Porównaj',
            FAQ: 'FAQ',
            shop: 'Sklep',
            contact: 'Kontakt',
            aboutHeader: 'O nas',
            aboutContent1: 'Nasza firma powstała w 2006 roku jako rozszerzenie firmy rodzinnej zajmującej się handlem kwiatami sztucznymi oraz ozdobami świątecznymi (Boże Narodzenie, Wielkanoc). Przygodę z importem zaczęliśmy w 2005 roku mając na uwadze potrzebę wprowadzania i rozszerzania naszej oferty produktowej.',
            aboutContent2: 'Oferowane przez nas produkty skierowane są głownie do odbiorców hurtowych branży florystycznej oraz handlowej w Polsce jak i za granicą i obejmują:',
            aboutContent3: '• Kwiaty sztuczne (gałązki, główki, bukiety, liście),',
            aboutContent4: '• Ozdoby Bożonarodzeniowe (bombki szklane, plastikowe oraz inne dodatki),',
            aboutContent5: '• Ozdoby Wielkanocne (kurczaki, jajka, zajączki oraz inne dodatki),',
            aboutContent6: '• Podkłady pod sztuczne wieńce,',
            aboutContent7: '• Kompozycje kwiatowe.',
            aboutContent8: 'Dzięki wieloletniemu doświadczeniu i wypracowanym kontaktom stale powiększamy naszą ofertę produktową starając się aby nasi klienci byli za każdym razem zadowoleni ze współpracy. Dla nas najważniejsze jest utrzymywanie jak najlepszych relacji z klientami oraz z producentami ponieważ według  nas zawsze każda ze stron powinna być zadowolona ze współpracy.',
            newProducts: 'Nowe produkty',
            new: 'nowy',
            addToCart: 'Dodaj do koszyka',
            loginToBuy: 'Zaloguj się aby kupić',
            quickView: 'Szybki podgląd',
            productUnit: 'Jednostka pakowania',
            quantity: 'Ilość (w szt.)',
            viewDetail: 'Szczegóły',
            cartonUnit: 'Karton',
            packUnit: 'Pudełko',
            relatedProducts: 'Powiązane produkty',
            product: "Produkt / ",
            products: 'Produkty',
            sortingItems: 'Sortowanie produktów',
            highToLow: 'Cena: malejąco',
            lowToHigh: 'Cena: rosnąco',
            newest: 'Najnowsze',
            ascOrder: 'Sortuj według nazwy: od A do Z',
            descOrder: 'Sortuj według nazwy: od Z do A',
            noProduct: 'Brak kolejnych produktów do załadowania',
            noResults: 'Nie można znaleźć produktu, którego szukasz!',
            tryAgain: 'Sprawdź, czy coś źle napisałeś lub spróbuj wyszukać za pomocą innych słów.',
            continueShopping: 'Kontynuuj zakupy',
            pcs: ' szt.',
            code: 'Kod',
            productDetails: 'Opis produktu',
            quantityCart: 'Ilość (szt.)',
            delete: 'Usuń',
            unitPrice: 'Cena jednostkowa',
            image: 'Zdjęcie',
            productName: 'Nazwa produktu',
            total: 'Razem',
            checkout: 'Podsumowanie',
            cart: 'Koszyk',
            emptyCart: 'Twój koszyk jest pusty',
            pleaseLogin: 'Dostęp do zawartości po zalogowaniu',
            logging: 'Logowanie',
            registering: 'Rejestracja',
            email: 'Email',
            password: 'Hasło',
            emailEnter: 'Wprowadź email',
            passwordEnter: 'Wprowadź hasło',
            newCustomer: 'Nowy klient',
            createAccount: 'Utwórz konto',
            registerTip: 'Załóż darmowe konto w naszym sklepie. Rejestracja jest szybka i łatwa. Pozwala na składanie zamówień.',
            placeholderSearch: 'Wprowadź nazwę lub kod produktu',
            viewCart: 'Koszyk',
            information: 'Informacje',
            basicInformation: 'Regulamin i podstawowe informacje',
            basicInformation1: 'Hurtownia internetowa działająca pod adresem www.fhsitko.pl prowadzona jest przez FIRMA HANDLOWA GRZEGORZ SITKO, 35-511 Rzeszów ul. Prymasa Tysiąclecia 9/2, NIP:9451871977, REGON: 180157064',
            basicInformation2: 'REJESTRACJA W SYSTEMIE SPRZEDAŻY',
            basicInformation3: '1. Rejestracja odbywa się na podstawie danych Nip z CEiDG (wymagana działalność gospodarcza).',
            basicInformation4: '2. Klient zobowiązany jest w procesie rejestracji do podania pełnych danych zgodnych z prawdą. FHSITKO nie ponosi odpowiedzialności za szkody powstałe w przypadku podania nieprawdziwych danych.',
            basicInformation5: '3. Wszelkie zmiany danych (dane osobowe, osoba kontaktowa itp.) przypisanych do konta klienta wymagają wyrażenia zgody FHSITKO.',
            basicInformation6: '4. Ceny produktów widoczne są jedynie dla zarejestrowanych i zweryfikowanych klientów.',
            basicInformation7: 'CENY I ZAMÓWIENIA',
            basicInformation8: '1. Wszystkie ceny widoczne na stronie  www.fhsitko.pl podane są w złotych polskich (PLN) i są cenami brutto (wraz z podatkiem VAT).',
            basicInformation9: '2. Serwis umożliwia dostęp do informacji o towarach i usługach będących aktualnie w sprzedaży.',
            basicInformation10: '3. Składanie zamówień na towary dostępne w ramach hurtowni i zawieranie umów sprzedaży towarów na odległość.',
            basicInformation11: '4. Podane ceny są cenami hurtowymi (nie prowadzimy sprzedaży detalicznej).',
            basicInformation12: '5. Informacje dotyczące produktów (materiały informacyjne, reklamowe)  wysyłane za pośrednictwem poczty elektronicznej oraz  informacje  zawarte na stronie www.fhsitko.pl kierowane do odbiorców i potencjalnych klientów nie  stanowią oferty w rozumieniu przepisów prawa.',
            basicInformation13: '6. Zamówienia przyjmowane są poprzez stronę www.fhsitko.pl',
            basicInformation14: '7. Złożenie zamówienia nie jest równoznaczne z jego przyjęciem.',
            basicInformation15: '8. Wszelkie zamówienia są sprawdzane i realizowane zależnie od dostępności towaru w magazynie.',
            basicInformation16: '9. Informacje dotyczące dostępności oraz ostatecznej ceny zamówionych produktów są przekazywane drogą elektroniczną po złożeniu zamówienia. W przypadku stwierdzenia braku produktów/wad itp.  zamawiający zostaje o tym fakcie bezzwłocznie poinformowany.',
            basicInformation17: '10. Wszelkie należności powinny zostać wpłacone przez zamawiającego  na konto FHSITKO dopiero po otrzymaniu faktury pro-forma, która stanowi potwierdzenie przyjęcia zamówienia do realizacji.',
            basicInformation18: '11. Zamówienie może zostać anulowane/zmienione  przez zamawiającego  w czasie procesu realizacji, w przypadku jeśli nie doszło do jego fizycznej realizacji.',
            basicInformation19: 'DOSTAWA',
            basicInformation20: '1. Towar zamówiony można odebrać osobiście w miejscu sprzedaży, może zostać wysłany za pośrednictwem firmy kurierskiej lub w inny uzgodniony wcześniej sposób.',
            basicInformation21: '2. Koszty transportu ponosi zamawiający.',
            basicInformation22: '3. W przypadku wysyłki towaru za pośrednictwem firmy kurierskiej zamawiający zostanie poinformowany o jej koszcie którego akceptacja będzie wiązała się z realizacją zamówienia (zostanie doliczony do faktury).',
            basicInformation23: '4. Wysyłka/dostawa następuje w terminie do pięciu dni roboczych od dnia otrzymania wpłaty na konto FHSITKO.',
            basicInformation24: '5. Wraz z towarem wydawana jest faktura VAT stanowiąca potwierdzenie zakupu. W przypadku stwierdzenia jej braku prosimy o zgłoszenie tego faktu.',
            basicInformation25: '6. W momencie otrzymania przesyłki/odbioru zamawiający powinien sprawdzić jej stan oraz zawartość. W przypadku stwierdzenia braków/uszkodzeń powinien dokonać wszelkich czynności niezbędnych do ustalenia odpowiedzialności firmy kurierskiej.',
            basicInformation26: 'METODY PŁATNOŚCI',
            basicInformation27: '1. Płatność gotówką w momencie odbioru towaru w punkcie sprzedaży.',
            basicInformation28: '2. Przed wydaniem towaru zamawiającemu (wysłaniem kurierem), po zaksięgowaniu wpłaty na rachunku bankowym FHSIKTO.',
            basicInformation29: '3. W inny ustalony sposób.',
            basicInformation30: 'ZWROT TOWARU I REKLAMACJE',
            basicInformation31: '1. Towary zakupione przez Zamawiającego przeznaczone są do dalszej odsprzedaży, dlatego strony zgodnie z art. art. 558 §1 Kodeksu cywilnego wyłączają odpowiedzialność sprzedającego z tytułu rękojmi za wady ukryte z wyjątkiem towarów na które została udzielona odrębna rękojmia.',
            basicInformation32: '2. Rękojmia za wady fizyczne zakupionych towarów obejmuje tylko i wyłącznie uprawnienie zamawiającego do dostarczenia produktów wolnych od wad. Jeśli stwierdzony zostanie brak produktu w magazynie, wówczas zostanie dokonana korekta faktury oraz zostanie zwrócona różnica należności. Pozostałe uprawnienia wynikające z przepisów o rękojmi za wady fizyczne ulegają wyłączeniu, stosownie do treści art. 558 §1 Kodeksu Cywilnego.',
            basicInformation33: '3. W przypadku gdy towar zawiera wady lub jest niezgodny z umową, koszty zwrotu przesyłki pokrywa firma FHSITKO.',
            basicInformation34: '4. Towar powinien zostać zwrócony w oryginalnym (niezniszczonym) opakowaniu umożliwiającym jego bezpieczny transport.',
            basicInformation35: 'POSTANOWIENIA KOŃCOWE',
            basicInformation36: '1. Podmiot prowadzący serwis www.fhsitko.pl nie ponosi odpowiedzialności za przerwy w korzystaniu z hurtowni internetowej zaistniałych  z przyczyn technicznych (konserwacja, przegląd, wymiana sprzętu, etc) lub innych od niego niezależnych.',
            basicInformation37: '2. FHSITKO zastrzega sobie prawo do dowolnych zmiana cen oraz ilości produktów w ofercie hurtowni.',
            basicInformation38: '3. Umowy sprzedaży dokumentowane są za pomocą faktury VAT zgodnie z prawem polskim oraz w języku polskim.',
            basicInformation39: '4. Wszelkie zdjęcia produktów znajdujące się na stronie www.fhsitko.pl są własnością FHSITKO. Wszelkie ich kopiowanie, rozpowszechnianie i wykorzystywanie w całości/części bez zgody jest zabronione. Przedstawiona kolorystyka w zależności od ustawień ekranu może nieco odbiegać od rzeczywistości.',
            basicInformation40: '5. W sprawach nieuregulowanych w niniejszym Regulaminie zastosowanie mają odpowiednie przepisy Kodeksu Cywilnego.',
            basicInformation41: 'CENY I RABATY',
            basicInformation42: '1. Podane ceny są cenami hurtowymi (nie prowadzimy sprzedaży detalicznej)',
            basicInformation43: '2. Podczas składania zamówienia w hurtowni internetowej rabat naliczany jest automatycznie.',
            basicInformation44: '3. W przypadku produktów promocyjnych, wyprzedażowych podana cena jest ceną sprzedaży (do zapłaty) i nie podlega obniżeniu o przysługujące rabaty.',
            basicInformation45: '4. Podane ceny zawierają podatek VAT (brutto) i podane są za 1 sztukę.',
            basicInformation46: '5. Podane ceny nie zawierają kosztu dostawy.',
            basicInformation47: '6. Koszt dostawy produktów będzie podany przy każdym produkcie. W przypadku kilku różnych produktów koszt dostawy będzie kalkulowany indywidualnie ponieważ spakowanie kilku produktów w jeden karton może być znacznie tańsze.',
            basicInformation48: '7. Najmniejsza możliwa ilość do kupienia to jest opakowanie (w zależności od produktu 12, 24, 36 sztuk).',
            basicInformation49: '8. Minimalna wartość zakupów to 500zł (brutto)',
            basicInformation50: '9. Rabat jednorazowy:',
            basicInformation51: 'a. 3% przy zamówieniach o wartości przekraczającej 2000 (brutto PLN).',
            basicInformation52: 'b. 5% przy zamówieniach o wartości przekraczającej 3500 (brutto PLN).',
            basicInformation53: 'c. 7% przy zamówieniach o wartości przekraczającej 6000 (brutto PLN).',
            basicInformation54: 'd. 10% przy zamówieniach o wartości przekraczającej 8000 (brutto PLN).',
            basicInformation55: 'e. 15% przy zamówieniach o wartości przekraczającej 12500 (brutto PLN).',
            basicInformation56: '10. Stały rabat (uwarunkowany rocznym obrotem – wymagana stosowna umowa, historia zakupów):',
            basicInformation57: 'a. 3% przy zamówieniach o wartości przekraczającej 15000 (brutto PLN).',
            basicInformation58: 'b. 5% przy zamówieniach o wartości przekraczającej 20000 (brutto PLN).',
            basicInformation59: 'c. 7% przy zamówieniach o wartości przekraczającej 25000 (brutto PLN).',
            basicInformation60: 'd. 10% przy zamówieniach o wartości przekraczającej 30000 (brutto PLN).',
            basicInformation61: 'e. 15% przy zamówieniach o wartości przekraczającej 40000 (brutto PLN).',
            basicInformation62: '11. W przypadku odbioru osobistego zamówień o wartości przekraczającej 6000 klient otrzymuje dodatkowo 2% rabatu.',
            basicInformation63: '12. Rabat ustalany indywidualnie.',
            policyPrivacy: 'Polityka prywatności',
            sale: 'Rabaty',
            delivery: 'Koszty dostawy',
            prices: 'Ceny i rabaty',
            faq: 'FAQ',
            cookiesPolicy: 'Regulamin Cookies',
            address: 'Adres',
            contactContent1: 'Nasza siedziba znajduje się w Rzeszowie natomiast dział sprzedaży umiejscowiony jest w Krakowie:',
            contactContent2: 'Sezonowo (listopad-grudzień, marzec-kwiecień) działamy również w lokalizacji:',
            storeInformation: 'Informacje o sklepie',
            policyPivacyContent1: 'Do ochrony Twoich danych osobowych podchodzimy poważnie. W przypadku jakichkolwiek wątpliwości, pytań możesz się zawsze zwrócić do nas w celu otrzymania wyjaśnień. Chcemy aby nasza oferta była dopasowana do Twoich potrzeb i w tym celu  zamieszczamy poniżej wszelkie informacje które uznajemy za istotne: ',
            policyPivacyContent2: '1. Administratorem Twoich danych osobowych jest: FIRMA HANDLOWA GRZEGORZ SITKO, 35-511 Rzeszów ul. Prymasa Tysiąclecia 9/2, NIP:9451871977, REGON: 180157064 (zwana dalej Administratorem).',
            policyPivacyContent3: '2. W trosce o bezpieczeństwo powierzonych nam danych opracowaliśmy wewnętrzne procedury i zalecenia, które mają zapobiec udostępnieniu danych osobom nieupoważnionym. Kontrolujemy ich wykonywanie i stale sprawdzamy ich zgodność z odpowiednimi aktami prawnymi - ustawą o ochronie danych osobowych, ustawą o świadczeniu usług drogą elektroniczną, a także wszelkiego rodzaju aktach wykonawczych i aktach prawa wspólnotowego.',
            policyPivacyContent4: '3. Dane Osobowe przetwarzane są na podstawie zgody wyrażanej przez Użytkownika oraz w przypadkach, w których przepisy prawa upoważniają Administratora do przetwarzania danych osobowych na podstawie przepisów prawa lub w celu realizacji zawartej pomiędzy stronami umowy.',
            policyPivacyContent5: '4. Dane osobowe pozostawione w serwisie nie zostaną sprzedane ani udostępnione osobom trzecim, zgodnie z przepisami Ustawy o ochronie danych osobowych.',
            policyPivacyContent6: '5. Serwis realizuje funkcje pozyskiwania informacji o użytkownikach i ich zachowaniach w następujący sposób:',
            policyPivacyContent61: 'a. poprzez dobrowolnie wprowadzone w formularzach informacje',
            policyPivacyContent62: 'b. poprzez gromadzenie plików “cookies” ',
            policyPivacyContent7: '6. Serwis zbiera informacje dobrowolnie podane przez użytkownika.',
            policyPivacyContent8: '7. W każdej chwili przysługuje Ci prawo do zażądania wglądu do Twoich danych osobowych (sprostowania, usunięcia, ograniczenia ich przetwarzania oraz przeniesienia danych).',
            policyPivacyContent9: '8. W każdej chwili przysługuje Ci prawo do cofnięcia zgody na przetwarzanie Twoich danych osobowych.',
            policyPivacyContent10: '9. Podane dane osobowe, adres mailowy oraz numer telefonu będą przetwarzane przez nas w celach marketingowych. Z naszymi klientami chcemy mieć jak najlepsze kontakty aby informować ich o nowościach, promocjach oraz statusie zamówień.',
            policyPivacyContent11: '10.	Administratorem danych osobowych jest Grzegorz Sitko (tel:603104321)',
            policyPivacyContent12: '11.	Twoje dane będziemy przechowywać tylko w przypadku będzie wysyłania informacji o nowych produktach . Jeżeli nie będziesz już nim zainteresowany możesz w każdej chwili wycofać złożoną zgodę.',
            policyPivacyContent13: '12.	Twój adres mailowy, numer telefonu będzie służył nam do komunikacji z Tobą. W ten sposób będziesz informowany o naszych produktach, promocjach, statusie zamówienia',
            policyPivacyContent14: '13.	Jeśli uważasz że Twoje dane osobowe zostały naruszone to w każdej chwili masz prawo złożyć skargę do Prezesa Urzędu Ochrony Danych Osobowych.',
            policyPivacyContent15: '14. Jako Administrator serwisu korzystamy z różnych zewnętrznych usługodawców aby lepiej świadczyć własne usługi. Podane przez Ciebie dane osobowe mogą być  przez nich wykorzystywane. Firmy te świadczą dla nas usługi kurierskie oraz obsługę serwisu on-line. Posiadamy z nimi umowę powierzenia danych w której jest  zapewnione, że Twoje dane są bezpieczne. Jako administrator serwisu jesteśmy odpowiedzialni także za podmioty, które przetwarzają za nas Twoje dane osobowe.',
            policyPivacyContent16: '15. Zaznaczyłeś, że wyrażasz zgodę na przetwarzanie Twoich danych osobowych przez Administratora w celach marketingowych oraz komunikację z Tobą w celu informowania o promocjach i statusach Twoich zamówień.',
            policyPivacyContent17: '16. Zastrzegamy sobie prawo do zmiany w polityce ochrony prywatności serwisu, na które może wpłynąć rozwój technologii internetowej, ewentualne zmiany prawa w zakresie ochrony danych osobowych oraz rozwój naszego serwisu internetowego. O wszelkich zmianach będziemy informować w sposób widoczny i zrozumiały.',
            yourCartEmpty: 'Twój koszyk jest obecnie pusty.',
            dashboard: 'Panel',
            logout: 'Wyloguj',
            accountInfo: 'Informacje o koncie',
            my_account: 'Moje konto',
            loginInfo: 'Informacje logowania',
            defaultLanguage: 'Domyślny język',
            changePassword: 'Zmień hasło',
            name: 'Imię',
            surname: 'Nazwisko',
            companyName: 'Nazwa organizacji',
            phoneNumber: 'Numer telefonu',
            city: 'Miasto',
            postalCode: 'Kod pocztowy',
            country: 'Państwo',
            contactInformation: 'Informacje kontaktowe',
            edit: 'Edytuj',
            passwordRepeat: 'Powtórz hasło',
            emailRepeat: 'Powtórz email',
            alreadyLogin: 'Jesteś już zalogowany',
            save: 'Zapisz',
            cancel: 'Anuluj',
            oldPassword: 'Stare hasło',
            newPassword: 'Nowe hasło',
            repeatNewPassword: 'Powtórz nowe hasło',
            successSave: 'Poprawnie zapisano nowe dane konta!',
            enterNip: 'Wprowadź NIP',
            enterName: 'Wprowadź imię',
            enterSurname: 'Wprowadź nazwisko',
            enterCompanyName: 'Wprowadź nazwę organizacji',
            enterAddress: 'Wprowadź adres',
            enterPhone: 'Wprowadź numer telefonu',
            enterCity: 'Wprowadź miasto',
            enterCountry: 'Wprowadź państwo',
            enterEmail: 'Wprowadź email',
            enterPostalCode: 'Wprowadź kod pocztowy',
            enterPassword: 'Wprowadź hasło',
            emptyFieldRegister: 'Należy uzupełnić wszystkie pola oznaczone gwiazdką',
            wrongEmail: 'Nieprawidłowy format email',
            passNotMatch: 'Hasła nie pasują do siebie',
            emailNotMatch: 'Emaile nie pasują do siebie',
            wrongNip: 'Nieprawidłowy NIP',
            faq1: 'Czas realizacji zamówienia?',
            faq2: 'Zgodnie z regulaminem czas realizacji zamówienia wynosi pięć dni roboczych od otrzymania zapłaty. W zależności od ilości zamówień termin ten może być zarówno krótszy jak i dłuższy. W okresie przedświątecznym wzmożona ilość zamówień może mieć wpływ na wydłużenie realizacji zamówienia. ',
            faq3: 'Miejsce pochodzenia produktów?',
            faq4: 'Większość oferowanych przez nas produktów pochodzi z Azji i jest importowana przez naszą firmę. Część produktów jest pochodzenia polskiego (polscy producenci też mają ciekawe produkty).',
            faq5: 'Czy towar posiada kody kreskowe?',
            faq6: 'W większości przypadków kody kreskowe są umieszczane na produktach na których jest to możliwe. Chodzi o opakowania foliowe, kartoniki jak i w niektórych przypadkach pojedyncze sztuki. ',
            faq7: 'Czy można zamówić towar z wyprzedzeniem?',
            faq8: 'Istnieje taka możliwość. Jeśli wiemy że towar będzie wkrótce to możemy go odłożyć, zarezerwować. W przypadku wyrażenia chęci zamówienia konkretnego produktu istnieje możliwość zamówienia na specjalne życzenie – jeśli będzie określona ilość.',
            faq9: 'Skąd można odebrać zamówiony towar?',
            faq10: 'Preferowany odbiór na Giełdzie Kwiatowej w Krakowie. ',
            faq11: 'Centrum Giełdowe Balicka  30-149 Kraków ul. Balicka 56/20B',
            faq12: 'Pn-pt od 3:00-10:00, sobota od 3:00-8:00',
            emailAlreadyTaken: 'Adres e-mail jest już zajęty.',
            noChanges: 'Nie dokonano żadnych zmian.',
            changesSuccess: 'Pomyślnie dokonano zmian.',
            voidName: 'Imię nie może być puste.',
            voidSurname: 'Nazwisko nie może być puste.',
            voidCompanyName: 'Nazwa organizacji nie może być pusta.',
            voidAddress: 'Adres nie może być pusty.',
            voidPhone: 'Numer telefonu nie może być pusty.',
            voidCity: 'Miasto nie może być puste.',
            voidPostalCode: 'Kod pocztowy nie może być pusty.',
            emptyOldPassword: 'Stare hasło jest puste.',
            emptyNewPassword: 'Nowe hasło jest puste.',
            wrongOldPass: 'Niepoprawne stare hasło.',
            forgetPassword: 'Zapomniałem hasła',
            provideEmail: 'Na podany email prześlemy link do zmiany hasła.',
            send: 'Wyślij',
            sendConfirm: 'Jeśli użytkownik istnieje - link do zmiany hasła został wysłany.',
            addProduct: 'Dodaj produkt',
            productCode: "Kod produktu *",
            nameEnglish: "Angielska nazwa *",
            namePolish: "Polska nazwa *",
            nameChinese: "Chińska nazwa *",
            price: 'Cena jednostkowa *',
            family: "Rodzina *",
            lessCapacity: 'Pojemność mniejszego opakowania *',
            moreCapacity: 'Pojemność większego opakowania *',
            lessUnitEnglish: "Angielska nazwa mniejszego opakowania *",
            moreUnitEnglish: "Angielska nazwa większego opakowania *",
            lessUnitPolish: "Polska nazwa mniejszego opakowania *",
            moreUnitPolish: "Polska nazwa większego opakowania *",
            lessUnitChinese: "Chińska nazwa mniejszego opakowania *",
            moreUnitChinese: "Chińska nazwa większego opakowania *",
            descriptionEnglish: "Angielski opis *",
            descriptionPolish: "Polski opis *",
            descriptionChinese: "Chiński opis *",
            uploadImages: 'Dodaj zdjęcia',
            removeAllImages: 'Usuń wszystkie zdjęcia',
            update: 'Zaktualizuj',
            remove: 'Usuń',
            pictures: 'Zdjęcia *',
            maxPictureSize: 'Maksymalny rozmiar pojedyńczego zdjęcia: 5MB',
            recommendedPictureDimensions: 'Maksymalne i rekomendowane wymiary pojedyńczego zdjęcia -> szerokość: 736px, wysokość: 1000px',
            supportedPictureExtensions: 'Wspierane rozszerzenia zdjęć: .jpeg, .jpg, .png',
            customerData: 'Dane klienta',
            shippingCosts: ' + koszty wysyłki',
            changeCustomerData: 'Zmiany danych klienta można dokonać w panelu użytkownika.',
            placeOrder: 'Złóż zamówienie',
            cookieContent1: 'WPROWADZENIE',
            cookieContent2: 'FH Sitko może korzystać z plików cookie, sygnałów nawigacyjnych, pikseli śledzących i innych technologii śledzenia podczas odwiedzania naszej witryny fhsitko.pl, w tym wszelkich innych form mediów, kanałów medialnych, witryn mobilnych lub powiązanych z nimi aplikacji mobilnych (łącznie zwanych „Stroną” ”), Aby pomóc w dostosowaniu Witryny i poprawić jej komfort.',
            cookieContent3: 'Zastrzegamy sobie prawo do wprowadzania zmian w niniejszej Polityce dotyczącej plików cookie w dowolnym czasie i z dowolnego powodu. Wszelkie zmiany lub modyfikacje zaczną obowiązywać natychmiast po opublikowaniu zaktualizowanej Polityki dotyczącej plików cookie w Witrynie, a użytkownik rezygnuje z prawa do otrzymania konkretnego powiadomienia o każdej takiej zmianie lub modyfikacji.',
            cookieContent4: 'Zachęcamy do okresowego przeglądania niniejszej Polityki dotyczącej plików cookie, aby otrzymywać informacje o aktualizacjach. Zostaniesz uznany za poinformowanego, będzie podlegać i będzie uznawany za zaakceptowanie zmian we wszelkich poprawionych Zasadach dotyczących plików cookie poprzez dalsze korzystanie z Witryny po opublikowaniu takich zmienionych Zasad dotyczących plików cookie.',
            cookieContent5: 'WYKORZYSTANIE PLIKÓW COOKIE',
            cookieContent6: '„Cookie” to ciąg informacji, który przypisuje ci unikalny identyfikator, który przechowujemy na twoim komputerze. Twoja przeglądarka zapewnia ten unikalny identyfikator, którego można użyć przy każdym przesyłaniu zapytania do Witryny. Używamy plików cookie w Witrynie, aby między innymi śledzić używane usługi, rejestrować informacje rejestracyjne, rejestrować preferencje użytkownika, logować się do Witryny, ułatwiać procedury zakupu i śledzić odwiedzane strony. Pliki cookie pomagają nam zrozumieć, w jaki sposób strona jest wykorzystywana, i poprawiają komfort użytkowania.',
            cookieContent7: 'RODZAJE PLIKÓW COOKIE',
            cookieContent8: 'Podczas odwiedzania Witryny mogą być używane następujące rodzaje plików cookie:',
            cookieContent9: 'Reklamowe pliki cookie',
            cookieContent10: 'Reklamowe pliki cookie są umieszczane na Twoim komputerze przez reklamodawców i serwery reklam w celu wyświetlania reklam, które mogą Cię zainteresować. Te pliki cookie pozwalają reklamodawcom i serwerom reklam gromadzić informacje o twoich wizytach w Witrynie i innych witrynach, naprzemiennie wyświetlać reklamy na określonym komputerze oraz śledzić, jak często reklama była oglądana i przez kogo. Te pliki cookie są powiązane z komputerem i nie gromadzą żadnych danych osobowych na Twój temat.',
            cookieContent11: 'Pliki cookie Analytics',
            cookieContent12: 'Ciasteczka analityczne monitorują sposób, w jaki użytkownicy dotarli do Witryny oraz w jaki sposób wchodzą w interakcje i poruszają się raz w Witrynie. Te pliki cookie informują nas, które funkcje w Witrynie działają najlepiej i jakie funkcje w Witrynie można ulepszyć.',
            cookieContent13: 'Nasze pliki cookie',
            cookieContent14: 'Nasze pliki cookie to „własne pliki cookie” i mogą być trwałe lub tymczasowe. Są to niezbędne pliki cookie, bez których Witryna nie będzie działać poprawnie lub nie będzie w stanie zapewnić określonych funkcji i funkcjonalności. Niektóre z nich można ręcznie wyłączyć w przeglądarce, ale mogą mieć wpływ na funkcjonalność Witryny.',
            cookieContent15: 'Pliki cookie personalizacji',
            cookieContent16: 'Personalizacyjne pliki cookie służą do rozpoznawania osób regularnie odwiedzających Witrynę. Używamy tych plików cookie do rejestrowania historii przeglądania, odwiedzanych stron oraz ustawień i preferencji przy każdej wizycie na stronie.',
            cookieContent17: 'Pliki cookie bezpieczeństwa',
            cookieContent18: 'Zabezpieczające pliki cookie pomagają identyfikować i zapobiegać zagrożeniom bezpieczeństwa. Używamy tych plików cookie do uwierzytelniania użytkowników i ochrony danych użytkowników przed nieautoryzowanymi stronami.',
            cookieContent19: 'Pliki cookie do zarządzania witryną',
            cookieContent20: 'Pliki cookie do zarządzania witryną służą do zachowania Twojej tożsamości lub sesji w Witrynie, dzięki czemu nie jesteś nieoczekiwanie wylogowany, a wszelkie wprowadzane informacje są przechowywane ze strony na stronę. Tych plików cookie nie można wyłączyć indywidualnie, ale można wyłączyć wszystkie pliki cookie w przeglądarce.',
            cookieContent21: 'Pliki cookie stron trzecich',
            cookieContent22: 'Pliki cookie stron trzecich mogą być umieszczane na twoim komputerze, gdy odwiedzasz Stronę przez firmy, które prowadzą niektóre oferowane przez nas usługi. Te pliki cookie umożliwiają stronom trzecim gromadzenie i śledzenie niektórych informacji o tobie. Te pliki cookie można ręcznie wyłączyć w przeglądarce.',
            cookieContent23: 'KONTROLA PLIKÓW COOKIE',
            cookieContent24: 'Większość przeglądarek jest domyślnie ustawiona na akceptowanie plików cookie. Możesz jednak usunąć lub odrzucić pliki cookie w ustawieniach przeglądarki. Należy pamiętać, że takie działanie może wpłynąć na dostępność i funkcjonalność Strony.',
            cookieContent25: 'Aby uzyskać więcej informacji na temat kontrolowania plików cookie, sprawdź ustawienia przeglądarki lub urządzenia w celu kontrolowania lub odrzucania plików cookie lub odwiedź następujące linki:',
            cookieContent26: 'Ponadto możesz zrezygnować z plików cookie stron trzecich za pośrednictwem ',
            cookieContent27: 'Network Advertising Initiative’s Opt-Out Tool.',
            cookieContent28: 'INNE TECHNOLOGIE ŚLEDZENIA',
            cookieContent29: 'Oprócz plików cookie możemy korzystać z sygnałów nawigacyjnych, znaczników pikselowych i innych technologii śledzenia w Witrynie, aby pomóc w dostosowaniu Witryny i polepszeniu jej działania. „Sygnał nawigacyjny” lub „znacznik pikselowy” to mały obiekt lub obraz osadzony na stronie internetowej lub w wiadomości e-mail. Służą do śledzenia liczby użytkowników, którzy odwiedzili określone strony i przeglądali wiadomości e-mail, oraz uzyskują inne dane statystyczne. Gromadzą tylko ograniczony zestaw danych, takich jak numer pliku cookie, godzina i data wyświetlenia strony lub wiadomości e-mail oraz opis strony lub wiadomości e-mail, na której się znajdują. Sygnałów nawigacyjnych i znaczników pikselowych nie można odrzucić. Możesz jednak ograniczyć ich użycie, kontrolując pliki cookie, które z nimi współpracują.',
            cookieContent30: 'POLITYKA PRYWATNOŚCI',
            cookieContent31: 'Aby uzyskać więcej informacji o tym, jak wykorzystujemy informacje gromadzone przez pliki cookie i inne technologie śledzenia, zapoznaj się z naszą ',
            cookieContent32: 'Polityką prywatności ',
            cookieContent33: ' opublikowaną na stronie. Niniejsza Polityka plików cookie jest częścią naszej polityki prywatności. Korzystając z Witryny, zgadzasz się na przestrzeganie niniejszej Polityki dotyczącej plików cookie i naszej Polityki prywatności.',
            cookieContent34: 'SKONTAKTUJ SIĘ Z NAMI',
            cookieContent35: 'Jeśli masz pytania lub uwagi dotyczące niniejszej Polityki dotyczącej plików cookie, skontaktuj się z nami pod adresem:',
            deleteProduct: 'Usuń produkt',
            editProduct: 'Edytuj produkt',
            sureDelete: 'Na pewno chcesz usunąć produkt: ',
            back: 'Cofnij',
            beCareful: 'Bądź ostrożny! Obecne zdjęcia zostaną zastąpione nowymi.',
            errorAddProduct: 'Dodawanie produktu nie powiodło się.',
            successAddProduct: 'Dodawanie produktu powiodło się.',
            deleteSuccess: 'Pomyślnie usunięto produkt!',
            successEditProduct: 'Pomyślnie edytowano produkt.',
            errorEditProduct: 'Edycja produktu nie powiodła się.',
            minimumOrder: 'Minimalna wartość zakupów to 500zł (brutto).',
            discountInfo: 'Rabat zostanie uwzględniony w podsumowaniu.',
            discountInfoCheckout: 'Uwzględniono rabat',
            pageNotFound: 'Nie znaleziono strony',
            backToHome: 'Wróć do strony głównej',
            resetSuccess: 'Poprawnie zresetowano hasło. Sprawdź swojego maila.',
            passwordReset: 'Resetowanie hasła',
            passwordResetProblem: 'Resetowanie hasła nie powiodło się. Spróbuj jeszcze raz.',
            prefferedUnit: 'Preferowana jednostka pakowania:',
            prefferedUnitError: 'Należy wybrać preferowaną jednostkę pakowania dla każdego produktu.',
            orderSuccess: 'Pomyślnie złożono zamówienie. Potwierdzenie wysłano na maila.',
            orderFailed: 'Składanie zamówienia nie powiodło się. Spróbuj ponownie.',
            christmas: 'Boże Narodzenie',
            glassBaubles: 'Bombki szklane',
            plasticBaubles: 'Bombki plastikowe',
            decoratedBaubles: 'Bombki dekoracyjne',
            christmasTreeSpikes: 'Szpice choinkowe',
            others: 'Pozostałe',
            easter: 'Wielkanoc',
            chickens: 'Kurczaki',
            eggs: 'Jajka',
            birdsAndButterflies: 'Ptaki i motyle',
            artificialFlowers: 'Kwiaty sztuczne',
            artificialFlowers2: 'Kwiaty wyrobowe',
            singleFlowers: 'Kwiaty pojedyczne',
            bouquets: 'Bukiety',
            leaves: 'Liście',
            artificialFoundations: 'Podkłady sztuczne',
            differentAssortment: 'Inny asortyment',
            all: 'Kategorie produktów - wszystko',
            category: 'Kategoria',
            copyProduct: 'Skopiuj produkt',
            copyProductCareful: 'W przypadku kopiowania produktu należy dodać nowe zdjęcia.'
        }
    },
    zh: {
        locale: 'zh',
        messages: {
            langCode: 'zh',
            language: '語言',
            polish: '拋光',
            english: '英語',
            chinese: '中文',
            copyright: "網站創建者 ",
            topbar_title: '歡迎來到我們的商店',
            call_us: '呼叫',
            home: '家',
            login: '登錄',
            register: '寄存器',
            about_us: '關於我們',
            compare: '相比',
            FAQ: '常問問題',
            shop: '店',
            contact: '聯繫',
            aboutHeader: '關於我們',
            aboutContent1: '我们的公司成立于2006年，是我们家族企业的一部分。专门从事进口和销售人造花以及圣诞节和复活节装饰品。为了扩大市场，从2005年开始进口产品，并一直在不断扩大我们的产品种类。',
            aboutContent2: '产品主要提供给波兰和周边国家的批发商。在我们的目录中，您可以找到：',
            aboutContent3: '• 人造花（花头，花束，人造树枝，树叶）-圣诞节装饰（玻璃和塑料小玩意，花环等）',
            aboutContent4: '• 复活节装饰（彩蛋，小鸡，兔子等）',
            aboutContent5: '• 人造花环的底座',
            aboutContent6: '• 花掰。',
            aboutContent7: '• 插花。',
            aboutContent8: '凭借长期的经验，众多供应商和客户，我们的订单不断增多。我们的追求是客户满意度，与制造商的良好合作关系。',
            newProducts: '新產品',
            new: '新',
            addToCart: '添加到購物車',
            loginToBuy: '登錄購買',
            quickView: '快速瀏覽',
            productUnit: '包裝單位',
            quantity: '數量 （分片）',
            viewDetail: '查看詳細',
            cartonUnit: '紙箱',
            packUnit: '框',
            relatedProducts: '相關產品',
            product: "產品 / ",
            products: '產品展示',
            sortingItems: '排序項目',
            highToLow: '價格：從高到低',
            lowToHigh: '價格：從低到高',
            newest: '最新商品',
            ascOrder: '按名稱排序：A 到 Z',
            descOrder: '按名稱排序：Z 到 A',
            noProduct: '沒有更多要加載的產品',
            noResults: '找不到您要的產品！',
            tryAgain: '請檢查您是否拼寫錯誤或嘗試用其他單詞搜索。',
            continueShopping: '繼續購物',
            pcs: ' 個',
            code: '碼',
            productDetails: '產品詳情',
            quantityCart: '數量（個）',
            delete: '刪除',
            unitPrice: '單價',
            image: '圖片',
            productName: '產品名稱',
            total: '總',
            checkout: '查看',
            cart: '大車',
            emptyCart: '您的購物車是空的',
            pleaseLogin: '登录后访问内容',
            logging: '登錄',
            registering: '註冊',
            email: '電子郵件',
            password: '密碼',
            emailEnter: '輸入你的電子郵箱',
            passwordEnter: '輸入密碼',
            newCustomer: '新客戶',
            createAccount: '創建一個賬戶',
            registerTip: '在我們的商店中創建一個免費帳戶。 註冊非常簡單快捷。 允許您下訂單。',
            placeholderSearch: '輸入產品名稱或代碼',
            viewCart: '查看購物車',
            information: '信息',
            basicInformation: '法规和基本信息',
            basicInformation1: 'www.fhsitko.pl上的在线批发商店是由FIRM HANDLOWA GRZEGORZ SITKO（地址：35-511Rzeszów，ul）经营的。 PrymasaTysiąclecia9/2，NIP：9451871977，REGON：180157064',
            basicInformation2: '在销售系统中注册',
            basicInformation3: '1.注册基于CEIdG的Nip数据（需要进行业务活动）。',
            basicInformation4: '2.要求客户在注册过程中提供完整而真实的数据。 FHSITKO对由于提供虚假数据而造成的损失不承担任何责任。',
            basicInformation5: '3.对分配给客户帐户的数据（个人数据，联系人等）进行的任何更改都需要FHSITKO的同意。',
            basicInformation6: '4.产品价格仅对注册和验证的客户可见。',
            basicInformation7: '价格和订单',
            basicInformation8: '1.所有可在网站www.fhsitko.pl上看到的价格均以波兰兹罗提（PLN）表示，均为总价格（含增值税）。',
            basicInformation9: '2.通过该网站可以访问有关当前正在销售的商品和服务的信息。',
            basicInformation10: '3.下达作为仓库一部分的可用商品的订单，并签订远距离销售合同。',
            basicInformation11: '4.价格为批发价（我们不零售）。',
            basicInformation12: '5.通过电子邮件发送给收件人和潜在客户的有关产品的信息（信息，广告材料）和www.fhsitko.pl上的信息，在法律意义上不构成要约。',
            basicInformation13: '6.通过网站www.fhsitko.pl接受订单',
            basicInformation14: '7.下订单并不等于接受订单。',
            basicInformation15: '8.根据仓库中货物的可用性检查并执行所有订单。',
            basicInformation16: '9.下订单后，将以电子方式发送有关订购产品的可用性和最终价格的信息。 如果出现产品短缺/缺陷等情况，应立即通知订约当局。',
            basicInformation17: '10.只有在收到确认形式的订单已接受的形式发票后，订购方才应将所有应付金额支付给FHSITKO帐户。',
            basicInformation18: '11.如果订单尚未实际执行，则订约机构可以在执行过程中取消/更改订单。',
            basicInformation19: '供应',
            basicInformation20: '1.订购商品可以在销售地点亲自领取，也可以通过快递或其他先前约定的方式发送。',
            basicInformation21: '2.运输费用应由订货方承担。',
            basicInformation22: '3.如果通过快递方式发送货物，则将通知订购方其费用，接受费用将与订单的执行相关（将添加到发票中）。',
            basicInformation23: '4.运输/交付在收到FHSITKO帐户的付款后的五个工作日内进行。',
            basicInformation24: '5.增值税发票与货物一起开具，以确认购买。 如果找不到，请报告此事实。',
            basicInformation25: '6.收到包裹/收据后，订购方应检查其状况和内容。 如果出现缺陷/损坏，他应采取一切必要的措施来确定快递人员的责任。',
            basicInformation26: '支付方式',
            basicInformation27: '1.在销售点收到商品时以现金付款。',
            basicInformation28: '2.在付款被放到FHSIKTO银行帐户中之前，将货物释放给订购方（通过快递发送）。',
            basicInformation29: '3.以另一种商定的方式。',
            basicInformation30: '退货和投诉',
            basicInformation31: '1.雇主购买的商品打算转售，因此，双方根据本细则进行。 艺术。 《民法》第558条第1款排除了卖方对隐含缺陷的担保责任，但已单独授予担保的货物除外。',
            basicInformation32: '2.对所购商品的物理缺陷的担保仅包括签约机构交付无缺陷产品的权利。 如果发现产品缺货，则发票将被更正并且应退还的差额将被退还。 根据艺术品的内容，对于物理缺陷的担保规定所产生的其他权利不包括在内。 《民法典》 558§1。',
            basicInformation33: '3.如果货物存在缺陷或不符合合同规定，退货费用由FHSITKO承担。',
            basicInformation34: '4.货物应返回原包装（未破损）包装，以确保安全运输。',
            basicInformation35: '最后条款',
            basicInformation36: '1.运营网站www.fhsitko.pl的实体对由于技术原因（维护，检查，设备更换等）或超出其控制范围的其他原因而中断在线仓库的使用概不负责。',
            basicInformation37: '2. FHSITKO保留更改批发商报价中产品价格和数量的权利。',
            basicInformation38: '3.根据波兰法律和波兰语，通过增值税发票记录了销售合同。',
            basicInformation39: '4. www.fhsitko.pl上的所有产品照片均为FHSITKO的财产。 未经许可，禁止全部或部分复制，分发和使用。 根据屏幕设置，显示的颜色可能与实际情况略有不同。',
            basicInformation40: '五，在本条例未涵盖的事项中，应适用民法典的有关规定。',
            basicInformation41: '价格和折扣',
            basicInformation42: '1.价格为批发价（我们不零售）。',
            basicInformation43: '2.在在线仓库中下订单时，折扣是自动计算的。',
            basicInformation44: '3.对于促销产品和销售产品，给出的价格为销售价格（应付），不能因适用的折扣而降低。',
            basicInformation45: '4.价格包括增值税（毛额），为1项目。',
            basicInformation46: '5.给出的价格不包括交付成本。',
            basicInformation47: '6.将给出每种产品的产品交付成本。 对于几种不同的产品，交货成本将单独计算，因为将几种产品包装到一个纸箱中会便宜得多。',
            basicInformation48: '7.可购买的最小数量是包装（取决于产品：12、24、36件）。',
            basicInformation49: '8.最低购买价值为500波兰兹罗提（总价）',
            basicInformation50: '9.一次性折扣：',
            basicInformation51: 'a。 价值超过2000（波兰兹罗提）的订单的3％。',
            basicInformation52: 'b。 订单金额超过3500（波兰兹罗提）的5％。',
            basicInformation53: 'c。 订单金额超过6,000（波兰兹罗提）的7％。',
            basicInformation54: 'd。 订单金额超过8,000（波兰兹罗提）时，收取10％的费用。',
            basicInformation55: 'e。 15500以上的订单（总PLN）的15％。',
            basicInformation56: '10.永久回扣（取决于年营业额-所需的适当合同，购买历史记录）：',
            basicInformation57: 'a。订单金额超过15,000（波兰兹罗提），则为3％。',
            basicInformation58: 'b。订单金额超过20,000（波兰兹罗提）的5％。',
            basicInformation59: 'c。超过25,000（总PLN）的订单为7％。',
            basicInformation60: 'd。订单金额超过30,000（波兰兹罗提）的，收取10％。',
            basicInformation61: 'e。订单金额超过40,000（波兰兹罗提）的，收取15％。',
            basicInformation62: '11. 如果个人收集的订单超过6000个，则客户将获得2％的额外折扣。',
            basicInformation63: '12. 折扣单独设置。',
            policyPrivacy: '政策私隱',
            sale: '打折',
            delivery: '運輸成本',
            prices: '价格和折扣',
            faq: '常問問題',
            cookiesPolicy: 'Cookies 政策',
            address: '地址',
            contactContent1: '凭借长期的经验，众多供应商和客户，我们的订单不断增多。我们的追求是客户满意度，与制造商的良好合作关系。如果您有任何与我们业务有关的有趣报价，请随时将其发送至import@fhsitko.pl。如果您希望我们对包装和价格感兴趣，请记住提供包装和价格。我们将尝试尽快回答。我们的总部位于热舒夫，销售部门在克拉科夫设有办事处:',
            contactContent2: '圣诞节（11月至12月）和复活节（3月至4月）季节，我们也会在Tychy开展业务，地址是：',
            storeInformation: '店鋪信息',
            policyPivacyContent1: '我们认真对待您的个人数据的保护。 如果您有任何疑问或疑问，可以随时与我们联系以进行澄清。 我们希望我们的报价能够满足您的需求，为此，我们在下面提供了我们认为重要的所有信息：',
            policyPivacyContent2: '1.您个人数据的管理员是：FIRMA HANDLOWA GRZEGORZ SITKO，35-511Rzeszówul。 PrymasaTysiąclecia9/2，NIP：9451871977，REGON：180157064（以下称为管理员）。',
            policyPivacyContent3: '2.为了保护委托给我们的数据的安全，我们制定了内部程序和建议，以防止将数据泄露给未经授权的人员。 我们控制它们的实施，并不断检查它们是否符合相关法律法规-有关个人数据的保护法，有关提供电子服务的法律以及各种行政行为和共同体法律。',
            policyPivacyContent4: '3.个人数据是在用户表示同意的基础上处理的，并且在法律授权管理员根据法律或为执行双方之间订立的合同的目的而处理个人数据的情况下。',
            policyPivacyContent5: '4.根据《个人数据保护法》的规定，网站上保留的个人数据将不会出售或提供给第三方。',
            policyPivacyContent6: '5.网站通过以下方式收集有关用户及其行为的信息：',
            policyPivacyContent61: 'a。通过自愿以表格形式输入的信息',
            policyPivacyContent62: 'b。通过收集“ cookie”',
            policyPivacyContent7: '6.网站收集用户自愿提供的信息。',
            policyPivacyContent8: '7.您有权随时请求访问您的个人数据（更正，删除，限制其处理和数据传输）。',
            policyPivacyContent9: '8.您有权随时撤回对处理您的个人数据的同意。',
            policyPivacyContent10: '9.我们将出于营销目的对提供的个人数据，电子邮件地址和电话号码进行处理。 我们希望与客户保持最佳联系，以告知他们有关新产品，促销和订单状态的信息。',
            policyPivacyContent11: '10.个人数据的管理员是Grzegorz Sitko（电话：603104321）',
            policyPivacyContent12: '11.我们只会在您的数据发送有关新产品的信息时存储您的数据。 如果您不再对此感兴趣，则可以随时撤回您的同意。',
            policyPivacyContent13: '12.您的电子邮件地址和电话号码将用于与您交流。 这样，您将被告知我们的产品，促销和订单状态',
            policyPivacyContent14: '13.如果您认为您的个人数据遭到侵犯，则有权随时向个人数据保护办公室主任投诉。',
            policyPivacyContent15: '14.作为网站管理员，我们使用各种外部服务提供商来更好地提供我们自己的服务。 您提供的个人数据可能会被他们使用。 这些公司为我们提供快递服务和在线服务。 我们与他们签订了数据委托协议，以确保您的数据安全。 作为网站管理员，我们还负责为我们处理您的个人数据的实体。',
            policyPivacyContent16: '15.您已表明您同意管理员出于营销目的处理您的个人数据，并同意与您进行交流以告知促销和您的订单状态。',
            policyPivacyContent17: '16.我们保留更改网站隐私政策的权利，该隐私政策可能会受到互联网技术的发展，个人数据保护领域法律的可能变化以及我们网站的发展的影响。 我们将以可见和可理解的方式通知您任何更改。',
            yourCartEmpty: '您的購物車目前是空的。',
            dashboard: '面板',
            logout: '登出',
            accountInfo: '帐户信息',
            my_account: '我的帐户',
            loginInfo: '登录信息',
            defaultLanguage: '预设语言',
            changePassword: '更改密码',
            name: '名称',
            surname: '姓',
            companyName: '公司名',
            phoneNumber: '电话号码',
            city: '市',
            postalCode: '邮政编码',
            country: '国家',
            contactInformation: '联系信息',
            edit: '编辑',
            passwordRepeat: '重复输入密码',
            emailRepeat: '重复的电子邮件',
            alreadyLogin: '您已经登录',
            save: '保存',
            cancel: '取消',
            oldPassword: '旧密码',
            newPassword: '新密码',
            repeatNewPassword: '重复新密码',
            successSave: '新帐户详细信息已正确保存！',
            enterNip: '输入 NIP',
            enterName: '输入名字',
            enterSurname: '输入姓氏',
            enterCompanyName: '输入公司名称',
            enterAddress: '输入地址',
            enterPhone: '输入电话号码',
            enterCity: '输入城市',
            enterCountry: '输入国家',
            enterEmail: '输入电子邮件',
            enterPostalCode: '输入邮递区号',
            enterPassword: '输入密码',
            emptyFieldRegister: '填写所有带有星号的字段',
            wrongEmail: '无效的电子邮件格式',
            passNotMatch: '密码不匹配',
            emailNotMatch: '电子邮件内容不符',
            wrongNip: '无效的 NIP（税号）',
            faq1: '订单提前期？',
            faq2: '根据规定，交货时间为收到付款后的五个工作日。 根据订单数量，此截止日期可以越来越短。 在圣诞节之前，订单数量的增加可能会影响订单的延长。',
            faq3: '产品原产地？',
            faq4: '我们的大多数产品来自亚洲，并由我们公司进口。 有些产品是波兰产的（波兰生产商也有有趣的产品）。',
            faq5: '产品有条形码吗？',
            faq6: '在大多数情况下，可能的话会在产品上贴条形码。 这些是铝箔包装，纸板箱，在某些情况下还可以是单个零件。',
            faq7: '我可以提前订购商品吗？',
            faq8: '有这种可能性。 如果我们知道货物很快就会到帐，我们可以储备它们，储备它们。 如果要订购特定产品，可以根据特殊要求订购-如果已指定数量。',
            faq9: '您可以在哪里领取订购的商品？',
            faq10: '首选在克拉科夫的花卉市场接客。',
            faq11: 'Centrum Giełdowe Balicka  30-149 Kraków ul. Balicka 56/20B',
            faq12: '周一至周五上午3点至上午10点，周六上午3点至上午8点',
            emailAlreadyTaken: '该电子邮件已被接收。',
            noChanges: '尚未进行任何更改。',
            changesSuccess: '更改已成功完成。',
            voidName: '名称不能为空。',
            voidSurname: '姓不能为空。',
            voidCompanyName: '公司名称不能为空。',
            voidAddress: '地址不能为空。',
            voidPhone: '电话号码不能为空。',
            voidCity: '城市不能为空。',
            voidPostalCode: '邮政编码不能为空。',
            emptyOldPassword: '旧密码为空。',
            emptyNewPassword: '新密码为空。',
            wrongOldPass: '不正确的旧密码。',
            forgetPassword: '我忘记了我的密码',
            provideEmail: '我们将发送密码更改链接到提供的电子邮件地址。',
            send: '发送',
            sendConfirm: '如果用户存在-已发送更改密码的链接。',
            addProduct: '添加产品',
            productCode: "产品代码 *",
            nameEnglish: "英文名 *",
            namePolish: "波兰语名称 *",
            nameChinese: "中文名 *",
            price: '单价 *',
            family: "家庭 *",
            lessCapacity: '较小的容器容量 *',
            moreCapacity: '较大包装的容量 *',
            lessUnitEnglish: "较小包装的英文名称 *",
            moreUnitEnglish: "大包装的英文名称 *",
            lessUnitPolish: "较小包装的波兰语名称 *",
            moreUnitPolish: "较大包装的波兰语名称 *",
            lessUnitChinese: "小包装的中文名称 *",
            moreUnitChinese: "大包装的中文名称 *",
            descriptionEnglish: "英文说明 *",
            descriptionPolish: "波兰语说明 *",
            descriptionChinese: "中文说明 *",
            uploadImages: '上传图片',
            removeAllImages: '删除所有图片',
            update: '更新资料',
            remove: '去掉',
            pictures: '图片 *',
            maxPictureSize: '单个图片的最大大小：5MB',
            recommendedPictureDimensions: '单张图片的最大尺寸和建议尺寸->宽度：736像素，高度：1000像素',
            supportedPictureExtensions: '支持的图片扩展名：.jpeg，.jpg，.png',
            customerData: '客户资料',
            shippingCosts: ' + 运输费',
            changeCustomerData: '可以在用户面板中更改客户数据。',
            placeOrder: '下订单',
            cookieContent1: '介绍',
            cookieContent2: '当您访问我们的网站fhsitko.pl时，FH Sitko可能会使用cookie，网络信标，跟踪像素和其他跟踪技术，包括任何其他与之相关或连接的媒体形式，媒体渠道，移动网站或移动应用程序（统称为“站点 ”）以帮助自定义网站并改善您的体验。',
            cookieContent3: '我们保留随时出于任何原因对本Cookie政策进行更改的权利。 任何更改或修改将在网站上发布更新后的Cookie政策后立即生效，并且您放弃接收每次此类更改或修改的特定通知的权利。',
            cookieContent4: '我们鼓励您定期查看本Cookie政策，以随时了解更新。 在您发布修订后的Cookie政策之日起继续使用本网站，您将被视为已被告知，受其约束且将被视为接受任何修订的Cookie政策的更改。',
            cookieContent5: '使用饼干',
            cookieContent6: '“ cookie”是一串信息，为您分配一个我们存储在您计算机上的唯一标识符。 然后，您的浏览器会提供该唯一标识符，以供您在每次向站点提交查询时使用。 我们在网站上使用Cookie来跟踪您所使用的服务，记录注册信息，记录您的用户偏好，使您保持登录网站的状态，简化购买过程并跟踪您访问的页面。 Cookies可帮助我们了解网站的使用方式并改善您的用户体验。',
            cookieContent7: '饼干的类型',
            cookieContent8: '当您访问本网站时，可能会使用以下类型的cookie：',
            cookieContent9: '广告饼干',
            cookieContent10: '广告Cookie由广告商和广告服务器放置在您的计算机上，以便显示您最可能感兴趣的广告。 这些Cookie允许广告商和广告服务器收集有关您对本网站和其他网站的访问的信息，替换发送到特定计算机的广告，并跟踪查看广告的频率和访问者。 这些cookie链接到计算机，不会收集有关您的任何个人信息。',
            cookieContent11: '分析Cookie',
            cookieContent12: 'Analytics cookie监视用户如何访问网站，以及他们如何与网站交互以及如何在网站上四处走动。 这些Cookie可以让我们知道网站上哪些功能最有效，以及网站上哪些功能可以改进。',
            cookieContent13: '我们的饼干',
            cookieContent14: '我们的cookie是“第一方cookie”，可以是永久性或临时性的。 这些是必要的cookie，否则，本网站将无法正常运行或无法提供某些功能。 其中一些可能会在浏览器中手动禁用，但可能会影响网站的功能。',
            cookieContent15: '个性化Cookie',
            cookieContent16: '个性化cookie用于识别站点的重复访问者。 我们使用这些cookie记录您的浏览历史记录，您访问过的页面以及您每次访问本网站时的设置和首选项。',
            cookieContent17: '安全性Cookie',
            cookieContent18: '安全cookie有助于识别和预防安全风险。 我们使用这些cookie来验证用户身份并保护用户数据免遭未授权方的攻击。',
            cookieContent19: '网站管理Cookies',
            cookieContent20: '站点管理cookie用于维护您在站点上的身份或会话，以便您不会意外注销，并且您输入的任何信息都会在页面之间保留。 这些cookie不能单独关闭，但是您可以禁用浏览器中的所有cookie。',
            cookieContent21: '第三方Cookie',
            cookieContent22: '运行我们提供某些服务的公司在您访问本网站时可能会将第三方Cookie放置在您的计算机上。 这些Cookie允许第三方收集和跟踪有关您的某些信息。 您可以在浏览器中手动禁用这些cookie。',
            cookieContent23: '控制饼干',
            cookieContent24: '大多数浏览器默认设置为接受Cookie。 但是，您可以在浏览器的设置中删除或拒绝Cookie。 请注意，此类操作可能会影响网站的可用性和功能。',
            cookieContent25: '有关如何控制Cookie的更多信息，请检查浏览器或设备的设置以了解如何控制或拒绝Cookie，或访问以下链接：',
            cookieContent26: '此外，您可以通过以下方式选择退出某些第三方Cookie：',
            cookieContent27: 'Network Advertising Initiative’s Opt-Out Tool.',
            cookieContent28: '其他跟踪技术',
            cookieContent29: '除cookie之外，我们可能会在网站上使用网络信标，像素标记和其他跟踪技术来帮助自定义网站并改善您的体验。 “网络信标”或“像素标签”是嵌入在网页或电子邮件中的微小物体或图像。 它们用于跟踪访问特定页面和查看电子邮件的用户数量，以及获取其他统计数据。 他们仅收集有限的一组数据，例如Cookie编号，页面或电子邮件视图的时间和日期，以及它们所在的页面或电子邮件的描述。 网络信标和像素标签不能被拒绝。 但是，您可以通过控制与它们交互的cookie来限制它们的使用。',
            cookieContent30: '隐私政策',
            cookieContent31: '有关我们如何使用Cookie和其他跟踪技术收集的信息的更多信息，请参阅我们的',
            cookieContent32: '隐私政策',
            cookieContent33: '在网站上发布。 本Cookie政策是我们隐私政策的一部分，并已纳入我们的隐私政策。 使用本网站，您同意受本Cookie政策和我们的隐私政策的约束。',
            cookieContent34: '联系我们',
            cookieContent35: '如果您对此Cookie政策有任何疑问或意见，请通过以下方式与我们联系：',
            deleteProduct: '删除产品',
            editProduct: '编辑产品',
            sureDelete: '您确定要删除产品吗: ',
            back: '背部',
            beCareful: '小心！ 当前照片将被替换为新照片。',
            errorAddProduct: '添加产品失败。',
            successAddProduct: '该产品已成功添加。',
            deleteSuccess: '产品已成功删除！',
            successEditProduct: '产品已成功编辑。',
            errorEditProduct: '产品版本失败。',
            minimumOrder: '最低购买价值为500波兰兹罗提（总价）。',
            discountInfo: '折扣将包含在摘要中。',
            discountInfoCheckout: '包括折扣',
            pageNotFound: '网页未找到',
            backToHome: '返回首页',
            resetSuccess: '密码已成功重置。 查看你的电子邮件。',
            passwordReset: '重设密码',
            passwordResetProblem: '密码重置失败。 再试一次。',
            prefferedUnit: '首选包装单位：',
            prefferedUnitError: '为每种产品选择首选的包装单位。',
            orderSuccess: '订单已成功下达。 通过电子邮件发送确认。',
            orderFailed: '下订单失败。 再试一次。',
            christmas: '圣诞',
            glassBaubles: '玻璃小玩意',
            plasticBaubles: '塑料小玩意',
            decoratedBaubles: '装饰的小玩意',
            christmasTreeSpikes: '圣诞树钉',
            others: '其他',
            easter: '复活节',
            chickens: '鸡类',
            eggs: '蛋',
            birdsAndButterflies: '鸟类和蝴蝶',
            artificialFlowers: '人造花',
            artificialFlowers2: '人造花',
            singleFlowers: '单花',
            bouquets: '花束',
            leaves: '树叶',
            artificialFoundations: '人造粉底',
            differentAssortment: '不同的分类',
            all: '产品类别-全部',
            category: '类别',
            copyProduct: '复制产品',
            copyProductCareful: '复制产品时，添加新照片。'
        }
    },
    en: {
        locale: 'en_US',
        messages: {
            langCode: 'en',
            language: 'Language',
            polish: 'Polish',
            english: 'English',
            chinese: 'Chinese',
            copyright: "Created by ",
            topbar_title: 'Welcome to Our store',
            call_us: 'Call Us',
            home: 'Home',
            login: 'Login',
            register: 'Register',
            about_us: 'About us',
            compare: 'Porównaj',
            FAQ: 'FAQ',
            shop: 'Shop',
            contact: 'Contact',
            aboutHeader: 'About us',
            aboutContent1: 'Our company was established in 2006 as part of our family business. We specialize in importing and selling artificial flowers as well as Christmas and Easter decorations. We started importing goods in 2005 as we wanted to expand our market. Since then we have been constantly enlarging our product line.',
            aboutContent2: 'We offer our goods mainly to wholesalers who focus on floristic branch, both in Poland and abroad. In our catalogue you will find:',
            aboutContent3: '• Artificial flowers (heads, bunches, twigs, leaves),',
            aboutContent4: '• Christmas decoration (glass and plastic baubles, garlands, etc.),',
            aboutContent5: '• Easter decoration (eggs, chicks, bunnies, etc.),',
            aboutContent6: '• Base for artificial wreaths,',
            aboutContent7: '• Flower compositions.',
            aboutContent8: 'Thanks to our longstanding experience, numerous suppliers and customers we constantly increase our offer. Our goal is customer satisfaction as well as good relations with manufacturers.',
            newProducts: 'New products',
            new: 'new',
            addToCart: 'Add to cart',
            loginToBuy: 'Login to buy',
            quickView: 'Quick view',
            productUnit: 'Packaging Unit',
            quantity: 'Quantity (in pcs.)',
            viewDetail: 'View Detail',
            cartonUnit: 'Carton',
            packUnit: 'Box',
            relatedProducts: 'Related Products',
            product: "Product / ",
            products: 'Products',
            sortingItems: 'Sorting items',
            highToLow: 'Price: High to Low',
            lowToHigh: 'Price: Low to High',
            newest: 'Newest Items',
            ascOrder: 'Sort By Name: A To Z',
            descOrder: 'Sort By Name: Z To A',
            noProduct: 'No more products to load',
            noResults: 'Couldn\'t find the product you were looking For!',
            tryAgain: 'Please check if you have misspelled something or try searching with other words.',
            continueShopping: 'Continue Shopping',
            pcs: ' pcs.',
            code: 'Code',
            productDetails: 'Product details',
            quantityCart: 'Quantity (pcs.)',
            delete: 'Delete',
            unitPrice: 'Unit price',
            image: 'Image',
            productName: 'Product name',
            total: 'Total',
            checkout: 'Checkout',
            cart: 'Cart',
            emptyCart: 'Your Cart is Empty',
            pleaseLogin: 'Access to content after logging in',
            logging: 'Login',
            registering: 'Register',
            email: 'Email',
            password: 'Password',
            emailEnter: 'Enter your email',
            passwordEnter: 'Enter your password',
            newCustomer: 'New customer',
            createAccount: 'Create a account',
            registerTip: 'Create a free account in our store. Registration is quick and easy. Allows you to place orders.',
            placeholderSearch: 'Enter product name or code',
            viewCart: 'View cart',
            information: 'Information',
            basicInformation: 'Regulations and basic information',
            basicInformation1: 'The online wholesale store at www.fhsitko.pl is run by FIRM HANDLOWA GRZEGORZ SITKO, 35-511 Rzeszów, ul. Prymasa Tysiąclecia 9/2, NIP: 9451871977, REGON: 180157064',
            basicInformation2: 'REGISTRATION IN THE SALE SYSTEM',
            basicInformation3: '1. Registration is based on Nip data from CEIdG (business activity required).',
            basicInformation4: '2. The customer is required to provide full and truthful data during the registration process. FHSITKO is not liable for damages resulting from providing false data.',
            basicInformation5: '3. Any changes to the data (personal data, contact person, etc.) assigned to the customers account require the consent of FHSITKO.',
            basicInformation6: '4. Product prices are visible only to registered and verified customers.',
            basicInformation7: 'PRICES AND ORDERS',
            basicInformation8: '1. All prices visible on the website www.fhsitko.pl are given in Polish zlotys (PLN) and are gross prices (including VAT).',
            basicInformation9: '2. The website provides access to information about goods and services currently on sale.',
            basicInformation10: '3. Placing orders for goods available as part of a warehouse and concluding distance sales contracts.',
            basicInformation11: '4. The prices are wholesale prices (we do not sell retail).',
            basicInformation12: '5. Information on products (information, advertising materials) sent via e-mail and information on www.fhsitko.pl addressed to recipients and potential customers do not constitute an offer within the meaning of the law.',
            basicInformation13: '6. Orders are accepted via the website www.fhsitko.pl',
            basicInformation14: '7. Placing an order is not tantamount to its acceptance.',
            basicInformation15: '8. All orders are checked and carried out depending on the availability of the goods in the warehouse.',
            basicInformation16: '9. Information on the availability and final price of the products ordered is sent electronically after placing the order. In the event of a shortage of products / defects, etc., the contracting authority shall be immediately informed.',
            basicInformation17: '10. All amounts due should be paid by the ordering party to the FHSITKO account only after receiving the pro-forma invoice, which confirms the acceptance of the order for execution.',
            basicInformation18: '11. The order may be canceled / changed by the contracting authority during the implementation process, if it has not been physically implemented.',
            basicInformation19: 'SUPPLY',
            basicInformation20: '1. Ordered goods can be picked up in person at the place of sale, can be sent via courier or other previously agreed way.',
            basicInformation21: '2. The transport costs shall be borne by the ordering party.',
            basicInformation22: '3. In the case of sending the goods via courier, the ordering party will be informed of its cost, the acceptance of which will be associated with the implementation of the order (will be added to the invoice).',
            basicInformation23: '4. Shipping / delivery takes place within five business days of receipt of payment to the FHSITKO account.',
            basicInformation24: '5. A VAT invoice is issued together with the goods, which confirms the purchase. If it is not found, please report this fact.',
            basicInformation25: '6. Upon receipt of the parcel / receipt, the ordering party should check its condition and content. In the event of deficiencies / damage, he should perform all actions necessary to determine the couriers liability.',
            basicInformation26: 'PAYMENT METHODS',
            basicInformation27: '1. Payment in cash upon receipt of the item at the point of sale.',
            basicInformation28: '2. Before the goods are released to the ordering party (sent by courier), after the payment has been credited to the FHSIKTO bank account.',
            basicInformation29: '3. In another agreed way.',
            basicInformation30: 'RETURN OF GOODS AND COMPLAINTS',
            basicInformation31: '1. Goods purchased by the Employer are intended for resale, therefore the parties in accordance with art. Art. 558 §1 of the Civil Code excludes the liability of the seller under the warranty for hidden defects, except for goods for which a separate warranty has been granted.',
            basicInformation32: '2. Warranty for physical defects of purchased goods includes only the contracting authoritys right to deliver products free from defects. If the product is found out of stock, then the invoice will be corrected and the difference in amount due will be refunded. Other rights arising from the provisions on warranty for physical defects are excluded, pursuant to the content of art. 558 §1 of the Civil Code.',
            basicInformation33: '3. If the goods contain defects or do not comply with the contract, the costs of returning the shipment are covered by FHSITKO.',
            basicInformation34: '4. Goods should be returned in the original (undamaged) packaging enabling its safe transport.',
            basicInformation35: 'FINAL PROVISIONS',
            basicInformation36: '1. The entity running the website www.fhsitko.pl is not responsible for interruptions in the use of the online warehouse for technical reasons (maintenance, inspection, replacement of equipment, etc.) or other reasons beyond its control.',
            basicInformation37: '2. FHSITKO reserves the right to change prices and quantity of products in the wholesalers offer.',
            basicInformation38: '3. Sales contracts are documented by means of a VAT invoice in accordance with Polish law and in Polish.',
            basicInformation39: '4. All product photos on www.fhsitko.pl are the property of FHSITKO. All copying, distribution and use in whole / part without permission is prohibited. Depending on the screen settings, the presented colors may slightly differ from reality.',
            basicInformation40: '5. In matters not covered in these Regulations, the relevant provisions of the Civil Code shall apply.',
            basicInformation41: 'PRICES AND DISCOUNTS',
            basicInformation42: '1. The prices are wholesale prices (we do not sell retail)',
            basicInformation43: '2. When placing an order in an online warehouse, the discount is calculated automatically.',
            basicInformation44: '3. In the case of promotional and sale products, the price given is the sale price (payable) and cannot be reduced by the applicable discounts.',
            basicInformation45: '4. The prices include VAT (gross) and are given for 1 item.',
            basicInformation46: '5. The prices given do not include the cost of delivery.',
            basicInformation47: '6. The cost of delivery of the products will be given for each product. For several different products, the cost of delivery will be calculated individually because packing several products into one carton can be much cheaper.',
            basicInformation48: '7. The smallest quantity available for purchase is packaging (depending on the product: 12, 24, 36 pieces).',
            basicInformation49: '8. The minimum purchase value is PLN 500 (gross)',
            basicInformation50: '9. One-time discount:',
            basicInformation51: 'a. 3% on orders with a value exceeding 2000 (gross PLN).',
            basicInformation52: 'b. 5% on orders exceeding 3500 (gross PLN).',
            basicInformation53: 'c. 7% for orders in excess of 6,000 (gross PLN).',
            basicInformation54: 'd. 10% for orders in excess of 8,000 (gross PLN).',
            basicInformation55: 'e. 15% on orders above 12500 (gross PLN).',
            basicInformation56: '10. Permanent rebate (subject to annual turnover - appropriate contract required, purchase history):',
            basicInformation57: 'a. 3% for orders in excess of 15,000 (gross PLN).',
            basicInformation58: 'b. 5% for orders in excess of 20,000 (gross PLN).',
            basicInformation59: 'c. 7% for orders in excess of 25,000 (gross PLN).',
            basicInformation60: 'd. 10% for orders with a value exceeding 30,000 (gross PLN).',
            basicInformation61: 'e. 15% on orders with a value exceeding 40,000 (gross PLN).',
            basicInformation62: '11. In the case of personal collection of orders exceeding 6000, the customer receives an additional 2% discount.',
            basicInformation63: '12. Discount set individually.',
            policyPrivacy: 'Policy privacy',
            sale: 'Discounts',
            delivery: 'Delivery costs',
            prices: 'Prices and discounts',
            faq: 'FAQ',
            cookiesPolicy: 'Cookies Policy',
            address: 'Address',
            contactContent1: 'Our head office is located in Rzeszów and the sales department operates in Krakow:',
            contactContent2: 'During Christmas (November - December) and Easter (March – April) season we operate also in Tychy:',
            storeInformation: 'Store information',
            policyPivacyContent1: 'We take the protection of your personal data seriously. If you have any doubts or questions, you can always contact us for clarification. We want our offer to be tailored to your needs and for this purpose we include below all information that we consider important:',
            policyPivacyContent2: '1. The administrator of your personal data is: FIRMA HANDLOWA GRZEGORZ SITKO, 35-511 Rzeszów ul. Prymasa Tysiąclecia 9/2, NIP: 9451871977, REGON: 180157064 (hereinafter referred to as the Administrator).',
            policyPivacyContent3: '2. In the interests of the security of data entrusted to us, we have developed internal procedures and recommendations to prevent disclosure of data to unauthorized persons. We control their implementation and constantly check their compliance with the relevant legal acts - the Act on the protection of personal data, the Act on the provision of electronic services, as well as all kinds of executive acts and Community law.',
            policyPivacyContent4: '3. Personal Data are processed on the basis of the consent expressed by the User and in cases where the law authorizes the Administrator to process personal data on the basis of the law or for the purpose of implementing a contract concluded between the parties.',
            policyPivacyContent5: '4. Personal data left on the website will not be sold or made available to third parties in accordance with the provisions of the Personal Data Protection Act.',
            policyPivacyContent6: '5. The website gathers information about users and their behavior in the following ways:',
            policyPivacyContent61: 'a. through information voluntarily entered in forms',
            policyPivacyContent62: 'b. by collecting "cookies"',
            policyPivacyContent7: '6. The website collects information voluntarily provided by the user.',
            policyPivacyContent8: '7. You have the right to request access to your personal data (rectification, deletion, limitation of their processing and transfer of data) at any time.',
            policyPivacyContent9: '8. You have the right to withdraw your consent to the processing of your personal data at any time.',
            policyPivacyContent10: '9. The personal data provided, e-mail address and telephone number will be processed by us for marketing purposes. We want to have the best contacts with our clients to inform them about new products, promotions and order status.',
            policyPivacyContent11: '10. The administrator of personal data is Grzegorz Sitko (tel: 603104321)',
            policyPivacyContent12: '11. We will only store your data if it sends information about new products. If you are no longer interested in it, you can withdraw your consent at any time.',
            policyPivacyContent13: '12. Your email address and phone number will be used to communicate with you. This way you will be informed about our products, promotions and order status',
            policyPivacyContent14: '13. If you think that your personal data has been violated, you have the right to lodge a complaint to the President of the Office for Personal Data Protection at any time.',
            policyPivacyContent15: '14. As a Website Administrator, we use various external service providers to better provide our own services. The personal data you provide may be used by them. These companies provide courier services and on-line service for us. We have a data entrustment agreement with them which ensures that your data is secure. As a website administrator, we are also responsible for entities that process your personal data for us.',
            policyPivacyContent16: '15. You have indicated that you consent to the processing of your personal data by the Administrator for marketing purposes and communication with you to inform about promotions and the status of your orders.',
            policyPivacyContent17: '16. We reserve the right to change the privacy policy of the website, which may be affected by the development of internet technology, possible changes in the law in the field of personal data protection and the development of our website. We will inform you about any changes in a visible and understandable way.',
            yourCartEmpty: 'Your cart is currently empty.',
            dashboard: 'Dashboard',
            logout: 'Logout',
            accountInfo: 'Account info',
            my_account: 'My account',
            loginInfo: 'Login information',
            defaultLanguage: 'Default language',
            changePassword: 'Change password',
            name: 'Name',
            surname: 'Surname',
            companyName: 'Company Name',
            phoneNumber: 'Phone Number',
            city: 'City',
            postalCode: 'Postal Code',
            country: 'Country',
            contactInformation: 'Contact Information',
            edit: 'Edit',
            passwordRepeat: 'Repeat Password',
            emailRepeat: 'Repeat Email',
            alreadyLogin: 'You are already logged in',
            save: 'Save',
            cancel: 'Cancel',
            oldPassword: 'Old password',
            newPassword: 'New password',
            repeatNewPassword: 'Repeat new password',
            successSave: 'The new account details have been saved correctly!',
            enterNip: 'Enter NIP',
            enterName: 'Enter name',
            enterSurname: 'Enter surname',
            enterCompanyName: 'Enter company name',
            enterAddress: 'Enter address',
            enterPhone: 'Enter phone number',
            enterCity: 'Enter city',
            enterCountry: 'Enter country',
            enterEmail: 'Enter email',
            enterPostalCode: 'Enter postal code',
            enterPassword: 'Enter password',
            emptyFieldRegister: 'Complete all fields marked with an asterisk',
            wrongEmail: 'Invalid email format',
            passNotMatch: 'Passwords do not match',
            emailNotMatch: 'Emails do not match',
            wrongNip: 'Invalid NIP (Tax Identification Number)',
            faq1: 'Order lead time?',
            faq2: 'Pursuant to the regulations, the delivery time is five working days from receipt of payment. Depending on the number of orders, this deadline can be both shorter and longer. In the pre-Christmas period, the increased number of orders may affect the extension of the order.',
            faq3: 'Place of origin of products?',
            faq4: 'Most of our products come from Asia and are imported by our company. Some products are of Polish origin (Polish producers also have interesting products).',
            faq5: 'Does the product have barcodes?',
            faq6: 'In most cases, bar codes are placed on products where this is possible. These are foil packaging, cardboard boxes and in some cases individual pieces.',
            faq7: 'Can I order goods in advance?',
            faq8: 'There is such a possibility. If we know that the goods will be soon, we can reserve them, reserve them. If you want to order a specific product, you can order by special request - if the quantity is specified.',
            faq9: 'Where can you pick up the ordered goods?',
            faq10: 'Preferred pickup at the Flower Market in Krakow.',
            faq11: 'Centrum Giełdowe Balicka  30-149 Kraków ul. Balicka 56/20B',
            faq12: 'Mon-Fri from 3am to 10am, Saturday from 3am to 8am',
            emailAlreadyTaken: 'The email has already been taken.',
            noChanges: 'No changes have been made.',
            changesSuccess: 'Changes have been made successfully.',
            voidName: 'The name cannot be empty.',
            voidSurname: 'The surname cannot be empty.',
            voidCompanyName: 'The company name cannot be empty.',
            voidAddress: 'The address cannot be empty.',
            voidPhone: 'The phone number cannot be empty.',
            voidCity: 'The city cannot be empty.',
            voidPostalCode: 'The postal code cannot be empty.',
            emptyOldPassword: 'The old password is empty.',
            emptyNewPassword: 'The new password is empty.',
            wrongOldPass: 'Incorrect old password.',
            forgetPassword: 'I forgot my password',
            provideEmail: 'We will send a password change link to the e-mail address provided.',
            send: 'Send',
            sendConfirm: 'If the user exists - a link to change the password has been sent.',
            addProduct: 'Add product',
            productCode: "Product code *",
            nameEnglish: "English name *",
            namePolish: "Polish name *",
            nameChinese: "Chinese name *",
            price: 'Unit price *',
            family: "Family *",
            lessCapacity: 'Smaller container capacity *',
            moreCapacity: 'The capacity of a larger package *',
            lessUnitEnglish: "English name of smaller package *",
            moreUnitEnglish: "English name for larger packaging *",
            lessUnitPolish: "Polish name for a smaller package *",
            moreUnitPolish: "Polish name for a larger package *",
            lessUnitChinese: "Chinese name for smaller packaging *",
            moreUnitChinese: "Chinese name for a larger package *",
            descriptionEnglish: "English description *",
            descriptionPolish: "Polish description *",
            descriptionChinese: "Chinese description *",
            uploadImages: 'Upload images',
            removeAllImages: 'Remove all images',
            update: 'Update',
            remove: 'Remove',
            pictures: 'Pictures *',
            maxPictureSize: 'The maximum size of a single picture: 5MB',
            recommendedPictureDimensions: 'Maxiumum and recommended dimensions of a single picture -> width: 736px, height: 1000px',
            supportedPictureExtensions: 'Supported picture extensions: .jpeg, .jpg, .png',
            customerData: 'Customer Data',
            shippingCosts: ' + shipping costs',
            changeCustomerData: 'Changes to customer data can be made in the user panel.',
            placeOrder: 'Place order',
            cookieContent1: 'INTRODUCTION',
            cookieContent2: 'F.H. Sitko may use cookies, web beacons, tracking pixels, and other tracking technologies when you visit our website fhsitko.pl, including any other media form, media channel, mobile website, or mobile application related or connected thereto (collectively, the “Site”) to help customize the Site and improve your experience.',
            cookieContent3: 'We reserve the right to make changes to this Cookie Policy at any time and for any reason. Any changes or modifications will be effective immediately upon posting the updated Cookie Policy on the Site, and you waive the right to receive specific notice of each such change or modification.',
            cookieContent4: 'You are encouraged to periodically review this Cookie Policy to stay informed of updates. You will be deemed to have been made aware of, will be subject to, and will be deemed to have accepted the changes in any revised Cookie Policy by your continued use of the Site after the date such revised Cookie Policy is posted.',
            cookieContent5: 'USE OF COOKIES',
            cookieContent6: 'A “cookie” is a string of information which assigns you a unique identifier that we store on your computer. Your browser then provides that unique identifier to use each time you submit a query to the Site. We use cookies on the Site to, among other things, keep track of services you have used, record registration information, record your user preferences, keep you logged into the Site, facilitate purchase procedures, and track the pages you visit. Cookies help us understand how the Site is being used and improve your user experience.',
            cookieContent7: 'TYPES OF COOKIES',
            cookieContent8: 'The following types of cookies may be used when you visit the Site:',
            cookieContent9: 'Advertising Cookies',
            cookieContent10: 'Advertising cookies are placed on your computer by advertisers and ad servers in order to display advertisements that are most likely to be of interest to you. These cookies allow advertisers and ad servers to gather information about your visits to the Site and other websites, alternate the ads sent to a specific computer, and track how often an ad has been viewed and by whom. These cookies are linked to a computer and do not gather any personal information about you.',
            cookieContent11: 'Analytics Cookies',
            cookieContent12: 'Analytics cookies monitor how users reached the Site, and how they interact with and move around once on the Site. These cookies let us know what features on the Site are working the best and what features on the Site can be improved.',
            cookieContent13: 'Our Cookies',
            cookieContent14: 'Our cookies are “first-party cookies”, and can be either permanent or temporary. These are necessary cookies, without which the Site won\'t work properly or be able to provide certain features and functionalities. Some of these may be manually disabled in your browser, but may affect the functionality of the Site.',
            cookieContent15: 'Personalization Cookies',
            cookieContent16: 'Personalization cookies are used to recognize repeat visitors to the Site. We use these cookies to record your browsing history, the pages you have visited, and your settings and preferences each time you visit the Site.',
            cookieContent17: 'Security Cookies',
            cookieContent18: 'Security cookies help identify and prevent security risks. We use these cookies to authenticate users and protect user data from unauthorized parties.',
            cookieContent19: 'Site Management Cookies',
            cookieContent20: 'Site management cookies are used to maintain your identity or session on the Site so that you are not logged off unexpectedly, and any information you enter is retained from page to page. These cookies cannot be turned off individually, but you can disable all cookies in your browser.',
            cookieContent21: 'Third-Party Cookies',
            cookieContent22: 'Third-party cookies may be place on your computer when you visit the Site by companies that run certain services we offer. These cookies allow the third parties to gather and track certain information about you. These cookies can be manually disabled in your browser.',
            cookieContent23: 'CONTROL OF COOKIES',
            cookieContent24: 'Most browsers are set to accept cookies by default. However, you can remove or reject cookies in your browser’s settings. Please be aware that such action could affect the availability and functionality of the Site.',
            cookieContent25: 'For more information on how to control cookies, check your browser or device’s settings for how you can control or reject cookies, or visit the following links:',
            cookieContent26: 'In addition, you may opt-out of some third-party cookies through the ',
            cookieContent27: 'Network Advertising Initiative’s Opt-Out Tool.',
            cookieContent28: 'OTHER TRACKING TECHNOLOGIES',
            cookieContent29: 'In addition to cookies, we may use web beacons, pixel tags, and other tracking technologies on the Site to help customize the Site and improve your experience. A “web beacon” or “pixel tag” is tiny object or image embedded in a web page or email. They are used to track the number of users who have visited particular pages and viewed emails, and acquire other statistical data. They collect only a limited set of data, such as a cookie number, time and date of page or email view, and a description of the page or email on which they reside. Web beacons and pixel tags cannot be declined. However, you can limit their use by controlling the cookies that interact with them.',
            cookieContent30: 'PRIVACY POLICY',
            cookieContent31: 'For more information about how we use information collected by cookies and other tracking technologies, please refer to our ',
            cookieContent32: 'Privacy Policy ',
            cookieContent33: ' posted on the Site. This Cookie Policy is part of and is incorporated into our Privacy Policy. By using the Site, you agree to be bound by this Cookie Policy and our Privacy Policy.',
            cookieContent34: 'CONTACT US',
            cookieContent35: 'If you have questions or comments about this Cookie Policy, please contact us at:',
            deleteProduct: 'Delete product',
            editProduct: 'Edit product',
            sureDelete: 'Are you sure you want to delete the product: ',
            back: 'Back',
            beCareful: 'Be careful! Current photos will be replaced with new ones.',
            errorAddProduct: 'Adding product failed.',
            successAddProduct: 'The product has been successfully added.',
            deleteSuccess: 'Product successfully removed!',
            successEditProduct: 'Product successfully edited.',
            errorEditProduct: 'Product edition failed.',
            minimumOrder: 'The minimum purchase value is PLN 500 (gross).',
            discountInfo: 'The discount will be included in the summary.',
            discountInfoCheckout: 'Discount included',
            pageNotFound: 'Page not found',
            backToHome: 'Back to home',
            resetSuccess: 'Password has been successfully reset. Check your email.',
            passwordReset: 'Password reset',
            passwordResetProblem: 'Password reset failed. Try again.',
            prefferedUnit: 'Preferred packing unit:',
            prefferedUnitError: 'Choose the preferred packaging unit for each product.',
            orderSuccess: 'Order successfully placed. Confirmation sent by email.',
            orderFailed: 'Placing the order failed. Try again.',
            christmas: 'Christmas',
            glassBaubles: 'Glass baubles',
            plasticBaubles: 'Plastic baubles',
            decoratedBaubles: 'Decorated baubles',
            christmasTreeSpikes: 'Christmas tree spikes',
            others: 'Others',
            easter: 'Easter',
            chickens: 'Chickens',
            eggs: 'Eggs',
            birdsAndButterflies: 'Birds and butterflies',
            artificialFlowers: 'Artificial flowers',
            artificialFlowers2: 'Artificial flowers',
            singleFlowers: 'Single flowers',
            bouquets: 'Bouquets',
            leaves: 'Leaves',
            artificialFoundations: 'Artificial foundations',
            differentAssortment: 'Different assortment',
            all: 'Product categories - all',
            category: 'Category',
            copyProduct: 'Copy product',
            copyProductCareful: 'When copying a product, add new photos.'
        }
    }

}

export default module;