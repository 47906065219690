import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-responsive-modal';
import { withTranslate } from 'react-redux-multilingual'


class SpecialProductItem extends Component {

    constructor(props) {
        super(props)

        this.state = {
            open: false,
            openQuantity: false,
            stock: 'InStock',
            quantity: 1,
            image: ''
        }
    }

    onClickHandle(img) {
        this.setState({ image: img });
    }
    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };

    minusQty = () => {
        if (this.state.quantity > 1) {
            this.setState({ stock: 'InStock' })
            this.setState({ quantity: this.state.quantity - 1 })
            this.props.onDecrementClicked()
        } else {
            console.log('removefromcart')
            this.setState({ openQuantity: false })
            this.props.onRemoveFromCart()
        }
    }

    plusQty = () => {
        if (this.props.product.stock >= this.state.quantity) {
            this.setState({ quantity: this.state.quantity + 1 })
            this.props.onIncrementClicked()
        } else {
            this.setState({ stock: 'Out of Stock !' })
        }
    }
    changeQty = (e) => {
        this.setState({ quantity: parseInt(e.target.value) })
    }
    updateQty = (e) => {
        if (this.props.product.stock >= parseInt(e.target.value)) {
            this.setState({ quantity: parseInt(e.target.value) })
            this.props.onAddToCartClicked()
        } else {
            this.setState({ stock: 'Out of Stock !' })
        }
    }
    openQuantity = () => {
        this.setState({ openQuantity: true });
        this.props.onAddToCartClicked()
    }

    render() {
        const {
            product,
            symbol,
            onAddToCartClicked,
            translate
        } = this.props;

        let RatingStars = []
        for (var i = 0; i < product.rating; i++) {
            RatingStars.push(<i className="fa fa-star" key={i}></i>)
        }
        return (
            <div className="product-box">
                <div className="img-wrapper">
                    <div className="lable-block">
                        {
                            translate('new') === 'nowy' ? <span style={{
                                padding: '20px 6px',
                                borderRadius: '100%',
                                backgroundColor: '#ff4c3b',
                                textAlign: 'center',
                                fontSize: '14px',
                                fontWeight: '700',
                                position: 'absolute',
                                textTransform: 'uppercase',
                                color: '#ffffff',
                                top: '7px',
                                left: '7px'
                            }}>{translate('new')}</span>
                                :
                                translate('new') === 'new' ? <span className="lable3">{translate('new')}</span>
                                    :
                                    <span style={{
                                        padding: '12px 14px',
                                        borderRadius: '100%',
                                        backgroundColor: '#ff4c3b',
                                        textAlign: 'center',
                                        fontSize: '14px',
                                        fontWeight: '700',
                                        position: 'absolute',
                                        textTransform: 'uppercase',
                                        color: '#ffffff',
                                        top: '7px',
                                        left: '7px'
                                    }}>{translate('new')}</span>
                        }

                    </div>
                    <div className="front">
                        <Link to={`${process.env.PUBLIC_URL}/product/${product.id}`} ><img
                            src={product.pictures[0] !== undefined ? `${product.pictures[0].filename}` : '/assets/images/defaultFlower.png'} 
                            className="img-fluid lazyload bg-img"
                            alt="" /></Link>
                    </div>
                    {this.state.stock != 'InStock' ? <span>Out Of Stock</span> : ''}
                </div>
                <div className="product-detail text-center">
                    <div>
                        <Link to={`${process.env.PUBLIC_URL}/product/${product.id}`}>
                            <h6>
                                {
                                    translate('langCode') === 'en' ?
                                        product.nameEnglish :
                                        translate('langCode') === 'pl' ?
                                            product.namePolish :
                                            translate('langCode') === 'zh' ?
                                                product.nameChinese : null
                                }
                            </h6>
                        </Link>
                        {this.props.isLogged ? <h4>{symbol}{product.price}</h4> : null}
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslate(SpecialProductItem);