import React, { Component } from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux'
import { withTranslate } from 'react-redux-multilingual'

import { getBestSeller } from '../../../services'
import { Product4 } from '../../../services/script'
import {
    addToCart,
    addToWishlist,
    addToCompare,
    incrementQty,
    decrementQty,
    removeFromCart
} from "../../../actions";
import ProductItem from '../common/special-product-item';

class Tranding extends Component {

    render() {
        const { items, symbol, addToCart, addToWishlist, addToCompare, incrementQty, decrementQty, removeFromCart, type, translate } = this.props;

        return (
            <div>
                {/*Paragraph*/}
                <section className="section-b-space addtocart_count ratio_square" style={{paddingTop: '20px'}}>
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="title4">
                                    <h2 className="title-inner4">{translate('newProducts')}</h2>
                                    <div className="line"><span></span></div>
                                </div>
                                <Slider {...Product4} className="product-4 product-m no-arrow">
                                    {items.map((product, index) =>
                                        <div key={index}>
                                            <ProductItem isLogged={this.props.isLogged} product={product} symbol={symbol}
                                                onAddToCartClicked={() => addToCart(product, 1)}
                                                onIncrementClicked={() => incrementQty(product, 1)}
                                                onDecrementClicked={() => decrementQty(product.id)}
                                                onRemoveFromCart={() => removeFromCart(product)}
                                                key={index} />
                                        </div>)
                                    }
                                </Slider>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    items: getBestSeller(state.data.products, ownProps.type),
    symbol: state.data.symbol
})

// export default connect(mapStateToProps,
//     {
//         addToCart,
//         addToWishlist,
//         addToCompare,
//         incrementQty,
//         decrementQty,
//         removeFromCart
//     }) (Tranding);

export default withTranslate(connect(mapStateToProps,
    {
        addToCart,
        addToWishlist,
        addToCompare,
        incrementQty,
        decrementQty,
        removeFromCart
    })(Tranding));