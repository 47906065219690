import React, { Component } from 'react';
import Slider from 'react-slick';

import Breadcrumb from "../common/breadcrumb";
import { Slider2, Team4 } from "../../services/script"
import { withTranslate } from 'react-redux-multilingual'

class policyPrivacy extends Component {

    constructor(props) {
        super(props)

    }

    render() {
        const { translate } = this.props;

        return (
            <div>
                <Breadcrumb title={translate('policyPrivacy')} />
                {/*about section*/}
                <section className="about-page  section-b-space" style={{paddingTop: '20px'}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                            <h4>{translate('policyPrivacy')}</h4>
                                <p>{translate('policyPivacyContent1')}</p> <br></br>
                                <p>{translate('policyPivacyContent2')}</p> <br></br>
                                <p>{translate('policyPivacyContent3')}</p> <br></br>
                                <p>{translate('policyPivacyContent4')}</p> <br></br>
                                <p>{translate('policyPivacyContent5')}</p> <br></br>
                                <p>{translate('policyPivacyContent6')}</p> <br></br>
                                <p>{translate('policyPivacyContent7')}
                                    <br></br>
                                    {translate('policyPivacyContent61')}<br></br>
                                    {translate('policyPivacyContent62')}
                                </p> <br></br>
                                <p>{translate('policyPivacyContent8')}</p> <br></br>
                                <p>{translate('policyPivacyContent9')}</p> <br></br>
                                <p>{translate('policyPivacyContent10')}</p> <br></br>
                                <p>{translate('policyPivacyContent11')}</p> <br></br>
                                <p>{translate('policyPivacyContent12')}</p> <br></br>
                                <p>{translate('policyPivacyContent13')}</p> <br></br>
                                <p>{translate('policyPivacyContent14')}</p> <br></br>
                                <p>{translate('policyPivacyContent15')}</p> <br></br>
                                <p>{translate('policyPivacyContent16')}</p> <br></br>
                                <p>{translate('policyPivacyContent17')}</p> <br></br>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}

export default withTranslate(policyPrivacy);