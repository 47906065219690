import React, { Component } from 'react';
import Slider from 'react-slick';
import '../common/index.scss';

// import custom Components
import ProductListing from './common/product-listing'
import ProductListingSearch from './common/product-listing-search'
import ProductListingSearchAlt from './common/product-listing-search-alt'
import Breadcrumb from "../common/breadcrumb";
import FilterBar from "./common/filter-bar";
import { withTranslate } from 'react-redux-multilingual'
import { addToWishlistUnsafe } from '../../actions';


class CollectionNoSideBar extends Component {
    constructor(props) {
        super(props)
    }

    state = {
        layoutColumns: 3,
        searchName: '',
        firstSearch: false,
        secondSearch: false,
        whichCategory: ''
    }

    LayoutViewClicked(colums) {
        this.setState({
            layoutColumns: colums
        })
    }

    componentDidMount() {
        this.setState({
            firstSearch: false,
            secondSearch: false
        })
    }

    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            this.setState({
                searchName: event.target.value,
            })
            if (event.target.value === '' && this.state.whichCategory === '') {
                this.setState({
                    firstSearch: false,
                    secondSearch: false
                })
            }
            else {
                if (!this.state.firstSearch && !this.state.secondSearch) {
                    this.setState({
                        firstSearch: true,
                        secondSearch: false
                    })
                }
                else {
                    this.setState({
                        firstSearch: !this.state.firstSearch,
                        secondSearch: !this.state.secondSearch
                    })
                }
            }
        }
    }

    filterCategory = (val) => {
        this.setState({
            whichCategory: val,
        })
        if (val === '' && this.state.searchName === '') {
            this.setState({
                firstSearch: false,
                secondSearch: false
            })
        }
        else {
            if (!this.state.firstSearch && !this.state.secondSearch) {
                this.setState({
                    firstSearch: true,
                    secondSearch: false
                })
            }
            else {
                this.setState({
                    firstSearch: !this.state.firstSearch,
                    secondSearch: !this.state.secondSearch
                })
            }
        }
    }

    handleClick = (val) => {
        this.setState({
            searchName: val,
        })
        if (val === '' && this.state.whichCategory === '') {
            this.setState({
                firstSearch: false,
                secondSearch: false
            })
        }
        else {
            if (!this.state.firstSearch && !this.state.secondSearch) {
                this.setState({
                    firstSearch: true,
                    secondSearch: false
                })
            }
            else {
                this.setState({
                    firstSearch: !this.state.firstSearch,
                    secondSearch: !this.state.secondSearch
                })
            }
        }
    }

    render() {
        const { translate } = this.props
        return (
            <div>
                <Breadcrumb title={translate('shop')} />

                {/*Section Start*/}
                <section className="section-b-space" style={{paddingTop: '20px'}}>
                    <div className="collection-wrapper">
                        <div className="container">
                            <div className="row">
                                <div className="collection-content col">
                                    <div className="page-main-content">
                                        <div className="container-fluid">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="collection-product-wrapper">
                                                        <div className="product-top-filter">
                                                            <div className="container-fluid p-0">
                                                                <div className="row">
                                                                    <div className="col-12">
                                                                        <FilterBar onLayoutViewClicked={(colmuns) => this.LayoutViewClicked(colmuns)} handleKeyPress={this.handleKeyPress} handleClick={this.handleClick} filterCategory={this.filterCategory} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="product-wrapper-grid">
                                                            <div className="container-fluid">
                                                                <div className="row">
                                                                    {
                                                                        this.state.searchName === '' && this.state.whichCategory === '' ?
                                                                            <ProductListing isLogged={this.props.isLogged} colSize={this.state.layoutColumns} /> :
                                                                            this.state.firstSearch ?
                                                                                <ProductListingSearch isLogged={this.props.isLogged} colSize={this.state.layoutColumns} searchName={this.state.searchName} whichCategory={this.state.whichCategory} />
                                                                                :
                                                                                this.state.secondSearch ?
                                                                                    <ProductListingSearchAlt isLogged={this.props.isLogged} colSize={this.state.layoutColumns} searchName={this.state.searchName} whichCategory={this.state.whichCategory} />
                                                                                    : null
                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*Section End*/}

            </div>
        )
    }
}

export default withTranslate(CollectionNoSideBar);