import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-responsive-modal';
import { withTranslate } from 'react-redux-multilingual'


class ProductItem extends Component {

    constructor(props) {
        super(props)

        this.state = {
            open: false,
            stock: 'InStock',
            quantity: 0,
            more: false,
            less: true,
            image: ''
        }
    }

    onClickHandle(img) {
        this.setState({ image: img });
    }
    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };

    minusQty = () => {
        if (this.state.quantity > 1) {
            this.setState({ stock: 'InStock' })
            this.setState({ quantity: this.state.quantity - 1 })
        }
    }

    plusQty = () => {
        if (this.props.product.stock >= this.state.quantity) {
            this.setState({ quantity: this.state.quantity + 1 })
        } else {
            this.setState({ stock: 'Out of Stock !' })
        }
    }
    minusQty = () => {
        if (this.state.quantity > 0) {
            if (this.state.less) {
                this.setState({ quantity: this.state.quantity - this.props.product.lessCapacity })
                if (this.state.quantity - this.props.product.lessCapacity < 0) {
                    this.setState({ quantity: 0 })
                }
            }
            else if (this.state.more) {
                this.setState({ quantity: this.state.quantity - this.props.product.moreCapacity })
                if (this.state.quantity - this.props.product.moreCapacity < 0) {
                    this.setState({ quantity: 0 })
                }
            }
        }
    }

    plusQty = () => {
        if (this.state.less) {
            this.setState({ quantity: this.state.quantity + this.props.product.lessCapacity })
        }
        else if (this.state.more) {
            this.setState({ quantity: this.state.quantity + this.props.product.moreCapacity })
        }
    }
    changeQty = (e) => {
        this.setState({ quantity: parseInt(e.target.value) })
    }

    radioButtonClicked(unit) {
        if (unit === 'more') {
            this.setState({
                more: true,
                less: false
            })
        }
        if (unit === 'less') {
            this.setState({
                more: false,
                less: true
            })
        }
    }

    render() {
        const { product, symbol, addToCartClicked, onAddToWishlistClicked, onAddToCompareClicked, translate } = this.props;

        let RatingStars = []
        for (var i = 0; i < product.rating; i++) {
            RatingStars.push(<i className="fa fa-star" key={i}></i>)
        }
        return (
            <div className="product-box">
                <div className="img-wrapper">
                    <div className="front">
                        <Link to={`${process.env.PUBLIC_URL}/product/${product.id}`} ><img
                            src={product.pictures[0] !== undefined ? `${product.pictures[0].filename}` : '/assets/images/defaultFlower.png'}
                            className="img-fluid"
                            alt="" /></Link>
                    </div>
                </div>
                <div className="product-detail">
                    <div>
                        <Link to={`${process.env.PUBLIC_URL}/product/${product.id}`}>
                            <h6>
                                {
                                    translate('langCode') === 'en' ?
                                        product.nameEnglish :
                                        translate('langCode') === 'pl' ?
                                            product.namePolish :
                                            translate('langCode') === 'zh' ?
                                                product.nameChinese : null
                                }
                            </h6>
                        </Link>
                        {this.props.isLogged ? <h4>{symbol}{product.price}</h4> : null}





                        <div className="radio" style={{ display: 'grid', marginTop: '10px', width: 'fit-content' }}>
                            <label>
                                <input type="radio" value={this.state.more} checked={this.state.more} onChange={() => this.radioButtonClicked('more')} style={{ marginRight: '5px' }} />
                                {translate('langCode') === 'en' ?
                                    product.moreUnitEnglish :
                                    translate('langCode') === 'pl' ?
                                        product.moreUnitPolish :
                                        translate('langCode') === 'zh' ?
                                            product.moreUnitChinese : null
                                } ({product.moreCapacity}{translate('pcs')})
                            </label>
                            <label>
                                <input type="radio" value={this.state.less} checked={this.state.less} onChange={() => this.radioButtonClicked('less')} style={{ marginRight: '5px' }} />
                                {translate('langCode') === 'en' ?
                                    product.lessUnitEnglish :
                                    translate('langCode') === 'pl' ?
                                        product.lessUnitPolish :
                                        translate('langCode') === 'zh' ?
                                            product.lessUnitChinese : null
                                } ({product.lessCapacity}{translate('pcs')})
                            </label>
                        </div>
                        {this.props.isLogged ?
                            <div>
                                <h6 className="product-title" style={{ textTransform: 'none' }}>{translate('quantity')}</h6>
                                <div className="qty-box" style={{float: 'left'}}>
                                    <div className="input-group">
                                        <span className="input-group-prepend">
                                            <button type="button" className="btn quantity-left-minus" onClick={this.minusQty} data-type="minus" data-field="">
                                                <i className="fa fa-angle-left"></i>
                                            </button>
                                        </span>
                                        <input id="quantityID" type="text" name="quantity" value={this.state.quantity} onChange={this.changeQty} className="form-control input-number" disabled={true} />
                                        <span className="input-group-prepend">
                                            <button type="button" className="btn quantity-right-plus" onClick={this.plusQty} data-type="plus" data-field="">
                                                <i className="fa fa-angle-right"></i>
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            :
                            null}
                        <div className="product-buttons">
                            {this.props.isLogged ?
                                <button
                                    className={this.state.quantity !== 0 ? "btn btn-solid" : null}
                                    style={this.state.quantity !== 0 ? {marginTop: '16px'} : {
                                        marginTop: '16px',
                                        padding: '7px 25px',
                                        cursor: 'auto',
                                        color: '#ffffff !important',
                                        letterSpacing: '0.05em',
                                        border: '2px solid #ff4c3b',
                                        backgroundImage: '-webkit-linear-gradient(30deg, #ff4c3b 50%, transparent 50%)',
                                        backgroundImage: '-webkit-linear-gradient(60deg, #ff4c3b 50%, transparent 50%)',
                                        backgroundImage: 'linear-gradient(30deg, #ff4c3b 50%, transparent 50%)',
                                        backgroundSize: '540px',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: '0',
                                        webkitTransition: 'background 300ms ease-in-out',
                                        transition: 'background 300ms ease-in-out'
                                    }}
                                    disabled={this.state.quantity !== 0 ? false : true} onClick={() => addToCartClicked(product, this.state.quantity, translate('langCode'))}>{translate('addToCart')}</button>
                                :
                                <Link to={`${process.env.PUBLIC_URL}/login`}
                                     style={{
                                        color: 'rgb(255, 76, 59)'
                                    }}>{translate('loginToBuy')}</Link>
                            }
                        </div>







                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslate(ProductItem);