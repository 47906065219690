import React, { Component, useState } from 'react';
import Breadcrumb from "../common/breadcrumb";
import { withTranslate } from 'react-redux-multilingual'
import { Collapse, Button, CardBody, Card } from 'reactstrap';

class Faq extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isOpen1: false,
            isOpen2: false,
            isOpen3: false,
            isOpen4: false,
            isOpen5: false,
        };
    }

    toggle(which) {
        if (which === '1') this.setState({ isOpen1: !this.state.isOpen1 })
        else if (which === '2') this.setState({ isOpen2: !this.state.isOpen2 })
        else if (which === '3') this.setState({ isOpen3: !this.state.isOpen3 })
        else if (which === '4') this.setState({ isOpen4: !this.state.isOpen4 })
        else if (which === '5') this.setState({ isOpen5: !this.state.isOpen5 })
    }

    render() {
        const { translate } = this.props;


        return (
            <div>
                <Breadcrumb title={translate('faq')} />


                {/*Dashboard section*/}
                <section className="faq-section section-b-space" style={{paddingTop: '20px'}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="accordion theme-accordion" id="accordionExample" style={{ marginBottom: '1rem' }}>
                                    <Button className="btn btn-solid" onClick={() => this.toggle('1')} style={{width: 'fit-content', backgroundImage: 'linear-gradient(30deg, #ff4c3b 100%, transparent 50%)' }}>{translate('faq1')}</Button>
                                    <Collapse isOpen={this.state.isOpen1}>
                                        <Card>
                                            <CardBody>
                                            {translate('faq2')}
                                                </CardBody>
                                        </Card>
                                    </Collapse>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="accordion theme-accordion" id="accordionExample" style={{ marginBottom: '1rem' }}>
                                    <Button className="btn btn-solid" onClick={() => this.toggle('2')} style={{ width: 'fit-content', backgroundImage: 'linear-gradient(30deg, #ff4c3b 100%, transparent 50%)' }}>{translate('faq3')}</Button>
                                    <Collapse isOpen={this.state.isOpen2}>
                                        <Card>
                                            <CardBody>
                                            {translate('faq4')}
                                                </CardBody>
                                        </Card>
                                    </Collapse>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="accordion theme-accordion" id="accordionExample" style={{ marginBottom: '1rem' }}>
                                    <Button className="btn btn-solid" onClick={() => this.toggle('3')} style={{ width: 'fit-content', backgroundImage: 'linear-gradient(30deg, #ff4c3b 100%, transparent 50%)' }}>{translate('faq5')}</Button>
                                    <Collapse isOpen={this.state.isOpen3}>
                                        <Card>
                                            <CardBody>
                                            {translate('faq6')}
                                                </CardBody>
                                        </Card>
                                    </Collapse>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="accordion theme-accordion" id="accordionExample" style={{ marginBottom: '1rem' }}>
                                    <Button className="btn btn-solid" onClick={() => this.toggle('4')} style={{ width: 'fit-content', backgroundImage: 'linear-gradient(30deg, #ff4c3b 100%, transparent 50%)' }}>{translate('faq7')}</Button>
                                    <Collapse isOpen={this.state.isOpen4}>
                                        <Card>
                                            <CardBody>
                                            {translate('faq8')}
                                                </CardBody>
                                        </Card>
                                    </Collapse>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="accordion theme-accordion" id="accordionExample" style={{ marginBottom: '1rem' }}>
                                    <Button className="btn btn-solid" onClick={() => this.toggle('5')} style={{ width: 'fit-content', backgroundImage: 'linear-gradient(30deg, #ff4c3b 100%, transparent 50%)' }}>{translate('faq9')}</Button>
                                    <Collapse isOpen={this.state.isOpen5}>
                                        <Card>
                                            <CardBody>
                                            {translate('faq10')}<br></br>
                                            {translate('faq11')}<br></br>
                                            {translate('faq12')}<br></br>
                                                </CardBody>
                                        </Card>
                                    </Collapse>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}

export default withTranslate(Faq)