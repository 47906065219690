import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-responsive-modal';
import { withTranslate } from 'react-redux-multilingual'


class ProductListItem extends Component {

    constructor(props) {
        super(props)

        this.state = {
            open: false,
            stock: 'InStock',
            quantity: 1,
            image: ''
        }
    }

    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };

    onClickHandle(img) {
        this.setState({ image: img });
    }

    minusQty = () => {
        if (this.state.quantity > 1) {
            this.setState({ stock: 'InStock' })
            this.setState({ quantity: this.state.quantity - 1 })
        }
    }

    plusQty = () => {
        if (this.props.product.stock >= this.state.quantity) {
            this.setState({ quantity: this.state.quantity + 1 })
        } else {
            this.setState({ stock: 'Out of Stock !' })
        }
    }
    changeQty = (e) => {
        this.setState({ quantity: parseInt(e.target.value) })
    }


    render() {
        const { product, symbol, onAddToCartClicked, onAddToWishlistClicked, onAddToCompareClicked, translate } = this.props;
        const { open } = this.state;

        let RatingStars = []
        for (var i = 0; i < product.rating; i++) {
            RatingStars.push(<i className="fa fa-star" key={i}></i>)
        }

        return (

            <div className="product-box">
                <div className="img-wrapper">
                    <div className="front">
                        <Link to={`${process.env.PUBLIC_URL}/product/${product.id}`} ><img
                            src={product.pictures[0] !== undefined ? product.pictures[0].filename : '/assets/images/defaultFlower.png'}
                            className="img-fluid"
                            alt="" /></Link>
                    </div>
                </div>
                <div className="product-detail">
                    <div>
                        <Link to={`${process.env.PUBLIC_URL}/product/${product.id}`}>
                            <h6>
                                {
                                    translate('langCode') === 'en' ?
                                        product.nameEnglish :
                                        translate('langCode') === 'pl' ?
                                            product.namePolish :
                                            translate('langCode') === 'zh' ?
                                                product.nameChinese : null
                                }
                            </h6>
                        </Link>
                        {this.props.isLogged ? <h4>{symbol}{product.price}</h4> : null}
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslate(ProductListItem);