import React, { Component } from 'react';
import Slider from 'react-slick';
import '../common/index.scss';
import { connect } from "react-redux";


// import custom Components
import RelatedProduct from "../common/related-product";
import Breadcrumb from "../common/breadcrumb";
import DetailsWithPrice from "./common/product/details-price";
import DetailsTopTabs from "./common/details-top-tabs";
import { addToCart, addToCartUnsafe, addToWishlist } from '../../actions'
import ImageZoom from './common/product/image-zoom'
import SmallImages from './common/product/small-image'
import { withTranslate } from 'react-redux-multilingual'




class NoSideBar extends Component {

    constructor() {
        super();
        this.state = {
            nav1: null,
            nav2: null
        };
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2
        });

    }

    render() {
        const { symbol, item, addToCart, addToCartUnsafe, addToWishlist, translate } = this.props
        var products = {
            fade: true
        };

        var productsnav = {
            slidesToShow: 3,
            slidesToScroll: 1,
            swipeToSlide: true,
            draggable: true,
            focusOnSelect: true
        };


        return (
            <div>

                <Breadcrumb title={
                    translate('langCode') === 'en' ? <span>{translate('product') + item.nameEnglish}</span> :
                        translate('langCode') === 'pl' ? <span>{translate('product') + item.namePolish}</span> :
                            translate('langCode') === 'zh' ? <span>{translate('product') + item.nameChinese}</span> :
                                null
                } />

                {/*Section Start*/}
                {(item) ?
                    <section >
                        <div className="collection-wrapper">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6 product-thumbnail">
                                        <Slider {...products} asNavFor={this.state.nav2} ref={slider => (this.slider1 = slider)} className="product-slick">
                                            {item.pictures[0] !== undefined ?
                                                item.pictures.map((vari, index) =>
                                                    <div key={index}>
                                                        <ImageZoom image={vari.filename} className="img-fluid image_zoom_cls-0" />
                                                    </div>
                                                )
                                                :
                                                <div>
                                                    <ImageZoom image={'/assets/images/defaultFlower.png'} className="img-fluid image_zoom_cls-0" />
                                                </div>
                                            }
                                        </Slider>
                                        {item.pictures.length === 1 ?
                                            null : <SmallImages item={item} settings={productsnav} navOne={this.state.nav1} />
                                        }
                                    </div>
                                    <DetailsWithPrice store={this.props.store} symbol={symbol} token={this.props.token} item={item} navOne={this.state.nav1} addToCartClicked={addToCart} BuynowClicked={addToCartUnsafe} addToWishlistClicked={addToWishlist} isLogged={this.props.isLogged} isAdmin={this.props.isAdmin} />
                                </div>
                            </div>
                        </div>
                    </section> : ''}
                {/*Section End*/}
                <RelatedProduct addToCartClicked={addToCart} isLogged={this.props.isLogged} itemNameEnglish={item.nameEnglish} itemNamePolish={item.namePolish} itemNameChinese={item.nameChinese} itemFamily={item.family} />
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let productId = ownProps.match.params.id;
    return {
        item: state.data.products.find(el => el.id == productId),
        symbol: state.data.symbol
    }
}

export default withTranslate(connect(mapStateToProps, { addToCart, addToCartUnsafe, addToWishlist })(NoSideBar));